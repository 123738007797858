import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import Loading from '../includes/Loading';
import moment from 'moment';

const cookies = new Cookies();

export default function Pages() {
    
  const Pagespage = (props) => {

    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    var a = 0;
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [pageForm, setpageForm] = useState({
        id: "", title: "", body: "", seoslug: "", seotitle: "", seokeywords: "", seodescription: "",
        status: "1"
    })
    const clearForm = () => {
        var temp = pageForm
        temp['id'] = ""
        temp['title'] = ""
        temp['body'] = ""
        temp['seoslug'] = ""
        temp['seotitle'] = ""
        temp['seokeywords'] = ""
        temp['seodescription'] = ""
        temp['status'] = "1"
        setpageForm(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")

    }
    const [ckLoading, setCkLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const [pages, setPages] = useState([])

    const changeVar = (col, val) => {
        var temp = pageForm
        temp[col] = val
        setpageForm(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }
    const AddPage = (e) => {

        e.preventDefault();
        formLoading(e.target)
        const formData = new FormData();
        formData.append('id', pageForm.id);
        formData.append('title', pageForm.title);
        formData.append('subtext', pageForm.subtext);
        formData.append('body', pageForm.body);
        formData.append('seoslug', pageForm.seoslug);
        formData.append('seotitle', pageForm.seotitle);
        formData.append('seokeywords', pageForm.seokeywords);
        formData.append('seodescription', pageForm.seodescription);
        formData.append('status', pageForm.status);
        
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        
        fetch(apiurl+"api/add_page", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    $(e.target).trigger("reset");
                    clearForm()
                    setShow(false)
                    get_pages()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    

    const get_pages = () => {
        setLoading(true)
        fetch(apiurl+"api/get_page", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setPages(response.pages)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
        };

        const editPage = (item) => {
            var temp = pageForm
            temp['id'] = item.id
            temp['title'] = item.title
            temp['body'] = item.body
            temp['seoslug'] = item.seoslug
            temp['seotitle'] = item.seotitle
            temp['seokeywords'] = item.seokeywords
            temp['seodescription'] = item.seodescription
            temp['status'] = item.status
            setpageForm(temp)
            setRefresh(refresh === "No" ? "Yes" : "No")
            setShow(true)
            setCkLoading(true)
            setTimeout(function(){
                setCkLoading(false)
            },1000)
        }

        const removePage = (item) => {
            confirmAlert({
                title: 'Are You Sure?',
                message: "You won't be able to revert this back.",
                buttons: [
                {
                    label: 'Yes',
                    onClick: () => removeThisPage(item)
                },
                {
                    label: 'No',
                    onClick: () => console.log("Canceled")
                }
                ]
            });
        } 

    const removeThisPage = (item) => {
        fetch(apiurl+"api/remove_page", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: item.id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    get_pages()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }
    useEffect(() => {
        if(a == 0){
            get_pages()
            // get_banners_list()
            a++
        }
     }, [])



    return(
    
    <>
    {
        show && pagePermissions(props.pagepermissions, "Pages", "add_page") &&
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        {
                            pageForm.id === "" ? "Add Page" : "Update Page"
                        }
                    </h3>
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setShow(false)} >
                        <i className='fa fa-times' ></i>
                    </button>
                </div>
                <form action='' method='POST' onSubmit={AddPage} >
                    <div className='box-body'>
                        <label>Page Title<span style={{color:"red"}} >*</span></label>        
                        <input type={"text"} className="form-control" required placeholder='e.g WHAT TO DO AFTER CA FOUNDATION EXAMS TILL RESULT' value={pageForm.title} onChange={(e) => changeVar("title", e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>Page Body</label>        
                        {
                            ckLoading ? 
                            <div style={{position:"relative"}} >
                                <br /><br /><br />
                                <Loading />
                                <br /><br /><br /><br />
                            </div>
                            :
                            <CKEditor 
                            editor={ ClassicEditor } 
                            config={
                                { 
                                    simpleUpload: { 
                                        uploadUrl: apiurl + 'api/ckeditor/image_upload'  
                                    }
                                }

                            } 
                            data={pageForm.body} onChange={ ( event, editor ) => { const data = editor.getData(); changeVar("body", data) } } />
                        }
                    </div>
                    <div className='box-body col-md-6'>
                        <label>Seo Slug<span style={{color:"red"}} >*</span></label>        
                        <input type={"text"} className="form-control" required placeholder='e.g what-to-do-after-ca-foundation-exams-till-result' value={pageForm.seoslug} onChange={(e) => changeVar("seoslug", e.target.value)} />
                    </div>
                    <div className='box-body col-md-6'>
                        <label>Seo Title</label>        
                        <input type={"text"} className="form-control" placeholder='e.g what-to-do-after-ca-foundation-exams-till-result' value={pageForm.seotitle} onChange={(e) => changeVar("seotitle", e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>Seo Keywords</label>        
                        <input type={"text"} className="form-control" placeholder='e.g What Next After CA Foundation Exams, What to do after ca foundation exams, what next after ca foundation, ca foundation exam what next, what to do after exam till result, ca foundation exam passed what next' value={pageForm.seokeywords} onChange={(e) => changeVar("seokeywords", e.target.value)} />
                    </div>
                    <div className='box-body'>
                        <label>Seo Description</label>        
                        <input type={"text"} className="form-control" placeholder='e.g What Next After CA Foundation Exams, What to do after ca foundation exams, what next after ca foundation, ca foundation exam what next, what to do after exam till result, ca foundation exam passed what next' value={pageForm.seodescription} onChange={(e) => changeVar("seodescription", e.target.value)} />
                    </div>
                    <div className='box-body no-left-padding no-right-padding'>
                        <div className='box-body col-md-3'>
                            <label>Show On Footer</label>        
                            <select className='form-control' defaultValue={pageForm.status} onChange={(e) => changeVar("status", e.target.value)}  >
                                <option value={"1"} >Yes</option>
                                <option value={"0"} >No</option>
                            </select>
                        </div>
                    </div>
                    
                    <div className='box-footer'>
                        <button className='btn btn-primary btn-flat btn-block'>
                            {
                                pageForm.id === "" ? "Add Page" : "Update Page"
                            }
                            
                        </button>
                    </div>
                </form>
            </div>
        </div>
    }

    <div className='col-md-12'>
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Manage Pages
                </h3>
                {
                    !show &&
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => {
                        clearForm()
                        setShow(true)
                        setCkLoading(true)
                        setTimeout(function(){
                            setCkLoading(false)
                        },1000)
                    }} >
                        <i className='fa fa-plus' ></i> Add New Page
                    </button>
                }
            </div>
            <div className='box-body'>
                <table id="studenttable" className="table table-bordered table-hover datatable">
                    <thead>
                        <tr>
                            <th style={{width:"80px"}} >S.No</th>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Show On Footer?</th>
                            <th>Added by</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pages.length > 0 &&
                            pages.map((item, index) => {
                                var staff = JSON.parse(item.staff)
                                moment.locale('en');
                                var dt = item.created_at;
                                return(
                                    <tr key={"key"+index} >
                                        <td style={{width:"80px"}} >{index+1}</td>
                                        <td>
                                            <a href={"https://www.escholars.in/page/"+item.seoslug} target={"_blank"} >
                                                {item.title}
                                            </a>
                                        </td>
                                        <td>{moment(dt).format("D-M-Y HH:mm:ss")}</td>
                                        <td>
                                            {
                                                (item.status === "1" || item.status === 1) ?
                                                <span className='badge bg-green'><i className='fa fa-check'></i></span>
                                                :
                                                <span className='badge bg-red'><i className='fa fa-times'></i></span>
                                            }
                                        </td>
                                        <td>
                                            <strong>{staff.name}<sup>({staff.role})</sup></strong><br />
                                            {staff.email}<br />
                                            {staff.contact}
                                        </td>
                                        <td style={{whiteSpace:"nowrap"}} >
                                            {
                                                pagePermissions(props.pagepermissions, "Pages", "edit_page") &&
                                                <button className='btn btn-primary btn-flat' onClick={() => editPage(item)} >  
                                                    <i className='fa fa-pencil'></i>
                                                </button>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, "Pages", "remove_page") &&
                                                <button className='btn btn-danger btn-flat' onClick={() => removePage(item)}>
                                                    <i className='fa fa-trash'></i>
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    </>
    )
  }
  return (
    <>
      <Sidebar page={Pagespage} permission="Pages"/>
    </>
  )
}
