import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import Cookies from 'universal-cookie';
// import Adminprops from '../../VelidateWebsite/Adminprops'
// import { Link, useNavigate } from 'react-router-dom'
import apiurl from '../../Global';
import CommonFunc from '../includes/Common';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

export default function Managecategories() {
  const ManagecategoriesPage = (props) => {

    const navigate = useNavigate()
    const [categoryId, setCategoryId] = useState('')
    const [categoryName, setCategoryName] = useState('')
    const [categoryPackageText, setCategoryPackageText] = useState('')
    const [categoryCourseText, setCategoryCourseText] = useState('')
    const [categoryFreeText, setCategoryFreeText] = useState('')
    const [categoryOtherText, setCategoryOtherText] = useState('')
    const [webLimit, setWeblimit] = useState(8)
    const [appLimit, setApplimit] = useState(8)
    const [mobileLimit, setMobilelimit] = useState(8)

    const [ppos, setPpos] = useState(1)
    const [cpos, setCpos] = useState(2)
    const [fpos, setFpos] = useState(3)
    const [opos, setOpos] = useState(4)
    const [categories, setCategoryList] = useState([])
    
    // const [loading, setLoading] = useState(true)
    // const adminProps = Adminprops()
    // const admin = cookies.get('admin')
    // const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    
    // const [refres, setRefresh] = useState("No")
    const addCategory = (e) => {
        
        formLoading(e.target)
        e.preventDefault();
        console.log(cpos)
        // setLoading(true)
        var url = (categoryId === "") ? 'add_new_category' : 'update_category'
        fetch(apiurl+"api/"+url, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    id: categoryId,
                    categoryName: categoryName,
                    categoryPackageText: categoryPackageText,
                    categoryCourseText: categoryCourseText,
                    categoryFreeText: categoryFreeText,
                    categoryOtherText: categoryOtherText,
                    cpos: cpos,
                    fpos: fpos,
                    ppos: ppos,
                    opos: opos,
                    webLimit: webLimit,
                    appLimit: appLimit,
                    mobileLimit: mobileLimit
                })
            })
            .then(response => response.json())
            .then(response => {
                
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){  
                    $(e.target).find(".loaderbody").remove()
                }, 1000);
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    CategoryList()
                    cleanFields()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                // setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    
    const CategoryList = () => {
        setCategoryList([])
        fetch(apiurl+"api/category_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setCategoryList(response.categories)
                    setTimeout(function(){
                        callSorting()
                    }, 2000)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                // setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const removeCategory = (id) => {
        fetch(apiurl+"api/remove_category", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    id:id
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    CategoryList()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                // setLoading(false)
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeDialog = (id) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeCategory(id)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }


    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_category_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }

    const editCategory = (item) => {
        setCategoryId(item.id)
        setCategoryName(item.categoryName)
        setCategoryPackageText(item.categoryPackageText)
        setCategoryCourseText(item.categoryCourseText)
        setCategoryFreeText(item.categoryFreeText)
        setCategoryOtherText(item.categoryOtherText)
        setPpos((item.ppos !== null) ? item.ppos : 1)
        setCpos((item.cpos !== null) ? item.cpos : 2)
        setFpos((item.fpos !== null) ? item.fpos : 3)
        setOpos((item.opos !== null) ? item.opos : 4)
        setMobilelimit((item.mobilelimit !== null) ? item.mobilelimit : 8)
        setApplimit((item.applimit !== null) ? item.applimit : 8)
        setWeblimit((item.weblimit !== null) ? item.weblimit : 8)
    }
    const cleanFields = () => {
        setCategoryId("")
        setCategoryName("")
        setCategoryPackageText("")
        setCategoryCourseText("")
        setCategoryFreeText("")
        setCategoryOtherText("")
        setPpos(1)
        setCpos(2)
        setFpos(3)
        setOpos(4)
        setMobilelimit(8)
        setApplimit(8)
        setWeblimit(8)
    }

    const changeActive = (item) => {
        fetch(apiurl+"api/change_category_active", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    activeid: item.id,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                CategoryList()
            })
            .catch(err => { });
    }

    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        var temp = categories
        const th = ["Name", "Heading 1", "Heading 2", "Heading 3", "Heading 4"]
        var td = [];
        for(var i = 0;i<temp.length;i++){
            td.push(
                [
                    temp[i]['categoryName'], temp[i]['categoryPackageText'], temp[i]['categoryCourseText'], temp[i]['categoryFreeText'], temp[i]['categoryOtherText']
                ]
            )
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Categories List of ("+td.length+") Entries"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
    var a = 0
    useEffect(() => {
        if(a === 0){
            CategoryList()
            a++
        }
    }, [])


    return(
        <>

        {
            
            pagePermissions(props.pagepermissions, "Categories", "add_category") &&

      <div className='col-md-4'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
                {
                    categoryId !== "" ? 
                    "Update Category"
                    :
                    "Add New Category"
                }
               
            </h3>
            {
                categoryId !== "" &&
                <span onClick={() => cleanFields() } className={"pull-right cursor-pointer"}>
                    <i className='fa fa-times'></i>
                </span>
            }
          </div>
          <form action='' method='POST' onSubmit={addCategory} id="add_staff">
            <div className='box-body'>
                <label>Category Name<span className='text-red'>*</span></label>
                <input type={"text"} name="name" placeholder='e.g CA Foundation' className='form-control' value={categoryName} required onChange={e => setCategoryName(e.target.value)}/>
            </div>
            <div className='box-body'>
                <div className='col-md-8 no-left-padding'>
                    <label>Category Package Heading<span className='text-red'>*</span></label>
                    <input type={"text"} name="categoryPackageText" placeholder='e.g Our Packages' className='form-control' value={categoryPackageText} required onChange={e => setCategoryPackageText(e.target.value)}/>
                </div>
                <div className='col-md-4 no-right-padding'>
                    <label>
                        Position
                    </label>
                    <input type={"number"} name="categoryPackageText" placeholder='e.g 1' className='form-control' value={ppos} required onChange={e => setPpos(e.target.value)}/>
                </div>
            </div>
            <div className='box-body'>
                <div className='col-md-8 no-left-padding'>
                    <label>Category Courses Heading<span className='text-red'>*</span></label>
                    <input type={"text"} name="categoryCourseText" placeholder='e.g Our Premium Courses ' className='form-control' value={categoryCourseText} required onChange={e => setCategoryCourseText(e.target.value)}/>
                </div>
                <div className='col-md-4 no-right-padding'>
                    <label>
                        Position
                    </label>
                    <input type={"number"} name="categoryPackageText" placeholder='e.g 1' className='form-control' value={cpos} required onChange={e => setCpos(e.target.value)}/>
                </div>
            </div>
            <div className='box-body'>
                <div className='col-md-8 no-left-padding'>
                    <label>Category Free Stuff Heading<span className='text-red'>*</span></label>
                    <input type={"text"} name="categoryFreeText" placeholder='e.g Our Free Courses' className='form-control' value={categoryFreeText} required onChange={e => setCategoryFreeText(e.target.value)}/>
                </div>
                <div className='col-md-4 no-right-padding'>
                    <label>
                        Position
                    </label>
                    <input type={"number"} name="categoryPackageText" placeholder='e.g 1' className='form-control' value={fpos} required onChange={e => setFpos(e.target.value)}/>
                </div>
            </div>
            <div className='box-body'>
                <div className='col-md-8 no-left-padding'>
                    <label>Category Other Heading</label>
                    <input type={"text"} name="categoryOtherText" placeholder='e.g Other Courses' className='form-control' value={categoryOtherText} onChange={e => setCategoryOtherText(e.target.value)}/>
                </div>
                <div className='col-md-4 no-right-padding'>
                    <label>
                        Position
                    </label>
                    <input type={"number"} name="categoryOtherText" placeholder='e.g 1' className='form-control' value={opos} required onChange={e => setOpos(e.target.value)}/>
                </div>
            </div>
            <div className='box-body'>
                <div className='col-md-4 no-left-padding'>
                    <label>Web Limit<span className='text-red'>*</span></label>
                    <input type={"number"} name="webLimit" required placeholder='e.g 8' className='form-control' value={webLimit} onChange={e => setWeblimit(e.target.value)}/>
                </div>
                <div className='col-md-4'>
                    <label>
                        Mobile Limit<span className='text-red'>*</span>
                    </label>
                    <input type={"number"} name="mobileLimit" required placeholder='e.g 8' className='form-control' value={mobileLimit} onChange={e => setMobilelimit(e.target.value)}/>
                </div>
                <div className='col-md-4 no-right-padding'>
                    <label>
                        App Limit<span className='text-red'>*</span>
                    </label>
                    <input type={"number"} name="appLimit" required placeholder='e.g 8' className='form-control' value={appLimit} onChange={e => setApplimit(e.target.value)}/>
                </div>
            </div>
            
            
            <div className='box-footer'>
                <input type={"submit"} className="btn btn-primary btn-flat pull-right" value={ ( categoryId !== "" ) ? "Update Category" : "Add Category"} required />
            </div>
            
          </form>
        </div>
      </div>
  }
  {
    pagePermissions(props.pagepermissions, "Categories", "view_category") &&
        <div className='col-md-8'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Categories List
                    </h3>
                    {
                        !downloading ?
                        <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                            Download
                        </button>
                        :
                        <button className='btn btn-warning btn-flat pull-right' >
                            Preparing...
                        </button>
                    }
                </div>
                {
                    categories.length < 1 ?
                    <div className='box-body'>
                        No Categories Found
                    </div>
                    :
                    <div className='box-body'>
                        <table id="staff_table" className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>
                                    </th>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Heading 1 {/* Package */}
                                    </th>
                                    <th>
                                        Heading 2 {/* Course */}
                                    </th>
                                    <th>
                                        Heading 3 {/* Free */}
                                    </th>
                                    <th>
                                        Heading 4 {/* Other */}
                                    </th>
                                    <th className='text-center'>
                                        Active Toggle
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='connected-sortable droppable-area1' >
                                {
                                    categories.map((item, index) => (
                                        <tr 
                                        key={"data"+index}
                                        id={"id"+item.id}>
                                            
                                            <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                <i className='fa fa-arrows-v'></i>
                                            </td>
                                            <td>
                                                {item.categoryName}
                                            </td>
                                            <td>
                                                <strong>({item.ppos})</strong> {item.categoryPackageText}
                                            </td>
                                            <td>
                                                <strong>({item.cpos})</strong> {item.categoryCourseText}
                                            </td>
                                            <td>
                                                <strong>({item.fpos})</strong> {item.categoryFreeText}
                                            </td>
                                            <td>
                                                <strong>({item.opos})</strong> {item.categoryOtherText}
                                            </td>
                                            <td className='text-center'>
                                                <label className="switch">
                                                    <input type="checkbox" className='checkboxes' defaultChecked={(item.active === 1) ? true : false} onChange={(e) => {
                                                        // window.$("#staff_table").find(".checkboxes").prop("checked", false)
                                                        changeActive(item)
                                                    }} />
                                                    <span className="slider round"></span>
                                                </label>
                                            </td>
                                            <td>
                                                {
                                                    pagePermissions(props.pagepermissions, "Categories", "edit_category") &&
                                                        <button className='btn btn-primary btn-flat' onClick={() => editCategory(item)}>
                                                            <i className='fa fa-edit'></i>
                                                        </button>
                                                }
                                                &nbsp;&nbsp;
                                                {
                                                    pagePermissions(props.pagepermissions, "Categories", "remove_category") &&
                                                        <button className='btn btn-danger btn-flat' onClick={() => removeDialog(item.categoryid)} >
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                }
                                                &nbsp;&nbsp;
                                                {
                                                    pagePermissions(props.pagepermissions, "Categories", "courses_position") &&
                                                        <button className='btn btn-primary btn-flat' onClick={() => navigate("/admin/categories/positions/"+item.categoryid)}>
                                                            Courses Positions
                                                        </button>
                                                }
                                            </td>
                                        </tr>
                                        
                                        ))
                                    }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
  }
      </>
    )
  }
  return (
    <>
      <Sidebar page={ManagecategoriesPage} permission="Categories"/>
    </>
  )
}
