import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import apiurl from '../../Global';
import Sidebar from '../includes/Sidebar';
import CommonFunc from '../includes/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import Loading from '../includes/Loading';
const cookies = new Cookies();

export default function Notifications() {
  const Notificationspage = (props) => {
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [loading, setLoading] = useState(false)
    const [notifications, setNotifications] = useState({
        "add_new_user" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "new_signup" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "item_purchased" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "transfer_order" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "books_shipped" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "books_delivered" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "course_content_updated" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "add_forum" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "mentioned_in_forum" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "mentioned_in_forum_comment" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "commented_on_your_post" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "replied_to_your_comment" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "live_classes_started" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "scheduled_item" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "new_msg_received" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "course_expiring_in" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "left_somethng_in_cart" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "assignment_accepted" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "assignment_rejected" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
        "quiz_result_generated" : {
            "title" : "",
            "link" : "",
            "body" : "",
            "app_body" : "",
            "sendto" : [],
            "edit" : false,
            "editLoading" : false,
        },
    })
    const replaceWords = {
        "add_new_user" : "{{userid}}, {{username}}, {{useremail}}, {{usercontact}}",
        "new_signup" : "{{userid}}, {{username}}, {{useremail}}, {{usercontact}}",
        "item_purchased" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{purchaseid}}, {{courseid}}, {{coursename}}",
        "transfer_order" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{purchaseid}}, {{courseid}}, {{coursename}}",
        "books_shipped" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{orderid}}",
        "books_delivered" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{orderid}}",
        // "course_content_updated" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{courseid}}, {{content}}, {{paths}}",
        "course_content_updated" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{courseid}}, {{content}}, {{coursename}}",
        "add_forum" : "",
        "mentioned_in_forum" : "",
        "mentioned_in_forum_comment" : "",
        "commented_on_your_post" : "",
        "replied_to_your_comment" : "",
        "new_msg_received" : "",
        "course_expiring_in" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{courseid}}, {{coursename}}",
        "live_classes_started" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}",
        "scheduled_item" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{courseid}}, {{coursename}}",
        "left_somethng_in_cart" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}",
        "assignment_accepted" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{item_name}}",
        "assignment_rejected" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{item_name}}",
        "quiz_result_generated" : "{{userid}}, {{username}}, {{usercontact}}, {{useremail}}, {{item_name}}",
    }
    
    const arr = [ 
        "add_new_user", "new_signup", "item_purchased", "transfer_order", 
        "books_shipped", "books_delivered", "course_content_updated","add_forum", 
        "mentioned_in_forum", "mentioned_in_forum_comment", "commented_on_your_post", 
        "replied_to_your_comment", "new_msg_received", "course_expiring_in", "live_classes_started", 
        "scheduled_item", "left_somethng_in_cart", 
        "assignment_accepted", "assignment_rejected", "quiz_result_generated"
    ]

  const getNotificationsText = () => {
    setLoading(true)
    
    fetch(apiurl + "api/getNotificationsList", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
            })
        })
        .then(response => response.json())
        .then(response => {
            if(response.status === "OK"){
                var temp = notifications

                for(var i=0;i<arr.length; i++){
                    var col = arr[i]
                    if(response.notification !== null && response.notification[col] !== null){ 
                        var jsonArray = JSON.parse(response.notification[col])
                        if(jsonArray['edit'] !== undefined){ jsonArray['edit'] = false; }
                        if(jsonArray['editLoading'] !== undefined){ jsonArray['editLoading'] = false; }
                        temp[col] = jsonArray
                    }
                }
                
                setNotifications(temp)
                setRefresh(refresh === "Yes" ? "No" : "Yes")
            }

            setTimeout(function(){
                setLoading(false)
                setTimeout(function(){
                    if(window.$(".select2-selection").length > 0){
                      window.$("select.select2-hidden-accessible").select2('destroy');
                    }else{
                        window.$(".select22").each(function(){
                            var id = window.$(this).attr("id")
                            window.$("#"+id).select2()
                        });
                    }
                }, 500) 
            }, 1000)
        })
  }
  
  const setColNotification = (data, main, col) => {
    var temp = notifications
    temp[main][col] = data
    setNotifications(temp)
    setRefresh(refresh === "No" ? "Yes" : "No")
  }

  
  const saveNotification = (e, col) => {
    e.preventDefault();
    formLoading(e.target)
    var values = notifications[col]
    values['sendto'] = window.$("#"+col).val();
    
    fetch(apiurl + "api/notificationsListSave", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                notification: values,
                column: col
            })
        })
        .then(response => response.json())
        .then(response => {
            $(e.target).find(".loaderbody").remove()
            if(typeof response.status !== "undefined" && response.status == "OK"){
                successModal("Success", response.message)
                
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
    }

    const callEdit = (val, item) => {
        setColNotification(val, item, "edit")
        if(window.$(".select2-selection").length > 0){
            window.$("select.select2-hidden-accessible").select2('destroy');
        }
          
        setTimeout(function(){
            window.$(".select22").each(function(){
                var id = window.$(this).attr("id")
                window.$("#"+id).select2()
            });
        }, 500) 
        // setColNotification(true, item, "editLoading")
        // setTimeout(function(){
        //     setColNotification(false, item, "editLoading")
        //     setRefresh(refresh === "No" ? "Yes" : "No")
        // },5000)
    }

  var a = 0
  useEffect(() => {
    if(a === 0) {
        setTimeout(function(){
            getNotificationsText()
        }, 1000)
      a++
    }
  },[])
    return(
      <>
        {
            <>
            {
                arr.map((item, index) => {
                    return(
                        <div className='col-md-12' key={"notifications"+index}  >
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title' style={{textTransform:"capitalize"}} >
                                        {item.replace(/_/g, " ")}
                                    </h3>
                                    {
                                        notifications[item]["edit"] ? 
                                        <button className='btn btn-success btn-flat pull-right' onClick={() => callEdit(false, item)} style={{marginLeft:"10px"}} >
                                            <i className='fa fa-times'></i>
                                        </button>
                                        :
                                        <button className='btn btn-primary btn-flat pull-right' onClick={() => callEdit(true, item)} style={{marginLeft:"10px"}} >
                                            <i className='fa fa-edit'></i>
                                        </button>
                                    }

                                    <span className='pull-right' >
                                        { replaceWords[item] }
                                    </span>
                                </div>
                                
                                {
                                    <>
                                    
                                    {
                                        notifications[item]["edit"] &&
                                    <form action='' method='POST' onSubmit={(e) => saveNotification(e, item)} >
                                        <div className='box-body' style={{position:"relative"}} >
                                            <div className='col-md-6 no-left-padding'>
                                                <label>Notification Title<span className='text-red' >*</span></label>
                                                <input type={"text"} className="form-control" required value={notifications[item]["title"]} onChange={(e) => setColNotification(e.target.value, item, "title") } placeholder={"Enter Your Notification Title For : " + item.replace(/_/g, " ")} />
                                            </div>
                                            <div className='col-md-6 no-right-padding'>
                                                <label>URL (If Any)</label>
                                                <input type={"url"} className="form-control" value={notifications[item]["link"]} onChange={(e) => setColNotification(e.target.value, item, "link") } placeholder={"Enter Your Notification Link For : " + item.replace(/_/g, " ")} />
                                            </div>
                                        </div>
                                        
                                        {
                                            notifications[item]["editLoading"] ?
                                            <div className='box-body' style={{position:"relative"}} >
                                                <br /><br />
                                                    <Loading />
                                                <br /><br /><br /><br />
                                            </div>
                                            :
                                            <div className='box-body' style={{position:"relative"}} >
                                                <label>Notification Body<span className='text-red' >*</span></label>
                                                    <CKEditor 
                                                    editor={ ClassicEditor } 
                                                    
                                                    config={
                                                        { 
                                                        simpleUpload: { 
                                                            uploadUrl: apiurl + 'api/ckeditor/image_upload' 
                                                        }, 
                                                        }
    
                                                    } 
                                                    data={notifications[item]["body"]} onChange={ ( event, editor ) => { const data = editor.getData(); setColNotification(data, item, "body") } } />
                                            </div>
                                        }
                                        <div className='box-body' style={{position:"relative"}} >
                                            <label>Notification Body (APP)<span className='text-red' >*</span></label>
                                            <textarea className="form-control" required onChange={(e) => setColNotification(e.target.value, item, "app_body") } placeholder={"Enter Your Notification App Body For : " + item.replace(/_/g, " ")} defaultValue={notifications[item]["app_body"]}></textarea>
                                            
                                        </div>
                                        
                                        <div className='box-body'>
                                            <label>Send On</label>
                                            <select className='form-control select22' multiple id={item} value={notifications[item]["sendto"]} onChange={(e) => setColNotification(e.target.value, item, "sendto") } data-placeholder={"Select Notification Send Via for : " + item.replace(/_/g, " ")}  >
                                                <option value={"Web"}>Web Push</option>
                                                <option value={"App"}>App Push</option>
                                                <option value={"Whatsapp"}>Whatsapp Push</option>
                                                <option value={"In App"}>In App</option>
                                            </select>
                                        </div>

                                        <div className='box-footer'>
                                            <button className='btn btn-primary btn-flat btn-block' style={{textTransform:"capitalize"}} >
                                                Update {item.replace(/_/g, " ")} Notification
                                            </button>
                                        </div>
                                    </form>
                                }
                                    </>
                                }
                            </div>
                        </div>
                        )
                    })
                }
            </>
        }   
  
      </>
    )
  }
  return (
    <>
      <Sidebar page={Notificationspage} permission="Notifications"/>
    </>
  )
}
