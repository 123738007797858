import React, { useState, useEffect, useRef } from 'react'
import $ from "jquery"

import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import CommonFunc from '../includes/Common';
import apiurl from '../../Global';
import Loading from '../includes/Loading';
import moment from 'moment';

const cookies = new Cookies();

export default function Logs() {

  const Logspage = () => {

    
    const [daylogs, setDaylogs] = useState([])
    const [start, setStart] = useState("")
    const [end, setEnd] = useState("")
    const [logs, setLogs] = useState([])
    let getToken = cookies.get('token')
    const {AdminBasics, errorModal, pagePermissions, successModal} = CommonFunc()
    const [leftLoading, setLeftLoading] = useState(true)
    const [mainLoading, setMainLoading] = useState(true)
    const [showOnly, setShowOnly] = useState({
        visited: false,
        modified: false,
        deleted: false,
    })
    const myRef = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView();
    const [showOnlyCount, setShowOnlyCount] = useState({
        visited: 0,
        modified: 0,
        deleted: 0,
    })
    const[refresh, setRefresh] = useState("No")
    const[datatable, setDatatable] = useState()
    const [segment, setSegment] = useState(0)
    const [tags, setTags] = useState([])

    const [filter, setFilter] = useState({
        start: "",
        end: "",
        flag: "",
        search: "",
        tag: ""
    });

    const changeFilter = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        // fetchLogs()
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const recentlogs = () => {
        setLeftLoading(true)
        fetch(apiurl+"api/recentlogs", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setDaylogs(response.dates)
                    setTags(response.tags)
                    if(filter.start === ""){
                        changeFilter("start", response.dates[0].start)
                    }
                    fetchLogs()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLeftLoading(false)
            })
            .catch(err => {
                
            });
    }

    const fetchLogs = () => {
        setMainLoading(true)
        fetch(apiurl+"api/fetchlogs", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                    filter: filter
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setLogs(response.logs)
                    
                    var temp = showOnlyCount
                    temp['visited'] = response.visitedcount
                    temp['modified'] = response.modifiedcount
                    temp['deleted'] = response.deletedcount
                    setShowOnlyCount(temp)
                    setRefresh(refresh === "Yes" ? "No" : "Yes")
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    // setTimeout(function(){
                    //     window.callDatatable("table", "searchid")
                    // }, 1000)
                    

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setMainLoading(false)
            })
            .catch(err => {
                
            });
    }

    const changeTab = (tab) => {
    //     var temp = showOnly
    //     temp[tab] = showOnly[tab] === true ? false : true
    //     setShowOnly(temp)
    //     fetchLogs(start, end)
    //     setRefresh(refresh === "Yes" ? "No" : "Yes")
    }



    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["Flag", "Description", "Date & Time", "Name", "Email", "Contact"]
        var segmentTemp = logs
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = logs[j]
            for(var i = 0;i<temp.length;i++){

                var lvl = "Deleted"
                if(temp[i].flag === 1){
                    lvl = "Visited"
                }
                if(temp[i].flag === 2){
                    lvl = "Modified"
                }
                moment.locale('en');
                var dt = temp[i].created_at;
                var member = JSON.parse(temp[i].staff_data)
                if (typeof member === 'string' || member instanceof String){
                    member = JSON.parse(member)
                }

                var cleanText = temp[i]['msg'].replace(/<\/?[^>]+(>|$)/g, "");

                td.push(
                    [
                        lvl, cleanText, moment(dt).format("D-M-Y hh:mm:s A"), 
                        member['name'], member['email'], member['contact']
                        
                    ]
                )
            }
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Logs List of ("+td.length+") Entries."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    var a = 0
    useEffect(()=>{
        if(a === 0){
            recentlogs()
            a++
        }
        // window.$("body").css({
        //     height:"100vh",
        //     overflow:"hidden"
        // })
    }, [])
    return(
        <div className='logsPage'>
            <div className='logsBody'>
                <div className='logsLeft'>
                    <p className='logsHeading no-margin'>
                        Logs Viewer
                    </p>
                    <p className='backToPanel'>
                        <Link to={"/admin/dashboard"} title={"Back To Panel"}>
                            <FeatherIcon icon={"arrow-left"} />
                            Back to dashboard
                        </Link>
                    </p>
                    <div className='logsSlots'>
                        {
                            leftLoading ? 
                            <Loading />
                            :
                            <ul>
                                {
                                    daylogs.length > 0 &&
                                    daylogs.map((item, index) => {
                                        var cls = ((filter.start === item.start && filter.end === "") ? 'activeLog' : '' )
                                        // cls += (item.count === 0) ? ' blankLog' : ''
                                        return(
                                        <li key={"log"+index} className={cls} onClick={(e) => {
                                            changeFilter("end", "")
                                            changeFilter("start", item.start)
                                            fetchLogs()
                                        }} >
                                            <p className='no-margin'>
                                                <strong>
                                                Log: &nbsp;
                                                </strong>
                                                {item.placeholder}
                                            </p>
                                            <p className='no-margin' style={{fontSize:"12px", color:"rgba(0,0,0,0.6)", display:"flex"}}>
                                                {/* {item.count} */}
                                                &nbsp;
                                                <FeatherIcon icon={"arrow-right"} />
                                            </p>
                                        </li>
                                        

                                        )
                                    })
                                    }
                            </ul>
                        }
                        
                    </div>
                </div>
                <div className='logsRight' ref={myRef}>
                    <div className='col-md-12 no-left-padding no-right-padding'>
                        <div className='logsRightTop'>
                            <ul className='no-padding' style={{width:"100%"}} >
                                <li >
                                    <select className='form-control' defaultValue={filter.flag}  onChange={(e) => changeFilter("flag", e.target.value)}  >
                                        <option value="">Select Flag</option>
                                        {/* <option value="">All</option> */}
                                        <option value="1">Visited</option>
                                        <option value="2">Modified</option>
                                        <option value="3">Deleted</option>
                                    </select>
                                </li>
                                <li >
                                    <select className='form-control' defaultValue={filter.tag}  onChange={(e) => changeFilter("tag", e.target.value)}  >
                                        <option value="">Select Tag Name</option>
                                        {
                                            tags.length > 0 &&
                                            tags.map((item, index) => {
                                                return(
                                                    <option value={item.tag} key={"tag"+index}>{item.tag}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </li>
                                
                                {/* <li className='infoSlots' onClick={(e) => changeTab("visited")} title={"Visited"} >
                                    <div className='checkbox'>
                                        {
                                            showOnly['visited'] === true &&
                                            <FeatherIcon icon={"check"} />
                                        }
                                    </div>
                                    <p>
                                    <strong>{showOnlyCount['visited']}</strong>
                                    </p>
                                </li>
                                <li className='warningSlots' onClick={(e) => changeTab("modified")} title={"Modified"} > 
                                    <div className='checkbox'>
                                        {
                                            showOnly['modified'] === true &&
                                            <FeatherIcon icon={"check"} />
                                        }
                                    </div>
                                    <p>
                                        <strong>{showOnlyCount['modified']}</strong>
                                    </p>
                                </li>
                                <li className='dangerSlots'  onClick={(e) => changeTab("deleted")} title={"Deleted"} >
                                    <div className='checkbox'>
                                        {
                                            showOnly['deleted'] === true &&
                                            <FeatherIcon icon={"check"} />
                                        }
                                    </div>
                                    <p>
                                    <strong>{showOnlyCount['deleted']}</strong>
                                    </p>
                                </li> */}
                                <li className='no-padding' >
                                    <input type={"date"} className="form-control no-margin" style={{width:"125px"}}  value={filter.start} onChange={(e) => changeFilter("start", e.target.value)}  />
                                </li>
                                <li className='no-padding' >
                                    <input type={"date"}  className="form-control no-margin" style={{width:"130px"}} value={filter.end} onChange={(e) => changeFilter("end", e.target.value)}  />
                                </li>
                                <li className='no-padding' >
                                    <input type={"search"} value={filter.search} onChange={(e) => changeFilter("search", e.target.value)}  className={"form-control"} placeholder={"Search Here..."}  />
                                </li>
                                <li>
                                    <button className='btn btn-primary btn-flat' onClick={() => fetchLogs()}>
                                        Search
                                    </button>
                                </li>
                                <li className='pull-right'>
                                    {
                                        !downloading ?
                                        <button className='btn btn-primary btn-flat btn-block pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest()} >
                                            Download
                                        </button>
                                        :
                                        <button className='btn btn-warning btn-flat btn-block pull-right' >
                                            Preparing...
                                        </button>
                                    }
                                </li>
                            </ul>   
                                
                            {/* <div className='searchArea pull-right'>
                                <input type={"search"} value={filter.search} onChange={(e) => changeFilter("search", e.target.value)}  className={"form-control"} placeholder={"Search Here..."}  />
                            </div> */}
                        </div>
                        <div className='logsRightBody'>
                            <table className='logsTable table dataTable' id='table'>
                                <thead>
                                    <tr>
                                        <th style={{width:"10px"}}>
                                            
                                        </th>
                                        <th style={{width:"40px"}}>
                                            Flag
                                        </th>
                                        <th style={{width:"50px"}}>
                                            Tag
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th style={{width:"80px"}}>
                                            Time
                                        </th>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Contact
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        mainLoading ? 
                                        <tr>
                                            <td colSpan={8} style={{textAlign:"center"}} >Loading...</td>
                                        </tr>
                                        :
                                    <>
                                    {
                                        typeof logs[segment] !== "undefined" &&
                                        logs[segment].length > 0 ?
                                        logs[segment].map((item, index) => {
                                            var idcls = "dangerLog"
                                            var iconcls = "fa fa-info-circle"
                                            var lvl = "Deleted"
                                            if(item.flag === 1){
                                                idcls = "infoLog"
                                                iconcls = "fa fa-laptop"
                                                lvl = "Visited"
                                            }
                                            if(item.flag === 2){
                                                idcls = "warningLog"
                                                iconcls = "fa fa-warning"
                                                lvl = "Modified"
                                            }
                                            moment.locale('en');
                                            var dt = item.created_at;
                                            var member = JSON.parse(item.staff_data)
                                            if (typeof member === 'string' || member instanceof String){
                                                member = JSON.parse(member)
                                            }
                                            
                                            return(
                                                <tr key={"tablelog"+index}>
                                                    <td className={idcls}>
                                                        <i className={iconcls}></i>
                                                    </td>
                                                    <td className={idcls}>
                                                        <strong>
                                                            {lvl}
                                                        </strong>
                                                    </td>
                                                    <td className={idcls}>
                                                        {/* <Link to={"#"} onClick={(e) => window.location.href=item.page_url} > */}
                                                            {item.tag}  
                                                        {/* </Link> */}
                                                        <span className='hiddenURL'>{item.page_url}</span>
                                                    </td>
                                                    <td className='logdescription'>
                                                        <div dangerouslySetInnerHTML={{__html : item.msg}}></div>
                                                    </td>
                                                    <td className='logtime' style={{whiteSpace:"nowrap"}}>
                                                    {
                                                        filter.end === "" ?
                                                        moment(dt).format("HH:mm:ss A")
                                                        : 
                                                        moment(dt).format("DD-MM-YYYY")
                                                    }
                                                    </td>
                                                    <td>
                                                        <div className='overflowTxt' title={member.name+' ('+member.role+')'} >
                                                            <strong>
                                                                {member.name} &nbsp;
                                                                <small>
                                                                    ({member.role})
                                                                </small>
                                                            </strong>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='overflowTxt' title={member.email} >
                                                            <Link to={"#"} onClick={()=>window.location.href='mailto:'+member.email} >
                                                                {member.email}    
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='overflowTxt' title={member.contact} >
                                                            <Link to={"#"} onClick={()=>window.location.href='tel:'+member.contact} >
                                                                {member.contact}    
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        :
                                        <tr>
                                            <td >No Records Found</td>
                                            <td ></td>
                                            <td ></td>
                                            <td ></td>
                                            <td ></td>
                                            <td ></td>
                                            <td ></td>
                                            <td ></td>
                                        </tr>
                                    }
                                    </>
                                    }
                                </tbody>
                            </table>
                            {/* <div className='box no-border'>
                                <div className='box-footer'> */}
                                    <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                                        {
                                            logs.length > 0 && logs.length <= 10 &&
                                            logs.map((item, index) => {
                                                const active = index === segment ? " btn-primary" : ""
                                                return(
                                                    <button className={'btn btn-flat'+active} key={"key"+index} style={{marginLeft:"5px"}} onClick={() => {
                                                        setSegment(index)
                                                        // document.body.scrollTop = document.documentElement.scrollTop = 0;
                                                        executeScroll()
                                                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                                                        }} >
                                                        {index+1}
                                                    </button>
                                                )
                                            })
                                        }
                                        {
                                            logs.length > 10 &&
                                            <div style={{width:"200px", float:"right"}}>
                                                <label>Last Page: {logs.length}</label>
                                                <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => {
                                                    setSegment(e.target.value - 1)
                                                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                                                    }} />
                                            </div>
                                        }
                                    </div>
                                {/* </div>
                            </div> */}
                        </div>
                    </div>             
                </div>
            </div>
        </div>
    )
  }
  return (
    <>
      <Sidebar page={Logspage} permission="Logs"/>
    </>
  )
}
