import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from './includes/Sidebar'
import Cookies from 'universal-cookie';
import CommonFunc from './includes/Common';
import { useParams } from 'react-router-dom';
import apiurl from '../Global';
import Loading from './includes/Loading';
const cookies = new Cookies();

export default function Admindashboard() {
  const Dashboard = (props) => {
    const admin = cookies.get('admin')
  let getToken = cookies.get('token')
  const params = useParams()
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions} = CommonFunc()
  const [refresh, setRefresh] = useState("No")
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState("No")

    
  const [customeRange, setCustomeRange] = useState(false)
  const [daysFilter, setDaysFilter] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const getDashboard = () => {
    setLoading(true)
    
    fetch(apiurl + "api/dashboard", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                startDate: startDate,
                endDate: endDate
            })
        })
        .then(response => response.json())
        .then(response => {
          setDaysFilter(response.days)
          
            setTimeout(function(){
              window.ambarchart("sales", response.summary.sales)
              window.ambarchart("bandwidth", response.summary.bandwidth)
              window.ambarchart("coursessold", response.summary.coursessold)
              window.ambarchart("packagessold", response.summary.packagessold)
              window.ambarchart("signups", response.summary.signups)
              window.radiusChart("disk", response.disk.graphData, response.disk.text1)
              
            }, 500)
            setSummary(response.summary)
            setRefresh(refresh === "Yes" ? "No" : "Yes")
            setLoading(false)
        })
  }

  const filterChart = (e) => {
    e.preventDefault()
    getDashboard()
}

  var a = 0
  useEffect(() => {
    if(a === 0) {
      getDashboard()
      a++
    }
  },[])
    return(
      <>
      {
        !loading &&
      <>
      <div className='row'>
        <div className='col-md-12'>
          {
            ((pagePermissions(props.pagepermissions, "Dashboard", "sales") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "coursessold") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "packagessold") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "total_signups") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "bandwidth_graph") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "sales_graph") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "courses_enrolled_graph") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "packages_enrolled_graph") === false) &&
            (pagePermissions(props.pagepermissions, "Dashboard", "new_signups_graph") === false)) &&
            
            <div className='col-md-12'>
              <div className='box no-border'>
                <div className='box-header text-center'>
                  <h3 className='box-title'>
                    Welcome to Dashboard
                  </h3>
                </div>
              </div>
              </div>
          }
          {
            (pagePermissions(props.pagepermissions, "Dashboard", "total_signups")) &&
              <div className="col-lg-3 col-xs-6">
                  <div className="small-box bg-my">
                    <div className="inner">
                      <h3>
                        { (pagePermissions(props.pagepermissions, "Dashboard", "total_signups")) ? summary.salessum : 'NA' }
                        
                      </h3>

                      <p>Total Sold Count</p>
                    </div>
                    <div className="icon">
                      <i className="fa fa-list"></i>
                    </div>
                    <a href="#" className="small-box-footer">
                      {/* More info <i className="fa fa-arrow-circle-right"></i> */}
                    </a>
                  </div>
              </div>
            }
            {
              (pagePermissions(props.pagepermissions, "Dashboard", "coursessold")) &&
                <div className="col-lg-3 col-xs-6">
                    <div className="small-box bg-my">
                      <div className="inner">
                        <h3>{ (pagePermissions(props.pagepermissions, "Dashboard", "coursessold")) ? summary.coursessoldsum : 'NA' }</h3>

                        <p>Total Courses Sold</p>
                      </div>
                      <div className="icon">
                        <i className="fa fa-book"></i>
                      </div>
                      <a href="#" className="small-box-footer">
                        {/* More info <i className="fa fa-arrow-circle-right"></i> */}
                      </a>
                    </div>
                  </div>
            }
            {
              (pagePermissions(props.pagepermissions, "Dashboard", "packagessold")) &&
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (pagePermissions(props.pagepermissions, "Dashboard", "packagessold")) ? summary.packagessoldsum : 'NA' }</h3>

              <p>Total Packages Sold</p>
            </div>
            <div className="icon">
              <i className="fa fa-inbox"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
        }
        {
          (pagePermissions(props.pagepermissions, "Dashboard", "total_signups")) && 
        <div className="col-lg-3 col-xs-6">
          <div className="small-box bg-my">
            <div className="inner">
              <h3>{ (pagePermissions(props.pagepermissions, "Dashboard", "total_signups")) ? summary.signupssum : 'NA' }</h3>

              <p>Total Signups</p>
            </div>
            <div className="icon">
              <i className="fa fa-users"></i>
            </div>
            <a href="#" className="small-box-footer">
              {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
          </div>
        </div>
      }
        </div>
        </div>
      </>
  }
  {
    (pagePermissions(props.pagepermissions, "Dashboard", "disk_usage_graph")) &&
    <div className='col-md-4'>
      <div className='box no-border'>
        <div className='box-header'>
          <h3 className='box-title'>
            Disk Usage 
          </h3>
        </div>
        <div className='box-body'>
            {
              loading ? 
              <>
                <Loading />
                <br /><br /><br /><br />
              </>
              :
              <>
                <div className='hideChartLogo'></div>
                <div id="disk" style={{ width: "100%", height: "500px" }}></div>
              </>
            }
            
        </div>
      </div>
    </div>
  }
  {
    (pagePermissions(props.pagepermissions, "Dashboard", "bandwidth_graph")) &&
      <div className='col-md-8'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
              Daily Clicks 
            </h3>

          <div className='pull-right'>
            <form action='' method='POST' style={{display:"flex"}} onSubmit={(e) => filterChart(e) } >
                <select className='form-control' required onChange={(e) => {
                    var val = e.target.value
                    setCustomeRange((val === "custome") ? true : false)
                    if(val !== "custome" && val !== ""){
                        setStartDate(daysFilter[val]['start'])
                        setEndDate(daysFilter[val]['end'])
                    }else{
                        setStartDate("")
                        setEndDate("")
                    }
                }}>
                    <option value={''}>Select Duration</option>
                    <option value={'seven'}>Seven Days</option>
                    <option value={'month'}>1 Month</option>
                    <option value={'monthsix'}>6 Months</option>
                    <option value={'custome'}>Custome Range</option>
                </select>&nbsp;
                {
                    customeRange &&
                    <>
                        <input type={"date"} className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />&nbsp;
                        <input type={"date"} className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} />&nbsp;
                    </>
                }
                <button className='btn btn-primary btn-flat'>
                    <i className='fa fa-filter'></i>
                </button>
            </form>
        </div>

          </div>
          {
            (pagePermissions(props.pagepermissions, "Dashboard", "bandwidth_graph")) ?
              <div className='box-body' style={{position:"relative"}}>
                {
                  loading ? 
                  <>
                    <Loading />
                    <br /><br /><br /><br />
                  </>
                  :
                  <>
                    <div className='hideChartLogo'></div>
                    <div id="bandwidth" style={{ width: "100%", height: "500px" }}></div>
                  </>
                }
              </div>
            :
              <div className='box-body' style={{position:"relative"}}>
              <p className='text-center' style={{fontSize:"30px", fontWeight:"bold"}} >
                Permission Denied
              </p>
                  <p className='text-center' style={{fontSize:"16px"}} >
                    Please Contact your Administrator
                  </p>
              </div>
          }
          
        </div>
      </div>
  }
  {
    (pagePermissions(props.pagepermissions, "Dashboard", "sales_graph")) &&
      <div className='col-md-12'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
              Sales 
            </h3>
          </div>
          {
            (pagePermissions(props.pagepermissions, "Dashboard", "sales_graph")) ?
              <div className='box-body' style={{position:"relative"}}>
                {
                  loading ? 
                  <>
                    <Loading />
                    <br /><br /><br /><br />
                  </>
                  :
                  <>
                    <div className='hideChartLogo'></div>
                    <div id="sales" style={{ width: "100%", height: "500px" }}></div>
                  </>
                }
              </div>
            :
              <div className='box-body' style={{position:"relative"}}>
              <p className='text-center' style={{fontSize:"30px", fontWeight:"bold"}} >
                Permission Denied
              </p>
                  <p className='text-center' style={{fontSize:"16px"}} >
                    Please Contact your Administrator
                  </p>
              </div>
          }
          
        </div>
      </div>
  }
  {
    (pagePermissions(props.pagepermissions, "Dashboard", "courses_enrolled_graph")) &&
      
      <div className='col-md-6'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
              Courses Enrollment 
            </h3>
          </div>
          {
            (pagePermissions(props.pagepermissions, "Dashboard", "courses_enrolled_graph")) ?
              <div className='box-body' style={{position:"relative"}}>
                {
                  loading ? 
                  <>
                    <Loading />
                    <br /><br /><br /><br />
                  </>
                  :
                  <>
                    <div className='hideChartLogo'></div>
                    <div id="coursessold" style={{ width: "100%", height: "500px" }}></div>
                  </>
                }
              </div>
            :
              <div className='box-body' style={{position:"relative"}}>
              <p className='text-center' style={{fontSize:"30px", fontWeight:"bold"}} >
                Permission Denied
              </p>
                  <p className='text-center' style={{fontSize:"16px"}} >
                    Please Contact your Administrator
                  </p>
              </div>
          }
          
        </div>
      </div>
  }
  {
    (pagePermissions(props.pagepermissions, "Dashboard", "packages_enrolled_graph")) &&
      <div className='col-md-6'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
              Packages Enrollment 
            </h3>
          </div>
          {
            (pagePermissions(props.pagepermissions, "Dashboard", "packages_enrolled_graph")) ?
              <div className='box-body' style={{position:"relative"}}>
                {
                  loading ? 
                  <>
                    <Loading />
                    <br /><br /><br /><br />
                  </>
                  :
                  <>
                    <div className='hideChartLogo'></div>
                    <div id="packagessold" style={{ width: "100%", height: "500px" }}></div>
                  </>
                }
              </div>
            :
              <div className='box-body' style={{position:"relative"}}>
              <p className='text-center' style={{fontSize:"30px", fontWeight:"bold"}} >
                Permission Denied
              </p>
                  <p className='text-center' style={{fontSize:"16px"}} >
                    Please Contact your Administrator
                  </p>
              </div>
          }
          
        </div>
      </div>
  }
    {
      (pagePermissions(props.pagepermissions, "Dashboard", "new_signups_graph")) &&
      <div className='col-md-12'>
        <div className='box no-border'>
          <div className='box-header'>
            <h3 className='box-title'>
              New Signups
            </h3>
          </div>

          
          {
            (pagePermissions(props.pagepermissions, "Dashboard", "new_signups_graph")) ?
              <div className='box-body' style={{position:"relative"}}>
                {
                  loading ? 
                  <>
                    <Loading />
                    <br /><br /><br /><br />
                  </>
                  :
                  <>
                    <div className='hideChartLogo'></div>
                    <div id="signups" style={{ width: "100%", height: "500px" }}></div>
                  </>
                }
              </div>
            :
              <div className='box-body' style={{position:"relative"}}>
              <p className='text-center' style={{fontSize:"30px", fontWeight:"bold"}} >
                Permission Denied
              </p>
                  <p className='text-center' style={{fontSize:"16px"}} >
                    Please Contact your Administrator
                  </p>
              </div>
          }
          
        </div>
      </div>
    }
      </>
    )
  }
  return (
    <>
      <Sidebar page={Dashboard} permission="Global"/>
    </>
  )
}
