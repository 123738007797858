import React, { useState, useEffect, useRef } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Adminprops from '../../VelidateWebsite/Adminprops'
import Cookies from 'universal-cookie'
import CommonFunc from '../includes/Common'
import Notfound from '../includes/Notfound'
import FeatherIcon from 'feather-icons-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading'


const cookies = new Cookies();
export default function Assignment(props) {
    
    const params = useParams()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, formLoading} = CommonFunc()

    const [id, setID] = useState("")
    const [name, setName] = useState("")
    const [marks, setMarks] = useState("")
    const [file, setFile] = useState("")
    const [available, setAvailable] = useState("1")
    const [from, setFrom] = useState("")
    const [to, setTo] = useState("")
    const [tags, setTags] = useState("")

    const [instruction, setInstruction] = useState("")
    const [instructionEditor, setInstructionEditor] = useState("")
    const [message, setMessage] = useState("")
    const [messageEditor, setMessageEditor] = useState("")
    const [refresh, setRefresh] = useState("No")
    const [assignmentName, setAssignmetName] = useState("")

    const [previewLoading, setPreviewLoading] = useState(false)
    const [grades, setgrades] = useState([
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" },
        { "min" : "", "max" : "", "msg" : "" }
    ])
    

    const clearForm = (data) => {
        
        if((data !== "" && (data.file_data !== "") && (data.file_data !== null))){
            setAssignmetName(JSON.parse(data.file_data)['name'])
        }else{
            setAssignmetName("")
        }
        
        var other = (data !== "") ? JSON.parse(data.assignment_data) : ''
        setName( (data === "") ? "" : data.name)
        setMarks( (data === "") ? "" : data.assignment_marks)
        setFile( (data === "") ? "" : "")
        setAvailable( (data === "") ? "1" : data.availibility)
        setFrom( (data === "" || data.assignment_start === null) ? "" : data.assignment_start)
        setTo( (data === "" || data.assignment_end === null) ? "" : data.assignment_end)
        setInstruction((data === "") ? "" : other.instruction)
        setTags((data === "" || data.tags === null) ? "" : data.tags)
        setInstructionEditor("")
        setMessage( (data === "") ? "" : other.message)
        setMessageEditor("")
        setTimeout(function(){
            setInstructionEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={(data === "") ? "" : other.instruction} onChange={ ( event, editor ) => { const data = editor.getData(); setInstruction(data) } } /> )
            setMessageEditor( <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={(data === "") ? "" : other.message} onChange={ ( event, editor ) => { const data = editor.getData(); setMessage(data) } } /> )
        }, 1000)

    }

const cleanData = () => {
    setID("")
    setName("")
    setMarks("")
    setFile("")
    setFrom("")
    setTo("")
    setTags("")
    setAvailable("")
}

const submitAssignment = (e) => {
    e.preventDefault()
    formLoading(e.target)
    console.log(message)
    const formData = new FormData();
    formData.append('id', id);
    formData.append('p_id',props.p_id);
    formData.append('main_id', props.main_id);

    formData.append('name', name);
    formData.append('type', "Assignment");
    formData.append('tags', tags);
    
    formData.append('marks', marks);
    formData.append('file', file);
    formData.append('availabel', available);
    formData.append('from', from);
    formData.append('to', to);
    formData.append('instruction', instruction);
    formData.append('message', message);
    formData.append('grades', JSON.stringify(grades));

    formData.append('staff', JSON.stringify(AdminBasics()));
    formData.append('accecible', 1);
    formData.append('page_url', window.location.href);

    if(props.from === "Import"){
        formData.append('importing', 1);
        formData.append('content_main_id', props.content_main_id);
        formData.append('content_p_id', props.content_p_id);
    }
    
    
    var url = "add_assignment"
    if(props.type === 'Edit'){
        url = "update_assignment"
        if( props.from === 'Course'){
            url = "update_course_assignment"
            formData.append('id', props.contentid);
        }
    }

    fetch(apiurl + "api/" + url, {
        "method": "POST",
        "headers": {
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": formData
        })
        .then(response => response.json())
        .then(response => { 
            $(e.target).find(".loaderbody").remove()
            if(typeof response.status !== "undefined" && response.status == "OK"){
                successModal("Success", response.message)
                cleanData()
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            // console.log(err);
        });
    
}

const getInfo = () => {
    setPreviewLoading(true)
    fetch(apiurl+"api/single_file_information", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                accecible: 1,
                id: props.assignmentId,
                from: props.from,
                contentid: props.contentid,
                page_url: window.location.href,
                staff: JSON.stringify(AdminBasics())
            })
        })
        .then(response => response.json())
        .then(response => {
            console.log(response)
            if(typeof response.status !== "undefined" && response.status === "OK"){
                clearForm(response.data[0])
                setgrades(
                        response.data[0]['assignment_grades'] === null ?
                        [
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" },
                            { "min" : "", "max" : "", "msg" : "" }
                        ]
                        :
                        JSON.parse(response.data[0]['assignment_grades'])
                    )
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
            setPreviewLoading(false)
        })
        .catch(err => {
            setPreviewLoading(false)
        });
}

const changeColval = (col, index, val) => {
    var temp = grades
    temp[index][col] = val
    setgrades(temp)
    setRefresh(refresh === "No" ? "Yes" : "No")
}

var a = 0
    useEffect(() => {
        if(a === 0){
            if(props.type === "Edit"){
                setID(props.assignmentId)
                getInfo()
            }else{
                clearForm("")
            }
            
        }
    }, [])
    
  return (
    <div className='col-md-12'>
        <form action='' method='POST' onSubmit={submitAssignment} >
            {
                previewLoading &&
                <div style={{width:"100%", height:"100%", position:"absolute", top:0, left:0,zIndex:9,backgroundColor:"#FFF"}}>
                    <Loading />
                </div>
            }
            <div className='box no-border no-shadow'>
                <div className='box-body no-left-padding no-right-padding'>
                    <div className='col-md-4'>
                        <label>
                            Name<span className='text-red'>*</span>
                        </label>
                            <input type={"text"} className={"form-control"} placeholder={"e.g #1 Mock Test -1"} required value={name} onChange={(e) => setName(e.target.value) } />
                    </div>
                    <div className='col-md-4'>
                        <label>
                            Marks<span className='text-red'>*</span>
                        </label>
                            <input type={"number"} className={"form-control"} placeholder={"e.g 100"} required value={marks} onChange={(e) => setMarks(e.target.value) } />
                    </div>
                    {
                        props.from === 'Course' &&
                        <div className='col-md-4'>
                            <label>
                                Availability Settings<span className='text-red'>*</span>
                            </label>
                                <select defaultValue={available} onChange={(e) => setAvailable(e.target.value)} className={"form-control"}>
                                    <option value={"1"}>Always Available</option>
                                    <option value={"Time"}>Time Based</option>
                                </select>
                        </div>
                    }

                    {
                        props.from !== 'Course' &&
                        <div className='col-md-4'>
                            <label>
                                Assignment File
                            </label>
                            {
                                assignmentName !== "" &&
                                <small className='red-text pull-right' style={{color:"green"}} ><strong>{assignmentName}</strong></small>
                            }
                            <input type={"file"} className={"form-control"} accept={".pdf"} onChange={(e) => setFile(e.target.files[0]) } />
                        </div>
                    }

                </div> 
                <div className='box-body no-left-padding no-right-padding'>
                    
                    {
                        props.from !== 'Course' &&
                        <div className='col-md-4'>
                            <label>
                                Availability Settings<span className='text-red'>*</span>
                            </label>
                                <select defaultValue={available} onChange={(e) => setAvailable(e.target.value)} className={"form-control"}>
                                    <option value={"1"}>Always Available</option>
                                    <option value={"Time"}>Time Based</option>
                                </select>
                        </div>
                    }
                    {
                        available === "Time" && 
                        <>
                            <div className='col-md-4'>
                                <label>
                                    Available From<span className='text-red'>*</span>
                                </label>
                                    <input type={"datetime-local"} className={"form-control"} required value={from} onChange={(e) => setFrom(e.target.value) } />
                            </div>
                            <div className='col-md-4'>
                                <label>
                                    Available To<span className='text-red'>*</span>
                                </label>
                                    <input type={"datetime-local"} className={"form-control"} required value={to} min={from} onChange={(e) => setTo(e.target.value) } />
                            </div>
                        </>
                    }

                
                </div>      
                <div className='box-body no-left-padding no-right-padding'>
                    <div className='col-md-12'>
                        <label>Tags</label>
                        <input type='text' className='form-control' placeholder='e.g enter your tags' value={tags} onChange={(e) => setTags(e.target.value)} />
                    </div>
                </div>
                <div className='box-body no-left-padding no-right-padding'>
                    <div className='col-md-12'>
                        <label>Instructions</label>
                            {
                                instructionEditor !== "" ?
                                <div>
                                {instructionEditor}
                                </div>
                                :
                                <>
                                    <br />
                                    <span>Loading...</span>
                                </>
                            }
                    </div>  
                </div>
                <div className='box-body no-left-padding no-right-padding'>
                    <div className='col-md-12'>
                        <label>Confirmation Message</label>
                            {
                                messageEditor !== "" ?
                                <div>
                                    {messageEditor}
                                </div>
                                :
                                <>
                                    <br />
                                    <span>Loading...</span>
                                </>
                            }
                    </div>  
                </div>

                <div className='box-header'>
                    <h3 className='box-title'>
                        Manage Grades
                    </h3>
                    <p>
                        <strong style={{color:"red"}} >
                            Min value input includes the more  than value and
                            Max value input  includes less than value.
                        </strong>
                    </p>
                </div>
                
                    {
                        grades.map((item, index) => {
                            return(
                                <div className='box-body no-left-padding no-right-padding' key={"key"+index} >
                                    <div className='col-md-3'>
                                        <label>Min %</label>
                                        <input type={"number"} value={item.min} className={"form-control"} placeholder={"e.g "+(index*10)} onChange={(e) => changeColval("min", index, e.target.value)} />
                                    </div>
                                    <div className='col-md-3'>
                                        <label>Max %</label>
                                        <input type={"number"} value={item.max} className={"form-control"} placeholder={"e.g "+((index+1)*10)} onChange={(e) => changeColval("max", index, e.target.value)} />
                                    </div>
                                    <div className='col-md-6'>
                                        <label>Message</label>
                                        <input type={"text"} value={item.msg} className={"form-control"} placeholder={"e.g Congratulations"} onChange={(e) => changeColval("msg", index, e.target.value)} />
                                    </div>
                                </div>
                                
                                )
                            })
                        }
                
                <div className='box-body no-left-padding no-right-padding'>
                    <div className='col-md-12'>
                        <button className='btn btn-primary btn-flat btn-block'>
                            <i className='fa fa-save' /> Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
  )
}
