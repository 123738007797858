import React, { useState, useEffect } from 'react'
import $ from "jquery";
import Scripts from './Scripts'
import Footer from './Footer'
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import CommonFunc from './Common';
import Adminprops from '../../VelidateWebsite/Adminprops';
import apiurl from '../../Global';
import Notfound from './Notfound';
import devtools from 'devtools-detect';

// import devtools from './devtools-detect/index.js';

const cookies = new Cookies();
const height = window.innerHeight;
const width = window.innerWidth;
let loggedin = cookies.get('admin')

// var userDefault = process.env.PUBLIC_URL+"/assets/assets/images/user.png";
var userDefault = process.env.PUBLIC_URL+"/favicon.ico";
var loadingDefault = process.env.PUBLIC_URL+"/favicon.ico";

export default function Sidebar(props) {
  
  const [isloadind, setIsloading] = useState(true)
  const [loggedin, setLoggedin] = useState(null)

  const [permissions, setPermissions] = useState("none")
  const [salesCourses, setSalescourses] = useState(null)
  
  const adminMain = cookies.get("admin");
  const navigate = useNavigate()
  const {successModal, pagePermissions, AdminBasics, assets_url} = CommonFunc()
  var a = 0
  const [getNotificationsCount, setNotificationsCount] = useState(0)



  const getPermisisons = () => {
    let getToken = cookies.get('token')
    const adminProps = Adminprops()
    var admin = cookies.get("admin");
    
    fetch(apiurl+"api/staff_list", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                accecible: 1,
                staffdata: admin.id,
                token: getToken,
                pagepermission: props.permission,
                lastactive: 1
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status == "OK"){
                var perm = response.staff.permissions
                
                
                var adminArray = response.staff
                delete adminArray['permissions'];
                delete adminArray['coursepermission'];
                delete adminArray['password'];
                setSalescourses(response.staff.salescourses)
                delete adminArray['salescourses'];
                
                let d = new Date();
                d.setTime(d.getTime() + (60*60*1000*1000));
                cookies.set('admin', JSON.stringify(adminArray), { path: '/', expires:d });

                setLoggedin(adminArray)

                setPermissions(perm)
                setTimeout(function(){
                  window.$("#search-btn").on("keyup", function(){
                    var a = window.$(this).val()
                    // var thisEle = window.$(this)
                    a = a.toLowerCase()
                      window.$(".treeview").each(function(){
                        var b = $(this).attr("data-bind")
                        b = b.toLowerCase()
                        if(b.includes(a)){
                          window.$(this).show()
                        }else{
                          window.$(this).hide()
                        }
                      });
                  })
                }, 2000);

            }else if(response.status === "error"){
                document.cookie = 'admin=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                window.location.href="/admin/login?auto"
            }
            getNotifications();
        })
        .catch(err => {
            // console.log(err);
        });
  }
  const getNotifications = () => {
    let getToken = cookies.get('token')
      fetch(apiurl+"api/getNotificationsCount", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                accecible: 1,
                staff: AdminBasics(),
                page_url: window.location.href,
            })
        })
        .then(response => response.json())
        .then(response => {
          setNotificationsCount(response.count)
        })
        .catch(err => {
        });
  }

  const logout = () => {
    
    let getToken = cookies.get('token')
    const adminProps = Adminprops()
    var admin = cookies.get("admin");
    
    fetch(apiurl+"api/logout", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+getToken
        },
        "body": JSON.stringify({
                accecible: 1,
                staff: AdminBasics(),
                page_url: window.location.href,
            })
        })
        .then(response => response.json())
        .then(response => {
            if(typeof response.status !== "undefined" && response.status == "OK"){
              document.cookie = 'admin=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              document.cookie = 'token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
              window.location.href="/admin/login"
            }
        })
        .catch(err => {
            // console.log(err);
        });
  }
  
  const curl = window.location.href
  
  useEffect(() => {
    if(a == 0){
      // callINs()
      
    // setInterval(function(){
    //   console.log(devtools)
    // },1000)
        // window.checkConsole()
        // setInterval(function(){
        //     window.$("table").parents('.box-body').css("overflow", "auto")
        // }, 2000);
        setLoggedin(cookies.get('admin'))
        let getToken = cookies.get('token')
        if(typeof getToken == "undefined" || getToken == ""){
            navigate("/not-found")
        }else{
          getPermisisons();
        }
        
        a++
    }  


    
    setTimeout(function(){
      setIsloading(false)
    },1000)
  },[])

  return (
    <>
    {
      isloadind &&
        <div style={{width:"100%",height:"100vh",position:"fixed",top:0,left:0,zIndex:99999 ,backgroundColor:"#FFF"}}>
          <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
            <img src={loadingDefault} />
          </div>
      </div>
    }
{
    (typeof loggedin !== "undefined" && loggedin !== "" && loggedin !== null) &&
    <>

    
  <ul className='error_success' id='error_success'>
      
  </ul>

  <div className="wrapper">
    <header className="main-header">
      {/* Logo */}
      <a href={"https://www.escholars.in/"} className="logo" target='_blank'>
        {/* mini logo for sidebar mini 50x50 pixels */}
        <span className="logo-mini"><b>ESC</b></span>
        {/* logo for regular state and mobile devices */}
        <span className="logo-lg"><b>Escholars</b></span>
      </a>
      {/* Header Navbar: style can be found in header.less */}
      <nav className="navbar navbar-static-top">
        {/* Sidebar toggle button*/}
        <a href="#" className="sidebar-toggle" data-toggle="offcanvas" role="button">
          <span className="sr-only">Toggle navigation</span>
        </a>

        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            {/* Notifications: style can be found in dropdown.less */}
            <li className="dropdown notifications-menu"  >
                <a href={'/admin/mynotifications'} className="dropdown-toggle" >
                <i className="fa fa-bell-o"></i>
                <span className="label label-warning">{getNotificationsCount}</span>
              </a>
            </li>
            
      
            {/* Tasks: style can be found in dropdown.less */}
            {/* <li className="dropdown tasks-menu"  >
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <i className="fa fa-users"></i>
                <span className="label label-danger">50</span>
              </a>
            </li> */}
            {/* User Account: style can be found in dropdown.less */}
            <li className="dropdown user user-menu">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                <img src={(loggedin.profile_photo == null || loggedin.profile_photo == "") ? userDefault : assets_url + loggedin.profile_photo } className="user-image" alt="User Image" />
                <span className="hidden-xs">{loggedin.name}</span>
              </a>
              <ul className="dropdown-menu">
                {/* User image */}
                <li className="user-header">
                  <img src={(loggedin.profile_photo == null || loggedin.profile_photo == "") ? userDefault : assets_url + loggedin.profile_photo } className="img-circle" alt="User Image" />

                  <p>
                  {loggedin.name}
                    <small>{loggedin.role} Of Escholars.</small>
                  </p>
                </li>
                {/* Menu Body */}
                
                {/* Menu Footer*/}
                <li className="user-footer">
                  {
                    adminMain !== null && typeof adminMain !== "undefined" &&
                    <div className="pull-left">
                        <a href={"/admin/edit-staff/"+adminMain.id} className="btn btn-default btn-flat">Profile</a>
                    </div>
                  }
                  <div className="pull-right" onClick={() => logout()}>
                      <a href="#" className="btn btn-default btn-flat">Logout</a>
                  </div>
                </li>
              </ul>
            </li>
            {/* Control Sidebar Toggle Button */}
            {/* <li >
              <a href="#" data-toggle="control-sidebar"><i className="fa fa-gears"></i></a>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
    {/* Left side column. contains the logo and sidebar */}
    <aside className="main-sidebar">
      {/* sidebar: style can be found in sidebar.less */}
      <section className="sidebar">
        {/* Sidebar user panel */}
        <div className="user-panel">
          <div className="pull-left image" >
            <img style={{width:"45px", height:"45px"}} src={(loggedin.profile_photo == null || loggedin.profile_photo == "") ? userDefault : assets_url + loggedin.profile_photo } className="img-circle" alt="User Image" />
          </div>
          <div className="pull-left info">
            <p>{loggedin.name}</p>
            <a href="#"><i className="fa fa-circle text-success"></i> Online</a>
          </div>
        </div>
        <form action="#" method="get" className="sidebar-form" onSubmit={(e) => e.preventDefault()}>
          <div className="input-group">
            <input type="text" name="q" className="form-control" id="search-btn" placeholder="Search..." />
                <span className="input-group-btn">
                  <button type="submit" name="search" className="btn btn-flat"><i className="fa fa-search"></i>
                  </button>
                </span>
          </div>
        </form>
        {/* /.search form */}
        {/* sidebar menu: : style can be found in sidebar.less */}
        <ul className="sidebar-menu">
          <li className="header">MAIN NAVIGATION</li>
          <li className={"treeview " + (curl.includes('admin/dashboard') ? "active" : "")} data-bind="Dashboard" >
            <Link to={"/admin/dashboard"}>
              <i className="fa fa-dashboard"></i> <span>Dashboard 
              </span>
            </Link>
          </li>
          
          {
            permissions !== "none" &&
            <>
            
            { 
              (pagePermissions(permissions, "Staff", "show_in_sidebar")) &&
               <li className={"treeview " + (curl.includes('admin/staff') ? "active" : "")} data-bind="Manage Staff">
                 <Link to={"/admin/staff"}>
                   <i className="fa fa-users"></i> <span>Manage Staff </span>
                 </Link>
               </li>
            } 
            { 
              (pagePermissions(permissions, "Permission_Groups", "show_in_sidebar")) &&
               <li className={"treeview " + (curl.includes('admin/permissiongroups') ? "active" : "")} data-bind="Manage Permission Groups">
                 <Link to={"/admin/permissiongroups"}>
                   <i className="fa fa-object-group"></i> <span>Manage Permission Groups </span>
                 </Link>
               </li>
            } 
            { 
              (pagePermissions(permissions, "Banner", "show_in_sidebar")) &&
               <li className={"treeview " + (curl.includes('admin/banner') ? "active" : "")} data-bind="Manage Banners">
                 <Link to={"/admin/banner"} title="Manage Banners">
                   <i className="fa fa-image"></i> <span>Manage Banners</span>
                 </Link>
               </li>
            } 

            { 
                (pagePermissions(permissions, "Categories", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/manage-categories') ? "active" : "")} data-bind="Manage Categories">
                  <Link to={"/admin/manage-categories"}>
                    <i className="fa fa-list"></i> <span>Manage Categories</span>
                  </Link>
                </li>
            }
            { 
              (pagePermissions(permissions, "Assets", "show_in_sidebar")) &&
               <li className={"treeview " + (curl.includes('admin/assets') ? "active" : "")} data-bind="Manage Assets">
                 <Link to={"/admin/assets"} title="Manage Assets">
                   <i className="fa fa-folder"></i> <span>Manage Assets</span>
                 </Link>
               </li>
            } 
            { 
                (pagePermissions(permissions, "Courses", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/manage-courses') ? "active" : "")} data-bind="Manage Courses">
                  <Link to={"/admin/manage-courses"}>
                    <i className="fa fa-book"></i> <span>Manage Course</span>
                  </Link>
                </li>
            }
            
            { 
                  (pagePermissions(permissions, "All_Files", "show_in_sidebar")) &&
                    <li className={"treeview " + (curl.includes('admin/all-files') ? "active" : "")} data-bind="Manage All Files, All Assignments, All Quiz, All Images, All Notes,All Video, All Youtube, All Live Classes">
                      <a >
                        <i className="fa fa-files-o"></i> <span>All Files</span>
                        <span className="pull-right-container">
                          <i className="fa fa-angle-left pull-right"></i>
                        </span>
                      </a>
                      <ul className="treeview-menu menu-open" >
                        {
                          (pagePermissions(permissions, "All_Files", "all_assignments")) &&
                          <li>
                            <Link to={"/admin/all-files/Assignment"}>
                              <i className="fa fa-circle-o"></i> <span>Assignments</span>
                            </Link>  
                          </li>
                        }
                        {
                          (pagePermissions(permissions, "All_Files", "all_quiz")) &&
                        <li>
                          <Link to={"/admin/all-files/Quiz"}>
                            <i className="fa fa-circle-o"></i> <span>Quiz</span>
                          </Link>  
                        </li>
                        }
                        {
                          (pagePermissions(permissions, "All_Files", "all_notes")) &&
                        <li>
                          <Link to={"/admin/all-files/Document"}>
                            <i className="fa fa-circle-o"></i> <span>Notes/Document</span>
                          </Link>  
                        </li>
                        }
                        {
                          (pagePermissions(permissions, "All_Files", "all_video")) &&
                        <li>
                          <Link to={"/admin/all-files/Video"}>
                            <i className="fa fa-circle-o"></i> <span>Videos</span>
                          </Link>  
                        </li>
                        }
                        {
                          (pagePermissions(permissions, "All_Files", "all_video")) &&
                        <li>
                          <Link to={"/admin/all-files/Youtube"}>
                            <i className="fa fa-circle-o"></i> <span>Youtube Videos</span>
                          </Link>  
                        </li>
                        }
                        {
                          (pagePermissions(permissions, "All_Files", "all_images")) &&
                        <li>
                          <Link to={"/admin/all-files/Image"}>
                            <i className="fa fa-circle-o"></i> <span>Images</span>
                          </Link>  
                        </li>
                        }
                        {
                          (pagePermissions(permissions, "All_Files", "all_live")) &&
                        <li>
                          <Link to={"/admin/all-files/Live"}>
                            <i className="fa fa-circle-o"></i> <span>Live Classes</span>
                          </Link>  
                        </li>
                        }
                      </ul>
                    </li>
              }
              { 
                  (pagePermissions(permissions, "Reported_Questions", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/manage-reports') ? "active" : "")} data-bind="Reported Questions">
                    <Link to={"/admin/manage-reports"}>
                      <i className="fa fa-exclamation-triangle"></i> <span>Reported Questions</span>
                    </Link>
                  </li>
              }
            { 
                (pagePermissions(permissions, "Logs", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/logs') ? "active" : "")} data-bind="Logs">
                  <Link to={"/admin/logs"}>
                    <i className="fa fa-key"></i> <span>Logs</span>
                  </Link>
                </li>
            }

            { 
                // (pagePermissions(props.pagepermissions, "Assets", "download_permission")) &&
                <li className={"treeview " + (curl.includes('admin/my-downloads') ? "active" : "")} data-bind="Downloads">
                  <Link to={"/admin/my-downloads"}>
                    <i className="fa fa-download"></i> <span>My Downloads</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Download_Requests", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/download-requests') ? "active" : "")} data-bind="Download Requests">
                  <Link to={"/admin/download-requests"}>
                    <i className="fa fa-download"></i> <span>Download Requests</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Login_Steps", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/login-steps') ? "active" : "")} data-bind="Login Steps">
                  <Link to={"/admin/login-steps"}>
                    <i className="fa fa-list-ol"></i> <span>Login Steps (APP)</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Students", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/manage-students') ? "active" : "")} data-bind="Manage Students">
                  <Link to={"/admin/manage-students"}>
                    <i className="fa fa-user"></i> <span>Manage Students</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Students", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/manage-students-remove') ? "active" : "")} data-bind="Manage Removed Students">
                  <Link to={"/admin/manage-students-remove"}>
                    <i className="fa fa-trash"></i> <span>Remove Requested Students</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Searchable_Students", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/manage-students-limit') ? "active" : "")} data-bind="Manage Students">
                  <Link to={"/admin/manage-students-limit"}>
                    <i className="fa fa-search"></i> <span>Manage Students</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Themes", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/themes') ? "active" : "")} data-bind="Manage Themes">
                  <Link to={"/admin/themes"}>
                    <i className="fa fa-eyedropper"></i> <span>Manage Themes (APP)</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Rules", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/rules') ? "active" : "")} data-bind="Manage Rules & Options">
                  <Link to={"/admin/rules"}>
                    <i className="fa fa-info"></i> <span>Manage Rules & Options</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Orders", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/orders/') ? "active" : "")} data-bind="Manage Orders">
                  <Link to={"/admin/orders/unprocessed"}>
                    <i className="fa fa-opencart"></i> <span>Manage Orders</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Forum", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/forum/manage') ? "active" : "")} data-bind="Manage Forum">
                  <Link to={"/admin/forum/manage"}>
                    <i className="fa fa-rss"></i> <span>Manage Forum</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Reported_Forum_And_Comments", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/forum/reports') ? "active" : "")} data-bind="Manage Forum & Comments">
                  <Link to={"/admin/forum/reports"}>
                    <i className="fa fa-exclamation-triangle"></i> <span>Reported Forum & Comments</span>
                  </Link>
                </li>
            }

            { 
                (pagePermissions(permissions, "Faq", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/faq') ? "active" : "")} data-bind="Faq's">
                  <Link to={"/admin/faq"}>
                    <i className="fa fa-info-circle"></i> <span>Faq's</span>
                  </Link>
                </li>
            }

            { 
                (pagePermissions(permissions, "Chat", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/chat') ? "active" : "")} data-bind="Chat">
                  <Link to={"/admin/chat"}>
                    <i className="fa fa-commenting"></i> <span>Chat</span>
                  </Link>
                </li>
            }
          
            { 
                (pagePermissions(permissions, "Chat", "report_chat")) &&
                <li className={"treeview " + (curl.includes('admin/chat/No') ? "active" : "")} data-bind="Unsatisfied Chats">
                  <Link to={"/admin/chat/No"}>
                    <i className="fa fa-commenting"></i> <span>Unsatisfied Chats</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Sales_Page", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/sales') ? "active" : "")} data-bind="Sales">
                  <Link to={"/admin/sales"}>
                    <i className="fa fa-rupee"></i> <span>Sales</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Usage_Page", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/usage') ? "active" : "")} data-bind="Usage">
                  <Link to={"/admin/usage"}>
                    <i className="fa fa-bar-chart"></i> <span>Usage</span>
                  </Link>
                </li>
            }
            { 
                (pagePermissions(permissions, "Daily_Live_Content", "show_in_sidebar")) &&
                <li className={"treeview " + (curl.includes('admin/live_usage') ? "active" : "")} data-bind="Daily Live Content">
                  <Link to={"/admin/live_usage"}>
                    <i className="fa fa-bar-chart"></i> <span>Daily Live Content</span>
                  </Link>
                </li>
            }
              
              { 
                  (pagePermissions(permissions, "Messanger", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/messages') ? "active" : "")} data-bind="Messanger">
                    <Link to={"/admin/messages"}>
                      <i className="fa fa-commenting"></i> <span>Messanger</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "Notifications", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/notifications') ? "active" : "")} data-bind="Notifications">
                    <Link to={"/admin/notifications"}>
                      <i className="fa fa-bell"></i> <span>Notifications</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "Blogs", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/blogs') ? "active" : "")} data-bind="Manage Blogs">
                    <Link to={"/admin/blogs"}>
                      <i className="fa fa-pencil-square-o"></i> <span>Manage Blogs</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "Blogs", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/pages') ? "active" : "")} data-bind="Manage Pages">
                    <Link to={"/admin/pages"}>
                      <i className="fa fa-newspaper-o"></i> <span>Manage Pages</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "Promocodes", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/promocodes') ? "active" : "")} data-bind="Manage Promocodes">
                    <Link to={"/admin/promocodes"}>
                      <i className="fa fa-qrcode"></i> <span>Manage Promocodes</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "All_Test_Attempts", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/all-attempts') ? "active" : "")} data-bind="All Test Attempts">
                    <Link to={"/admin/all-attempts"}>
                      <i className="fa fa-pencil"></i> <span>All Test Attempts</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "Doubts", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/doubts') ? "active" : "")} data-bind="Manage Doubts">
                    <Link to={"/admin/doubts"}>
                      <i className="fa fa-question-circle"></i> <span>Manage Doubts</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "Feedbacks", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/feedbacks') ? "active" : "")} data-bind="Feedback/Testimonials">
                    <Link to={"/admin/feedbacks"}>
                      <i className="fa fa-users"></i> <span>Feedback/Testimonials</span>
                    </Link>
                  </li>
              }
              { 
                  (pagePermissions(permissions, "Social_Links", "show_in_sidebar")) &&
                  <li className={"treeview " + (curl.includes('admin/social-links') ? "active" : "")} data-bind="Social Media">
                    <Link to={"/admin/social-links"}>
                      <i className="fa fa-facebook"></i> <span>Social Media</span>
                    </Link>
                  </li>
              }
              { 
                  // (pagePermissions(permissions, "Manaege_Enquiries", "show_in_sidebar")) &&
                  // <li className={"treeview " + (curl.includes('admin/dashboard') ? "active" : "")}>
                  //   <Link to={"/admin/manage-enquiries"}>
                  //     <i className="fa fa-commenting"></i> <span>Manage Enquiries</span>
                  //   </Link>
                  // </li>
              }
          
            </>
          }



        </ul>
      </section>
      {/* /.sidebar */}
    </aside>
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>
          Dashboard
          <small>Control panel</small>
        </h1>
        <ol className="breadcrumb">
          <li><a href="#"><i className="fa fa-dashboard"></i> Home</a></li>
          <li className="active">Dashboard</li>
        </ol>
      </section>
        <section className="content" style={{minHeight:(height-150)+"px"}}>
            <div className="row">
              
                {
                    permissions != "none" &&
                    pagePermissions(permissions, props.permission, "show_in_sidebar") ?
                    <>
                    <props.page pagepermissions={permissions} salescourses={salesCourses} />
                    </>
                    :
                    <>
                    <Notfound />
                    </>
                }
            </div>
          </section>
      </div>
    </div>

    
      <Footer />
      </>
}
      </>
  )
}
