import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { Link, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Sidebar from '../includes/Sidebar';

const cookies = new Cookies();

function printimg(files){
    const obj = URL.createObjectURL(files)
    return obj;
}

function getCourseName(arr, id){
    
    var filteredArray = arr.filter(function(item){
        return item.otherid.indexOf(id) > -1
    })
    if(filteredArray.length < 1){
        return "Root";
    }else{
        return filteredArray[0]['name'];
    }
}

const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }

export default function Singlechat (props) {

    
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url, formLoading, pagePermissions} = CommonFunc()
    const [loading, setLoading] = useState(false)
    let getToken = cookies.get('token')
    const adminData = AdminBasics()
    const [groups, setGroups] = useState([])
    const [chatid, setChatId] = useState(null)
    const [messages, setMessages] = useState([])
    const [msgLoading, setMsgLoading] = useState(false)
    const [msgbox, setMsgBox] = useState(null)
    const [msg, setMsg] = useState("")
    const [sending, setSending] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)
    const [courseslist, setCourseslist] = useState([])
    const [refresh, setRefresh] = useState("No")
    const [chatstatus, setChatstatus] = useState("Active")
    const [filter, setFilter] = useState({
        start: "",
        end: "",
        active: "",
        userid: "",
        user: "",
        chatid: "",
        readunread: "",
        courseid: (!props.allcourse && params.id !== undefined) ?  params.id : "",
        feedback: (typeof params.resp !== "undefined" && params.resp !== null) ? params.resp : ""
    })
    const [filterLoading, setFilterLoading] = useState(false)


    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        // if(col !== "chatid"){
            // getGroups()
            // setFilterLoading(true)
        // }
        
    }
      
      
    const restoreMsg = (id, index) => {
        fetch(apiurl+"api/restoreMsg", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                msgid: id
            })
            })
            .then(response => response.json())
            .then(response => { 
                successModal("Success", response.message)
                var temp = messages
                temp[index]['removed'] = null
                setMessages(temp)
                setRefresh(refresh === "No" ? "Yes" : "No")
            })
    }


    const deleteMsg = (id, index) => {
        fetch(apiurl+"api/removeMsg", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                msgid: id
            })
            })
            .then(response => response.json())
            .then(response => { 
                successModal("Success", response.message)
                var temp = messages
                temp[index]['removed'] = 1
                setMessages(temp)
                setRefresh(refresh === "No" ? "Yes" : "No")
            })
    }

    const getGroups = (e) => {
        fetch(apiurl+"api/getgroupsAdmin", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                filter: filter
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setGroups(response.groups)
                    setCourseslist(response.courses)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
                setFilterLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setFilterLoading(false)
                // console.log(err);
            });
    }

    
    const sendMessage = (e) => {
        setSending(true)

        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('message', msg);
        formData.append('cid', chatid);
        formData.append('reply', 0);
        formData.append('sender', 'Staff');

        if(selectedImage !== null) {
            formData.append('files', selectedImage);
        }


        fetch(apiurl+"api/sendmessage", {
            "method": "POST",
            "headers": {
                // "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    getMessages(chatid)
                    setMsg("")
                    setSelectedImage(null)
                    setTimeout(function(){
                        getGroups()
                    }, 1000);
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setSending(false)
            })
            .catch(err => {
                setSending(false)
                // console.log(err);
            });
    }

    const getMessagesPre = (item) => {
        setChatId(item.chatid)
        setMsgLoading(true)
        setMsgBox(null)
        getMessages(item.chatid)
    }

    const getMessages = (chatid) => {
        
        fetch(apiurl+"api/getmessagesAdmin", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                chatid: chatid,
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setMessages(response.chat)
                    setChatstatus(response.chatgroup[0]['active'])
                    setMsgBox(
                        <CKEditor editor={ ClassicEditor } config={{ simpleUpload: { uploadUrl: apiurl+'api/ckeditor/image_upload', }, }} data={msg} onChange={ ( event, editor ) => { const data = editor.getData(); setMsg(data) } } />
                    )
                    setTimeout(function(){
                        var objDiv = document.getElementById("chatbox");
                        objDiv.scrollTop = objDiv.scrollHeight;
                    },500)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setMsgLoading(false)
            })
            .catch(err => {
                setMsgLoading(false)
                // console.log(err);
            });
    }

    const filterFeedbacks = (e) => {
        e.preventDefault();
        getGroups()
        setFilterLoading(true)
    }

    const closeChat = () => {
        fetch(apiurl+"api/closeThisChat", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                chatid: chatid,
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Succcess", response.message)
                    getMessages(chatid)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setMsgLoading(false)
            })
            .catch(err => {
                setMsgLoading(false)
                // console.log(err);
            });
    }


    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        var temp = groups
        const th = ["Student Name", "Student Email", "Student Contact", "Student ID", "Chat ID", "First Message Date", "Last Message Date", "Status"]
        var td = [];
        for(var i = 0;i<temp.length;i++){
            
            var udata = JSON.parse(temp[i]['udata'])
            var lastmsg = JSON.parse(temp[i]['lastmsg'])
            moment.locale('en');
            var ct = temp[i]['created_at'];
            
            td.push(
                [
                    udata['name'], udata['email'], udata['contact'], temp[i]['uid'], temp[i]['chatid'], moment(ct).format("D-M-Y HH:mm:ss"), lastmsg.date, temp[i]['active']
                ]
            )
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Chat Groups List of ("+td.length+") Entries"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }
    const downloadTranscript = () => {
        setDownloading(true)
        var temp = messages
        const th = ["Student Name", "Student Message", "Team Name", "Team Message", "Path", "Date"]
        var td = [];
        for(var i = 0;i<temp.length;i++){
            var udata = JSON.parse(temp[i]['udata'])
            var path = (temp[i]['path'] !== null) ? JSON.parse(temp[i]['path']) : null
            moment.locale('en');
            var ct = temp[i]['created_at'];

            var pathstring = (path !== null) ? getCourseName(courseslist, path.course_id) : ""
            if(path !== null){
                for(var k = 0;k<path.length;k++){
                    pathstring += path[k]['name']+"/ "
                }
            }

            td.push(
                [
                    temp[i]['sender'] === "Student" ? udata['name'] : '-', 
                    temp[i]['sender'] === "Student" ? temp[i]['message'].replace(/<[^>]*>?/gm, '') : '-', 
                    temp[i]['sender'] === "Staff" ? udata['name'] : '-', 
                    temp[i]['sender'] === "Staff" ? temp[i]['message'].replace(/<[^>]*>?/gm, '') : '-', 
                    pathstring, moment(ct).format("D-M-Y HH:mm:ss")
                ]
            )
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Chat Script of ("+td.length+") Messages With Chat ID:- "+chatid
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    const clearFilter = (e) => {
        var temp = filter
        temp = {
            start: filter.start,
            end: filter.end,
            active: "",
            userid: "",
            user: "",
            chatid: "",
            readunread: "",
            courseid: (!props.allcourse && params.id !== undefined) ?  params.id : "",
            feedback: (typeof params.resp !== "undefined" && params.resp !== null) ? params.resp : ""
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }

    var a = 0
    useEffect(() => {
        if(a === 0) {
            setLoading(true)
            setChatId(null)
            setMsgBox(null)
            getGroups()
            a++
        }
    }, [])

    return(
        <>  
        {
             chatid === null &&
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filter Chat Groups
                        </h3>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                        <div style={{float:"right", overflow:"hidden", color:"red"}} >
                            {
                                filterLoading  &&  "Applying Filters..."
                            }
                        </div>
                    </div>
                    <form action='' method='POST' onSubmit={(e) => filterFeedbacks(e)} >
                        <div style={{overflow:"hidden"}}>
                            <div className='box-body col-md-6'>
                                <label>Date of Chat Start (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                            </div>   
                            <div className='box-body col-md-6'>
                                <label>Date of Chat Start (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Chat Status</label>
                                <select className='form-control' defaultValue={filter.active} onChange={(e) => changeValue(e.target.value, "active")} >
                                    <option value={""}>Any</option>
                                    <option value={"Active"}>Active</option>
                                    <option value={"Inactive"}>Inactive</option>
                                    <option value={"Pending Review"}>Pending Review</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Read Status</label>
                                <select className='form-control' defaultValue={filter.readunread} onChange={(e) => changeValue(e.target.value, "readunread")} >
                                    <option value={""}>Any</option>
                                    <option value={"Readed"}>Readed</option>
                                    <option value={"Unreaded"}>Unreaded</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>User ID</label>
                                <input type={"text"} className={"form-control"} value={filter.userid} onChange={(e) => changeValue(e.target.value, "userid")} placeholder={"e.g 167212121"} />
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>User Name, Email, Contact</label>
                                <input type={"text"} className={"form-control"} value={filter.user} onChange={(e) => changeValue(e.target.value, "user")} placeholder={"e.g Shivam"} />
                            </div> 
                            <div className={(typeof params.resp === "undefined" || params.resp === null) ? 'box-body col-md-6' : 'box-body col-md-12'}>
                                <label>Chat ID</label>
                                <input type={"text"} className={"form-control"} value={filter.chatid} onChange={(e) => changeValue(e.target.value, "chatid")} placeholder={"e.g 167212121"} />
                            </div> 
                            {
                                (typeof params.resp === "undefined" || params.resp === null) &&
                                <div className='box-body col-md-6'>
                                    <label>Feedback Response</label>
                                    <select className='form-control' defaultValue={filter.feedback} onChange={(e) => changeValue(e.target.value, "feedback")} >
                                        <option value={""}>Any</option>
                                        <option value={"Yes"}>Yes</option>
                                        <option value={"No"}>No</option>
                                    </select>
                                </div> 
                            }
                            {
                                props.allcourse &&
                                <div className='box-body col-md-12'>
                                    <label>Select Course</label>
                                    <select className='form-control' defaultValue={filter.courseid} onChange={(e) => changeValue(e.target.value, "courseid")} >
                                        <option value={""}>Any</option>
                                        {
                                            courseslist.length > 0 &&
                                            courseslist.map((item, index) => {
                                                return(
                                                    <option key={"op"+index} value={item.otherid.toString()}>{item.name}</option>
                                                )
                                            })
                                        }
                                        
                                    </select>
                                </div> 
                            }
                            <div className='box-footer'>
                                <button className='btn btn-flat btn-primary btn-block'>
                                    <i className='fa fa-search'></i> &nbsp;
                                    Search
                                </button>    
                            </div>   
                        </div>
                    </form>
                </div>
            </div>
        }
            
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Chat Groups
                        </h3>

                        {
                            groups.length > 0 &&
                            <>
                            {!downloading ?
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>}
                            </>
                        }
                    </div>
                    
                    {
                        loading ?
                        <div className='box-body' style={{position:"relative"}} >
                            <>
                                <Loading />
                                <br /><br />
                                <br /><br />
                            </>
                        </div>
                        :
                        <>
                            {
                                groups.length < 1 &&
                                <div className='box-body'>
                                    <p >No Chats Found</p>
                                </div>
                            }
                            {
                                groups.length > 0 &&
                                groups.map((item, index) => {
                                    var udata = JSON.parse(item.udata)
                                    var lastmsg = JSON.parse(item.lastmsg)
                                    var chatStatus = " btn-success"
                                    if(item.active === "Pending Review"){
                                        chatStatus = " btn-warning"
                                    }else if(item.active === "Inactive"){
                                        chatStatus = " btn-danger"
                                    }
                                    // filter.chatid
                                    return(
                                        <div className={"box-header with-border cursor-pointer opacityhover"} onClick={() => getMessagesPre(item)} 
                                        style={{backgroundColor: (chatid === item.chatid) ? "#f7f7f7" : "#FFF", fontWeight:(item.adminread === null) ? "bold" : "normal", opacity: (item.active === "Inactive") ? 0.5 : 1 }}
                                        key={"groups"+index}
                                        >
                                            <div className="user-block">
                                                {
                                                    udata.image !== null && udata.image !== "" ?
                                                    <img className="img-circle" src={assets_url + udata.image} alt="User Image" />
                                                    :
                                                    <div style={{
                                                        width:"40px", height:"40px", borderRadius:"40px", overflow:'hidden',
                                                        display:"flex", justifyContent:"center", alignItems:"center", float:'left',
                                                        backgroundColor: colors[udata.name[0].toLowerCase()]+"29", color:colors[udata.name[0].toLowerCase()],
                                                        fontSize:"20px"
                                                        }}>
                                                            <strong>
                                                                {udata.name[0].toUpperCase()}
                                                            </strong>
                                                    </div>
                                                }
                                                
                                                <span className="username"><a >{udata.name} <sup>(#{item.chatid})</sup></a></span>
                                                <span className="description">Last Msg - {lastmsg.date}</span>
                                            </div>
                                            <div className="box-tools">

                                                <button type="button" style={{color:"#FFF"}} className={"btn btn-box-tool"+chatStatus} ><i className="fa fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                </div>
            </div>

        {
            chatid !== null &&
            <div className="col-md-8">
                
                <div className="box no-border direct-chat direct-chat-primary">
                    <div className="box-header with-border">
                    <h3 className="box-title">#{chatid}</h3>

                    <div className="box-tools pull-right">
                        
                    {
                            groups.length > 0 &&
                            <>
                            {!downloading ?
                            <button className='btn btn-box-tool' onClick={() => downloadTranscript()} >
                                Download Transcript
                            </button>
                            :
                            <button className='btn btn-box-tool' >
                                Preparing...
                            </button>}
                            </>
                        }

                        <button type="button" style={{backgroundColor:"transparent"}} className="btn btn-box-tool" onClick={() => {
                            getMessages(chatid)
                            warningModal("Loading", "Refreshing Chat...")
                        }}  ><i className="fa fa-refresh"></i></button>

                        <button type="button" style={{backgroundColor:"transparent"}} className="btn btn-box-tool" onClick={() => {
                            setChatId(null)
                            setMsgLoading(false)
                            setMsgBox(null)
                            setSelectedImage(null)
                        }}  ><i className="fa fa-times"></i></button>
                        
                    </div>

                    </div>
                    <div className="box-body" style={{position:"relative"}}>
                        {
                            msgLoading ? 
                            <>
                                <Loading />
                                <br /><br />
                                <br /><br />
                                <br /><br />
                            </>
                            :
                            <>
                            {
                                messages.length < 1 ?
                                <p style={{padding:"10px"}} >No Messages Found</p>
                                :
                                <div className="direct-chat-messages" id='chatbox' style={{maxHeight:"calc(100vh - 350px)", height:"auto"}}>
                                    {
                                        messages.map((item, index) => {
                                            var udata = JSON.parse(item.udata)
                                            var files = (item.files !== null) ? JSON.parse(item.files) : null
                                            var reply = (item.replydata !== null) ? JSON.parse(item.replydata) : null
                                            var path = (item.path !== null) ? JSON.parse(item.path) : null
                                            moment.locale('en');
                                            var ct = item.created_at;
                                            var removedBy = (item.removedby === null) ? null : JSON.parse(item.removedby)
                                            var restoredBy = (item.restoredby === null) ? null : JSON.parse(item.restoredby)
                                            return(
                                                // item.sender === "Student" ?
                                                    <div className={(item.sender === "Student" ? "direct-chat-msg" : "direct-chat-msg right") + " opacityhover"} key={"msg"+index} 
                                                        style={{
                                                            opacity: (item.removed === 1) ? 0.5 : 1
                                                        }} 
                                                    >
                                                        <div className="direct-chat-info clearfix" style={{
                                                            display:"flex", alignItems:"center", justifyContent:"space-between", marginBottom:"10px"
                                                        }}>
                                                            
                                                            <span className={item.sender === "Student" ? "direct-chat-name pull-left" : "direct-chat-name pull-right"} style={{width:"100%"}} >{udata.name}</span>
                                                            <div  style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                                                
                                                                <span className={item.sender === "Student" ? "direct-chat-timestamp pull-right" : "direct-chat-timestamp pull-left"}>
                                                                    {moment(ct).format("D-M-Y HH:mm:ss A")}
                                                                </span>
                                                                {
                                                                    (pagePermissions(props.pagepermissions, "Chat", "remove_msg") || (adminData.id === item.uid && item.sender === "Staff")) &&
                                                                    <>
                                                                    {
                                                                        item.removed === null ?
                                                                        <span className='bg-red pull-right badge' title='Remove Message' style={{marginLeft:5}} onClick={() => deleteMsg(item.id, index)} >
                                                                            <i class="fa fa-trash"></i>
                                                                        </span>
                                                                        :
                                                                        <span className='bg-yellow pull-right badge' title='Restore Message' style={{marginLeft:5}} onClick={() => restoreMsg(item.id, index)} >
                                                                            <i class="fa fa-refresh"></i>
                                                                        </span>
                                                                    }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        
                                                        {
                                                            udata.image !== null && udata.image !== "" ?
                                                            <img className="direct-chat-img" src={assets_url + udata.image} alt="User Image" />
                                                            :
                                                            <div style={{
                                                                width:"40px", height:"40px", borderRadius:"40px", overflow:'hidden',
                                                                display:"flex", justifyContent:"center", alignItems:"center", float:item.sender === "Student" ? 'left' : "right",
                                                                backgroundColor: colors[udata.name[0].toLowerCase()]+"29", color:colors[udata.name[0].toLowerCase()],
                                                                fontSize:"20px"
                                                                }}>
                                                                    <strong>
                                                                        {udata.name[0].toUpperCase()}
                                                                    </strong>
                                                            </div>
                                                        }
                                                        
                                                        <div className="direct-chat-text">
                                                        {
                                                            reply !== null &&
                                                            <div style={{backgroundColor:"rgba(0,0,0,0.1)", padding:"10px", borderRadius:"10px", marginBottom:"10px"}} >
                                                                {
                                                                    reply.files !== null && reply.files !== "" &&
                                                                    <img src={assets_url + reply.files[0]['path']} style={{
                                                                        width:"100%", height:"auto", objectFit:"contain", borderRadius:"5px", marginBottom:"10px",
                                                                        maxWidth:"300px"
                                                                    }} 
                                                                    data-fancybox="gallery" data-src={assets_url + reply.files[0]['path']}
                                                                    />
                                                                }
                                                                {
                                                                    reply.message !== null &&
                                                                    <div dangerouslySetInnerHTML={{__html: reply.message }} className="ck-content" />
                                                                }
                                                            </div>
                                                        }
                                                        {
                                                            files !== null &&
                                                                <img src={assets_url + files[0]['path']} style={{
                                                                    width:"100%", height:"auto", objectFit:"contain", borderRadius:"5px", maxWidth:"300px", marginBottom:"10px"
                                                                }} 
                                                                data-fancybox="gallery" data-src={assets_url + files[0]['path']}
                                                                />
                                                        }
                                                        {
                                                            item.message !== null &&
                                                            <div dangerouslySetInnerHTML={{__html: item.message}} className={"ck-content"} ></div>
                                                        }
                                                        </div>
                                                        
                                                        {
                                                            item.sender === "Student" && path !== null &&
                                                            <div className="direct-chat-text">
                                                                {
                                                                    typeof path.course_id !== "undefined" &&
                                                                        <>
                                                                            <strong>
                                                                                File Path: &nbsp;
                                                                                <a href={'/admin/course-content/'+path.course_id+'/0'} target="_blank" >({getCourseName(courseslist, path.course_id)}): </a>
                                                                                {
                                                                                    path.pathList.length > 0 &&
                                                                                    path.pathList.map((item2, index2) => {
                                                                                        var createlink = false
                                                                                        if(path.filetype === "Folder"){ 
                                                                                            createlink = true 
                                                                                        }else{
                                                                                            if((index2+1) !== path.pathList.length){
                                                                                                createlink = true 
                                                                                            }
                                                                                        }

                                                                                        return(
                                                                                            createlink ?
                                                                                                <a href={'/admin/course-content/'+path.course_id+'/'+item2.id} key={"course"+index2} target={"_blank"}>
                                                                                                    {item2.name}/ &nbsp;
                                                                                                </a>
                                                                                            :
                                                                                            <span key={"course"+index2}>
                                                                                                {item2.name}/ &nbsp;
                                                                                            </span>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </strong>
                                                                        </>
                                                                }
                                                            </div>
                                                        }

                                                        {
                                                            pagePermissions(props.pagepermissions, "Chat", "view_remove__restore_trail") &&
                                                            (removedBy !== null || restoredBy !== null) &&
                                                                <div className="direct-chat-text" style={{overflow:"hidden"}}>
                                                                    <div className='col-md-6'>
                                                                        <table id="staff_table" className="table table-bordered table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Removed By</th>
                                                                                </tr>
                                                                                {
                                                                                    removedBy !== null &&
                                                                                    removedBy.length > 0 &&
                                                                                    removedBy.map((rem, index) => {
                                                                                        return(
                                                                                            <tr key={"rem" + index} >
                                                                                                <td>
                                                                                                    <Link to={"admin/staff?id=" + rem.id} title={"View Profile"} target='_blank' >
                                                                                                        <strong>{rem.name} ({rem.role})</strong><br />
                                                                                                        <small>{rem.email}</small>
                                                                                                    </Link>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </thead>
                                                                        </table>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <table id="staff_table" className="table table-bordered table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Restored By</th>
                                                                                </tr>
                                                                                {
                                                                                    restoredBy !== null &&
                                                                                    restoredBy.length > 0 &&
                                                                                    restoredBy.map((rest, index) => {
                                                                                        return(
                                                                                            <tr key={"rem" + index} >
                                                                                                <td>
                                                                                                    <Link to={"admin/staff?id=" + rest.id} title={"View Profile"} target='_blank' >
                                                                                                        <strong>{rest.name} ({rest.role})</strong><br />
                                                                                                        <small>{rest.email}</small>
                                                                                                    </Link>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </thead>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                        }
                                                            </div>
                                                // :

                                                // <div className="direct-chat-msg right" key={"msg"+index}>
                                                //     <div className="direct-chat-info clearfix">
                                                //         <span className="direct-chat-name pull-right">Sarah Bullock</span>
                                                //         <span className="direct-chat-timestamp pull-left">23 Jan 2:05 pm</span>
                                                //     </div>
                                                //     <img className="direct-chat-img" src="../dist/img/user3-128x128.jpg" alt="Message User Image" />
                                                //     <div className="direct-chat-text">
                                                //         You better believe it!
                                                //     </div>
                                                // </div>
                                                
                                                )
                                            })
                                        }
                                </div>
                            }
                            </>
                    }
                    </div>
                    {
                        msgbox !== null && chatstatus === "Active" &&
                        <>
                            <div className="box-footer">
                                {msgbox}
                            </div>
                            {
                                selectedImage === null ?
                                <div className="box-footer">
                                    <input type={"file"} className="form-control"  onChange={(e) => setSelectedImage(e.target.files[0])} accept=".png, .jpg, .jpeg, .gif, .svg" />
                                </div>
                                :
                                <div className="box-footer">
                                    <div className='col-md-8 no-left-padding'>
                                        <input type={"file"} className="form-control"  onChange={(e) => setSelectedImage(e.target.files[0])} accept=".png, .jpg, .jpeg, .gif, .svg" />
                                    </div>
                                    <div className='col-md-4 no-right-padding' style={{textAlign:"center", position:"relative", overflow:"hidden"}} >
                                        <span style={{
                                            display:"block", position:"absolute", top:"10px", right:"10px", padding:"5px", backgroundColor:"red", color:"#FFF", borderRadius:"5px", zIndex:9,
                                            cursor:"pointer"
                                        }} 
                                        onClick={() => setSelectedImage(null)}
                                        >
                                            <i className='fa fa-times'></i>
                                        </span>
                                        <img src={printimg(selectedImage)}  style={{width:"100%", height:"auto", objectFit:"contain"}} />
                                    </div>
                                </div>
                            }
                            <div className="box-footer">
                                {
                                    sending ?
                                        <button className='btn btn-block btn-success btn-flat' >Sending Message...</button>
                                    :
                                        <button className='btn btn-block btn-primary btn-flat' onClick={() => sendMessage()} >Send Message</button>
                                }
                                
                            </div>
                            <div className="box-footer">
                                <p className='text-center'>OR</p>
                                <button className='btn btn-flat btn-block btn-warning' onClick={() => closeChat()} >Close Chat</button>
                            </div>
                            
                        </>
                    }
                    {
                        chatstatus === "Inctive" &&
                        <div className="box-footer">
                            <button className='btn btn-flat btn-block btn-danger' >This Chat is Closed</button>
                        </div>
                    }
                    {
                        chatstatus === "Pending Review" &&
                        <div className="box-footer">
                            <button className='btn btn-flat btn-block btn-warning' >This Chat is Closed & Waiting For Student Feedback</button>
                        </div>
                    }
                    
                </div>
            </div>
        }
        </>
    )
  }
  