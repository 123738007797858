import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Sidebar from '../includes/Sidebar';

const cookies = new Cookies();

function printimg(files){
    const obj = URL.createObjectURL(files)
    return obj;
}

function getCourseName(arr, id){
    
    var filteredArray = arr.filter(function(item){
        return item.otherid.indexOf(id) > -1
    })
    if(filteredArray.length < 1){
        return "Root";
    }else{
        return filteredArray[0]['name'];
    }
}

const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
export default function Doubts() {
  const Doubtspage = (props) => {

    
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url, formLoading, pagePermissions} = CommonFunc()
    const [loading, setLoading] = useState(false)
    let getToken = cookies.get('token')
    const [courseslist, setCourseslist] = useState([])
    const [doubts, setDoubts] = useState([])
    const [refresh, setRefresh] = useState("No")
    const [segment, setSegment] = useState(0)
    const [filter, setFilter] = useState({
        start: "",
        end: "",
        userid: "",
        user: "",
        courseid: "",
        sort: "DESC",
    })
    const [filterLoading, setFilterLoading] = useState(false)


    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        // if(col !== "chatid"){
            // getGroups()
            // setFilterLoading(true)
        // }
        
    }
      
    const getGroups = (e) => {
        fetch(apiurl+"api/getDoubts", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                filter: filter
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setDoubts(response.doubts)
                    setCourseslist(response.courses)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
                setFilterLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setFilterLoading(false)
                // console.log(err);
            });
    }

    
    const filterFeedbacks = (e) => {
        e.preventDefault();
        getGroups()
        setFilterLoading(true)
    }

    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["Path", "Count"]
        var segmentTemp = doubts
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = doubts[j]
            for(var i = 0;i<temp.length;i++){
                var path = (temp[i]['path'] !== null) ? JSON.parse(temp[i]['path']) : null
                var pathstring = (path !== null) ? getCourseName(courseslist, path.course_id) : ""
                if(path !== null){
                    for(var k = 0;k<path.length;k++){
                        pathstring += path[k]['name']+"/ "
                    }
                }
                td.push(
                    [
                        pathstring, temp[i]['total']
                    ]
                )
            }
        }
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download Doubts List of ("+td.length+") Entries"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }


    const clearFilter = (e) => {
        var temp = filter
        temp = {
            start: filter.start,
            end: filter.end,
            userid: "",
            user: "",
            courseid: "",
            sort: "DESC",
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }
    var a = 0
    useEffect(() => {
        if(a === 0) {
            setLoading(true)
            getGroups()
            a++
        }
    }, [])

    return(
        <>  
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filter Doubts
                        </h3>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                        <div style={{float:"right", overflow:"hidden", color:"red"}} >
                            {
                                filterLoading  &&  "Applying Filters..."
                            }
                        </div>
                    </div>
                    <form action='' method='POST' onSubmit={(e) => filterFeedbacks(e)} >
                        <div style={{overflow:"hidden"}}>
                            <div className='box-body col-md-6'>
                                <label>Date (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                            </div>   
                            <div className='box-body col-md-6'>
                                <label>Date (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                            </div> 
                            <div className='box-body col-md-12'>
                                <label>User Name, Email, Contact</label>
                                <input type={"text"} className={"form-control"} value={filter.user} onChange={(e) => changeValue(e.target.value, "user")} placeholder={"e.g Shivam"} />
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>User ID</label>
                                <input type={"text"} className={"form-control"} value={filter.userid} onChange={(e) => changeValue(e.target.value, "userid")} placeholder={"e.g 167212121"} />
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Sort</label>
                                <select className='form-control' value={filter.sort} onChange={(e) => changeValue(e.target.value, "sort")} >
                                    <option value={"ASC"}>Ascending Order</option>
                                    <option value={"DESC"}>Descending Order</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-12'>
                                <label>Select Course</label>
                                <select className='form-control' value={filter.courseid} onChange={(e) => changeValue(e.target.value, "courseid")} >
                                    <option value={""}>Any</option>
                                    {
                                        courseslist.length > 0 &&
                                        courseslist.map((item, index) => {
                                            return(
                                                <option key={"op"+index} value={item.otherid.toString()}>{item.name}</option>
                                            )
                                        })
                                    }
                                    
                                </select>
                            </div> 
                            <div className='box-footer'>
                                <button className='btn btn-flat btn-primary btn-block'>
                                    <i className='fa fa-search'></i> &nbsp;
                                    Search
                                </button>    
                            </div>   
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Doubts List
                        </h3>
                        {
                            !downloading ?
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>
                        }
                    </div>
                    <div className='box-body'>
                        <table id="staff_table" className="table file_manager_table">
                            <thead>
                                <tr>
                                    <th>
                                        S.No
                                    </th>
                                    <th>
                                        Path
                                    </th>
                                    <th>
                                        Count
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    typeof doubts[segment] !== "undefined" &&
                                    doubts[segment].length > 0 &&
                                    doubts[segment].map((item, index) => {
                                        var path = (item.path !== null) ? JSON.parse(item.path) : null
                                        return(
                                            <tr key={"index" + index} >
                                                <td>
                                                {(index+1)}
                                                </td>
                                                <td>
                                                    {
                                                        path !== null &&
                                                        <div >
                                                                {
                                                                    typeof path.course_id !== "undefined" &&
                                                                        <>
                                                                            <strong>
                                                                                File Path: &nbsp;
                                                                                <a href={'/admin/course-content/'+path.course_id+'/0'} target="_blank" >({getCourseName(courseslist, path.course_id)}): </a>
                                                                                {
                                                                                    path.pathList.length > 0 &&
                                                                                    path.pathList.map((item2, index2) => {
                                                                                        var createlink = false
                                                                                        if(path.filetype === "Folder"){ 
                                                                                            createlink = true 
                                                                                        }else{
                                                                                            if((index2+1) !== path.pathList.length){
                                                                                                createlink = true 
                                                                                            }
                                                                                        }

                                                                                        return(
                                                                                            createlink ?
                                                                                                <a href={'/admin/course-content/'+path.course_id+'/'+item2.id} key={"course"+index2} target={"_blank"}>
                                                                                                    {item2.name}/ &nbsp;
                                                                                                </a>
                                                                                            :
                                                                                            <span key={"course"+index2}>
                                                                                                {item2.name}/ &nbsp;
                                                                                            </span>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </strong>
                                                                        </>
                                                                }
                                                            </div>
                                                    }
                                                </td>
                                                <td>
                                                    {item.total}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    
                    <div className='box-footer text-right'>
                        {
                            doubts.length > 0 && doubts.length < 10 &&
                            doubts.map((item, index) => {
                                const active = segment === index ? " btn-primary " : ""
                                return(
                                    <button className={'btn btn-flat'+active} style={{marginLeft:"10px"}} key={"navs"+index} onClick={() => setSegment(index)} >
                                        {index+1}
                                    </button>
                                )
                            })
                        }
                        {
                            doubts.length > 10 &&
                            <div style={{width:"200px", float:"right"}}>
                                <label>Last Page: {doubts.length}</label>
                                <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
  }
  return (
    <>
      <Sidebar page={Doubtspage} permission="Doubts"/>
    </>
  )
}
