import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();


export default function Assignmentslist(props) {

    const params = useParams()
    const courseindex = "course"+params.id;
    const courseData = (props.course.coursedata !== "" && props.course.coursedata !== null) ? JSON.parse(props.course.coursedata) : ""
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [tab, setTab] = useState("all")
    const [filter, setFilter] = useState({
        start: "",
        end: "",
        rating: "",
        outofmin: "",
        outofmax: "",
        obtainmin: "",
        obtainmax: "",
        userid: "",
        username: "",
        assignmentname: "",
        tab: "all",
    })

    const [infoId, setInfoid] = useState(null)
    const [infoTrail, setInfoTrail] = useState([])
    const [counts, setCounts] = useState(null)
    const [assignmentFile, setAssignmentFile] = useState("")
    const [review, setReview] = useState({
        marks: 0,
        remark: "",
        accept: "1",
    })
    const [segment, setSegment] = useState(0)

    const fetchList = () => {
        setLoading(true)
        fetch(apiurl+"api/get_submissions", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      courseid: params.id,
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      filter: filter,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setList(response.submittions)
                    setCounts(response.counts)
                    // document.body.scrollTop = document.documentElement.scrollTop = 0;
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
      }
      
    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        if(col === "tab"){
            fetchList()
        }
        
    }
      
    const changeReview = (col, value) => {
        var temp = review
        temp[col] = value
        setReview(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
    }
    const submitReview = (e, infoid, step) => {
        e.preventDefault()
        formLoading(e.target)

        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);

        formData.append('courseid', params.id);
        formData.append('review', JSON.stringify(review));
        formData.append('id', infoid.id);
        formData.append('assignmentFile', assignmentFile);
        formData.append('step', step);


        fetch(apiurl+"api/save_submission", {
            "method": "POST",
            "headers": {
                // "content-type": "multipart/form-data",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    // setInfoid(response.visit)
                    setInfoid(null)
                    fetchList()
                    successModal("Success", response.message)
                    var temp = review
                    review['marks'] = 0
                    review['remark'] = ""
                    review['accept'] = "1"
                    setReview(temp)
                    setRefresh(refresh === "No" ? "Yes" : "No")

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });



    }

    const filterFeedbacks = (e) => {
        e.preventDefault();
        fetchList()
    }
    const showAssignemntInfo = (e, item) => {
        e.preventDefault()
        setInfoid(item)
        var trail = JSON.parse(item.assignmenttrail)
        setInfoTrail(trail)
    }
    
    const checkFirstTab = () => {
        var temp = filter
        if(pagePermissions(props.pagepermissions, courseindex, "rejected_assignment_tab")){ temp['tab'] = 'fullyrejected' }
        if(pagePermissions(props.pagepermissions, courseindex, "finished_assignment_tab")){ temp['tab'] = 'finished' }
        if(pagePermissions(props.pagepermissions, courseindex, "rejected_by_teacher_tab")){ temp['tab'] = 'rejected' }
        if(pagePermissions(props.pagepermissions, courseindex, "accepted_by_teacher_tab")){ temp['tab'] = 'accepted' }
        if(pagePermissions(props.pagepermissions, courseindex, "teacher_review_tab")){ temp['tab'] = 'teacherreview' }
        if(pagePermissions(props.pagepermissions, courseindex, "all_assignment_tab")){ temp['tab'] = 'all' }
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
        fetchList()
    }


    const allowReupload = (item) => {
        warningModal("Loading...", "Allowing Re-Upload Please Wait a While.");
        fetch(apiurl+"api/allow_repload", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      courseid: params.id,
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      id: item.id,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", "Re-Upload Allowed")
                    fetchList()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
            });
    }

    const ReuploadConfirm = (item) => {
        
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => allowReupload(item)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }

    const downloadSubmission = (item, index) => {
        // console.log(infoId)
        const path = JSON.parse(infoId.path)

        successModal("Downloading...", "Please Wait File is Downloading...")
        fetch(apiurl+"api/download_submission", {
            "method": "POST",
            // "credentials": 'same-origin',
            // "referrerPolicy": 'no-referrer',
            "headers": {
                "content-type": "application/json",
                "accept": "application/pdf",
                // 'Content-Type': 'application/pdf',
                "Authorization" : "Bearer "+getToken,
            },
            "body": JSON.stringify({
                      courseid: params.id,
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                      id: infoId.id,
                      path: item.paths,
                      type: item.type,
                      index: index
                })
            })
            .then(response => response.blob())
            .then(blob => {
                
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                console.log(blob)
                // console.log(url)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    infoId.userdata.name+"_"+path[path.length-1]['name'] + `.pdf`,
                );
            
                // Append to html link element page
                document.body.appendChild(link);
            
                // Start download
                link.click();
            
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(err => {
                
            });
    }



    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["Student ID", "Student Name", "Student Email", "Student Contact", "File Name", "File Path", "Marks Obtained", "Marks Outof", "Remarks", "Submitted On", "Last Modified"]
        var segmentTemp = list
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = list[j]
            for(var i = 0;i<temp.length;i++){

                const path = JSON.parse(temp[i]['path'])
                const assignmenttrail = JSON.parse(temp[i]['assignmenttrail'])
                moment.locale('en');
                var ct = temp[i]['updated_at'];

                var pathstring = ""
                if(path !== null){
                    for(var k = 0;k<path.length;k++){
                        pathstring += path[k]['name']+"/ "
                    }
                }
                
                td.push(
                    [
                        temp[i]['userid'], temp[i]['userdata']['name'], temp[i]['userdata']['email'], temp[i]['userdata']['contact'], path[path.length-1]['name'], pathstring, temp[i]['marks'], temp[i]['outof'], 
                        (typeof assignmenttrail[assignmenttrail.length-1]['tag'] !== "undefined" ? assignmenttrail[assignmenttrail.length-1]['tag'].replace(/<[^>]*>?/gm, '') : '-'),
                        assignmenttrail[0]['datestring'], moment(ct).format("D-M-Y hh:mm:ss A")
                    ]
                )
            }
        }
        
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download assignment submissions List of ("+td.length+") Entries."
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    const clearFilter = (e) => {
        var temp = filter
        temp = {
            start: filter.start,
            end: filter.end,
            rating: "",
            outofmin: "",
            outofmax: "",
            obtainmin: "",
            obtainmax: "",
            userid: "",
            username: "",
            assignmentname: "",
            tab: filter.tab,
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }
    
    var a = 0
    useEffect(() => {
        if(a === 0){
            checkFirstTab()
            a++
        }
    }, [])

  return (
    <div className='col-md-12 no-left-padding no-right-padding'>
        {
            <>
            <div className='col-md-12'>
                <br />
                {
                    infoId === null && 
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filters
                        </h3>
                        
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                        {
                            filter.start === "" && filter.end === "" && 
                            <>
                                <br />
                                <small style={{color:"red"}}>
                                    You are currently viewing data of last 7 days.
                                </small>
                            </>
                        }
                    </div>
                    
                    
                    <form action='' method='POST' id='filterForm' onSubmit={(e) => filterFeedbacks(e)} >
                        <div style={{overflow:"hidden"}}>
                        <div className='box-body col-md-4'>
                            <label>Date of Submission (Min)</label>
                            <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                        </div>   
                        <div className='box-body col-md-4'>
                            <label>Date of Submission (Max)</label>
                            <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                        </div>   
                        <div className='box-body col-md-4'>
                            <label>Marks Outof (Min)</label>
                            <input type={"number"} className={"form-control"} value={filter.outofmin} onChange={(e) => changeValue(e.target.value, "outofmin")} placeholder={"e.g 0"} />
                        </div> 
                        <div className='box-body col-md-4'>
                            <label>Marks Outof (Max)</label>
                            <input type={"number"} className={"form-control"} value={filter.outofmax} onChange={(e) => changeValue(e.target.value, "outofmax")} placeholder={"e.g 100"} />
                        </div>   
                        <div className='box-body col-md-4'>
                            <label>Marks Obtained (Min)</label>
                            <input type={"number"} className={"form-control"} value={filter.obtainmin} onChange={(e) => changeValue(e.target.value, "obtainmin")} placeholder={"e.g 0"} />
                        </div>   
                        <div className='box-body col-md-4'>
                            <label>Marks Obtained (Max)</label>
                            <input type={"number"} className={"form-control"} value={filter.obtainmax} onChange={(e) => changeValue(e.target.value, "obtainmax")} placeholder={"e.g 100"} />
                        </div>   
                        <div className='box-body col-md-4'>
                            <label>Student ID</label>
                            <input type={"number"} className={"form-control"} value={filter.userid} onChange={(e) => changeValue(e.target.value, "userid")} placeholder={"e.g 1667198582"} />
                        </div>  
                        <div className='box-body col-md-4'>
                            <label>Student Name,Email,Contact</label>
                            <input type={"text"} className={"form-control"} value={filter.username} onChange={(e) => changeValue(e.target.value, "username")} placeholder={"e.g Shivam"} />
                        </div>  
                        <div className='box-body col-md-4'>
                            <label>Assignment Name</label>
                            <input type={"text"} className={"form-control"} value={filter.assignmentname} onChange={(e) => changeValue(e.target.value, "assignmentname")} placeholder={"e.g CA Foundation"} />
                        </div>  
                        </div>
                        <div className='box-footer'> 
                            
                            <button className='btn btn-flat btn-primary btn-block'  >
                                <i className='fa fa-search'></i> &nbsp;
                                Search
                            </button>    
                        </div>     
                    </form>
                    
                </div>
            }
            </div>
            {
                counts !== null &&
                <>
                <div className='row'>
                    <div className='col-md-12'>
                    <div className="col-lg-2 col-xs-4">
                        <div className="small-box bg-my">
                            <div className="inner">
                                <h3>
                                    {
                                        counts.waiting_for_teacher_review 
                                        + counts.accepted_by_teacher 
                                        + counts.rejected_by_teacher 
                                        + counts.finished 
                                        + counts.rejected
                                    }
                                </h3>
                                <p>All Submissions</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-history"></i>
                            </div>
                            <a href="#" className="small-box-footer"></a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-4">
                        <div className="small-box bg-my">
                            <div className="inner">
                                <h3>{counts.waiting_for_teacher_review}</h3>
                                <p>Teacher Review</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-history"></i>
                            </div>
                            <a href="#" className="small-box-footer"></a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-4">
                        <div className="small-box bg-my">
                            <div className="inner">
                                <h3>{counts.accepted_by_teacher}</h3>
                                <p>Accepted</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-history"></i>
                            </div>
                            <a href="#" className="small-box-footer"></a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-4">
                        <div className="small-box bg-my">
                            <div className="inner">
                                <h3>{counts.rejected_by_teacher}</h3>
                                <p>Rejected</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-history"></i>
                            </div>
                            <a href="#" className="small-box-footer"></a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-4">
                        <div className="small-box bg-my">
                            <div className="inner">
                                <h3>{counts.finished}</h3>
                                <p>Fully Accepted</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-history"></i>
                            </div>
                            <a href="#" className="small-box-footer"></a>
                        </div>
                    </div>
                    <div className="col-lg-2 col-xs-4">
                        <div className="small-box bg-my">
                            <div className="inner">
                                <h3>{counts.rejected}</h3>
                                <p>Fully Rejected</p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-history"></i>
                            </div>
                            <a href="#" className="small-box-footer"></a>
                        </div>
                    </div>
                    </div>
                    </div>
                </>
            }
            {
                infoId === null &&
            <div className='col-md-3'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Select Submissions Type
                        </h3>
                    </div>
                    {
                        pagePermissions(props.pagepermissions, courseindex, "all_assignment_tab")  && 
                        <div className='box-body'>
                            <button className={ (filter.tab === "all") ? 'btn btn-flat btn-block btn-primary' : 'btn btn-flat btn-block'} onClick={(e) => changeValue("all", "tab")}>
                                All Submissions
                            </button>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, courseindex, "teacher_review_tab")  && 
                        <div className='box-body'>
                            <button className={ (filter.tab === "teacherreview") ? 'btn btn-flat btn-block btn-primary' : 'btn btn-flat btn-block'} onClick={(e) => changeValue("teacherreview", "tab")}>
                                Waiting For Teacher Review
                            </button>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, courseindex, "accepted_by_teacher_tab")  && 
                        <div className='box-body'>
                            <button className={ (filter.tab === "accepted") ? 'btn btn-flat btn-block btn-primary' : 'btn btn-flat btn-block'} onClick={(e) => changeValue("accepted", "tab")}>
                                Accepted By Teacher
                            </button>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, courseindex, "rejected_by_teacher_tab")  && 
                        <div className='box-body'>
                            <button className={ (filter.tab === "rejected") ? 'btn btn-flat btn-block btn-primary' : 'btn btn-flat btn-block'} onClick={(e) => changeValue("rejected", "tab")}>
                                Rejected By Teacher
                            </button>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, courseindex, "finished_assignment_tab")  && 
                        <div className='box-body'>
                            <button className={ (filter.tab === "finished") ? 'btn btn-flat btn-block btn-primary' : 'btn btn-flat btn-block'} onClick={(e) => changeValue("finished", "tab")}>
                                Finished
                            </button>
                        </div>
                    }
                    {
                        pagePermissions(props.pagepermissions, courseindex, "rejected_assignment_tab")  && 
                        <div className='box-body'>
                            <button className={ (filter.tab === "fullyrejected") ? 'btn btn-flat btn-block btn-primary' : 'btn btn-flat btn-block'} onClick={(e) => changeValue("fullyrejected", "tab")}>
                                Rejected
                            </button>
                        </div>
                    }
                </div>
            </div>
            }
            <div className={infoId === null ? 'col-md-9' : 'col-md-4'}>
                {
                    
                    loading ? 
                    <>
                        <br /><br /><br />
                        <Loading />
                        <br /><br /><br />
                    </>
                    :
                    
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            {
                                infoId !== null &&
                                <>
                                    <button className="btn btn-flat" onClick={() => {
                                        setInfoid(null)
                                        var temp = review
                                        review['marks'] = 0
                                        review['remark'] = ""
                                        review['accept'] = "1"
                                        setReview(temp)
                                        setRefresh(refresh === "No" ? "Yes" : "No")
                                    }} >
                                        <i className='fa fa-arrow-left'></i> Back
                                    </button> &nbsp;
                                </>
                            }
                            Submission(s) ({counts !== null && counts.total})
                        </h3>
                        {
                            list.length > 0 &&
                            <>
                            
                            {!downloading ?
                            <button className='btn btn-primary btn-flat pull-right' style={{marginRight:"10px"}} onClick={() => downloadRequest()} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>}
                            </>
                        }
                    </div>
                    <div className='box-body' style={{overflow:"auto"}}>
                        <table id="contenttable" className="table file_manager_table">
                        <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Student</th>
                                    <th>File Name</th>
                                    {
                                        infoId === null &&
                                        <>
                                            <th>File Path</th>
                                            <th>Marks</th>
                                            <th>Remark</th>
                                            <th>Submitted On</th>
                                            <th>Last Modified</th>
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "allow_reupload")  && 
                                                <th className='text-center' >Action</th>
                                            }
                                        </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    typeof list[segment] !== "undefined" &&
                                    list[segment].length > 0 &&
                                    list[segment].map((item, index) => {
                                        const path = JSON.parse(item.path)
                                        const assignmenttrail = JSON.parse(item.assignmenttrail)
                                        // var step = "Waiting for teacher review";
                                        
                                        // if(item.step === 2){ step = "Accepted By Teacher"; }
                                        // if(item.step === 3){ step = "Rejected By Teacher"; }
                                        // if(item.step === 4){ step = "Finished"; }
                                        // if(item.step === 5){ step = "Rejected"; }

                                        moment.locale('en');
                                        var ct = item.updated_at;
                                        return(
                                            <tr key={"feedback"+index} >
                                                <td>{ index + 1 }</td>
                                                <td>
                                                    <Link to={"#"} target={"_BLANK"} >
                                                        {item.userdata.name}
                                                    </Link>    
                                                    <br />
                                                    {item.userdata.email}
                                                    <br />
                                                    {item.userdata.contact}

                                                </td>
                                                <td>
                                                    <Link to={"#"} target={"_BLANK"} onClick={(e) => showAssignemntInfo(e, item)} >
                                                        {
                                                            path[path.length-1]['name'] 
                                                        }
                                                    </Link>
                                                </td>
                                                {
                                                    infoId === null &&
                                                    <>
                                                        <td>
                                                            {
                                                                path.length > 0 &&
                                                                path.map((item2, index2) => {
                                                                    return(
                                                                        <Link to={"/admin/course-content/"+params.id+"/"+item2.p_id} key={"path"+item2.id+"_"+index2} target={"_BLANK"} >
                                                                            {item2.name+" / "}
                                                                        </Link>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                        <td>{item.marks}/{item.outof}</td>
                                                        
                                                        <td dangerouslySetInnerHTML={{__html: (typeof assignmenttrail[assignmenttrail.length-1]['tag'] !== "undefined" ? assignmenttrail[assignmenttrail.length-1]['tag'] : '-')}}>
                                                        </td>
                                                        <td>
                                                            {assignmenttrail[0]['datestring']}
                                                        </td>
                                                        <td>
                                                            {moment(ct).format("D-M-Y hh:mm:ss A")}
                                                        </td>
                                                        {
                                                            pagePermissions(props.pagepermissions, courseindex, "allow_reupload")  && 
                                                            <td>
                                                                {
                                                                    item.step !== 3 && item.reatt !== 1 &&
                                                                    <button className='btn btn-danger btn-flat ' onClick={(e) => ReuploadConfirm(item)}>
                                                                        Allow Re-Upload?
                                                                    </button>
                                                                }
                                                                {
                                                                    item.reatt === 1 &&
                                                                    <button className='btn btn-success btn-flat ' >
                                                                        Re-Upload Allowed
                                                                    </button>
                                                                }
                                                            </td>
                                                        }
                                                    </>
                                                }
                                            </tr>
                                            
                                            )
                                        })
                                }
                            </tbody>
                            
                        </table>
                    </div>
                    <div className='box-footer'>
                        <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                            {
                                list.length > 0 && list.length <= 10 &&
                                list.map((item, index) => {
                                    const active = index === segment ? " btn-primary" : ""
                                    return(
                                        <button className={'btn btn-flat'+active} key={"key"+index} style={{marginLeft:"5px"}} onClick={() => {
                                            setSegment(index)
                                            // document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }} >
                                            {index+1}
                                        </button>
                                    )
                                })
                            }
                            {
                                list.length > 10 &&
                                <div style={{width:"200px", float:"right"}}>
                                    <label>Last Page: {list.length}</label>
                                    <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => {
                                        setSegment(e.target.value - 1)
                                        // document.body.scrollTop = document.documentElement.scrollTop = 0;
                                        }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                }
            </div>

            <div className='col-md-8'>
            {
                infoId !== null && 
                <>
                {
                    infoId.step === 1 && pagePermissions(props.pagepermissions, courseindex, "teacher_review_tab")  && 
                        <div className='col-md-6'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Assignment Review
                                    </h3>
                                </div>
                                <form action='' method='POST' onSubmit={(e) => submitReview(e, infoId, 2)}>
                                    <div className='box-body'>
                                        <label>Assignment Status</label>
                                        <select className='form-control' defaultValue={review.accept} onChange={e => changeReview("accept", e.target.value)} >
                                            <option value={'1'}>Accept</option>
                                            <option value={'2'}>Reject</option>
                                        </select>
                                    </div>
                                    <div className='box-body'>
                                        <label>Checked Assignment File</label>
                                        <input type={"file"} required className='form-control' onChange={e => setAssignmentFile(e.target.files[0])} accept=".pdf" />
                                    </div>
                                    {
                                        review.accept === '1' &&
                                        <div className='box-body'>
                                            <label>Marks</label>
                                            <input type={'number'} step={0.01} className="form-control" placeholder='e.g 80' required value={review.marks} onChange={(e) => changeReview("marks", e.target.value)} />
                                        </div>
                                    }
                                    <div className='box-body'>
                                        <label>Remarks</label>
                                        <textarea className='form-control' placeholder='e.g Say something as a comment.' rows={5} value={review.remark} onChange={(e) => changeReview("remark", e.target.value)}></textarea>
                                    </div>
                                    <div className='box-footer'>
                                        <input type={"submit"} className={"btn btn-primary btn-flat btn-block"} value={"Submit"} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                    
                {
                    infoId.step === 2 && pagePermissions(props.pagepermissions, courseindex, "accepted_by_teacher_tab")  && 
                        <div className='col-md-6'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Accepted Assignment Review
                                    </h3>
                                </div>
                                <form action='' method='POST' onSubmit={(e) => submitReview(e, infoId, 3)}>
                                    <div className='box-body'>
                                        <label>Assignment Status</label>
                                        <select className='form-control' defaultValue={review.accept} onChange={e => changeReview("accept", e.target.value)} >
                                            <option value={'1'}>Accept</option>
                                            <option value={'2'}>Reject</option>
                                        </select>
                                    </div>
                                    <div className='box-body'>
                                        <label>Remarks</label>
                                        <textarea className='form-control' placeholder='e.g Say something as a comment.' rows={5} value={review.remark} onChange={(e) => changeReview("remark", e.target.value)}></textarea>
                                    </div>
                                    <div className='box-footer'>
                                        <input type={"submit"} className={"btn btn-primary btn-flat btn-block"} value={"Update"} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                    
                {
                    infoId.step === 4 && pagePermissions(props.pagepermissions, courseindex, "accepted_by_teacher_tab")  && 
                        <div className='col-md-6'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Rejected Assignment Review
                                    </h3>
                                </div>
                                <form action='' method='POST' onSubmit={(e) => submitReview(e, infoId, 5)}>
                                    <div className='box-body'>
                                        <label>Rejection Status</label>
                                        <select className='form-control' defaultValue={review.accept} onChange={e => changeReview("accept", e.target.value)} >
                                            <option value={'1'}>Accept</option>
                                            <option value={'2'}>Reject</option>
                                        </select>
                                    </div>
                                    <div className='box-body' >
                                        <label>Remarks</label>
                                        <textarea className='form-control' placeholder='e.g Say something as a comment.' rows={5} value={review.remark} onChange={(e) => changeReview("remark", e.target.value)}></textarea>
                                    </div>
                                    <div className='box-footer'>
                                        <input type={"submit"} className={"btn btn-primary btn-flat btn-block"} value={"Update"} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                    <div className='col-md-12'>
                        <div className='box no-border'>
                            <div className='box-header'>
                                <h3 className='box-title'>
                                    Assignment Trail
                                </h3>
                            </div>
                            <div className='box-body'>
                            <table id="contenttable" className="table file_manager_table">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Marks</th>
                                        <th>Tag</th>
                                        <th>Remark</th>
                                        <th>Date</th>
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "show_assignment_trail_staff")  && 
                                            <th>Staff</th>
                                        }
                                        <th>Download Attachment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        infoTrail !== null && infoTrail.length > 0 &&
                                        infoTrail.map((item, index) => {
                                            // console.log(item)
                                            var staff = (typeof item.staff !== "undefined") ? item.staff : null
                                            return(
                                                <tr key={"info"+index}>
                                                    <td>{index+1}</td>
                                                    <td>
                                                        {
                                                            typeof item.marks !== "undefined" && item.marks > 0 ? item.marks : '-'
                                                        }  
                                                    </td>
                                                    <td dangerouslySetInnerHTML={{__html: (typeof item.tag !== "undefined" ? item.tag : '-')}}>
                                                    </td>
                                                    <td >
                                                        {item.remarks}
                                                    </td>
                                                    <td>
                                                        {
                                                            typeof item.datestring !== "undefined" ? item.datestring : '-'
                                                        }  
                                                    </td>
                                                    {
                                                        pagePermissions(props.pagepermissions, courseindex, "show_assignment_trail_staff")  && 
                                                        <td>
                                                            {
                                                                staff !== null &&
                                                                <>
                                                                    <span> <strong>Name:</strong> {staff.name} </span><br />
                                                                    <span> <strong>Email:</strong> <a href={"mailto:"+staff.email}> {staff.email}</a> </span><br />
                                                                    <span> <strong>Contact:</strong> <a href={"tel:"+staff.contact}> {staff.contact}</a> </span><br />
                                                                    <span> <strong>Role:</strong> {staff.role} </span><br />
                                                                </> 
                                                            }
                                                        </td>
                                                    }
                                                    <td className='text-center' >
                                                        {
                                                            item.paths !== "" && item.paths !== null && item.paths.length > 0 && pagePermissions(props.pagepermissions, courseindex, "accepted_by_teacher_tab")  && index !== 0 &&
                                                                <button className='btn btn-success btn-flat' onClick={() => downloadSubmission(item, index)} >
                                                                    <i className='fa fa-download'></i>
                                                                </button>
                                                        }
                                                        {
                                                            item.paths !== "" && item.paths !== null && item.paths.length > 0 && index === 0 &&
                                                                <button className='btn btn-success btn-flat' onClick={() => downloadSubmission(item, index)} >
                                                                    <i className='fa fa-download'></i>
                                                                </button>
                                                        }

                                                    </td>
                                                </tr>
                                                
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </>
            }
            </div>
            </>
        }
    </div>
  )
}
