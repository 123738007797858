import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import apiurl from '../../Global'
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Sidebar from '../includes/Sidebar';

const cookies = new Cookies();

function printimg(files){
    const obj = URL.createObjectURL(files)
    return obj;
}

function getCourseName(arr, id){
    
    var filteredArray = arr.filter(function(item){
        return item.otherid.indexOf(id) > -1
    })
    if(filteredArray.length < 1){
        return "Root";
    }else{
        return filteredArray[0]['name'];
    }
}

const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
export default function Allfiles() {
  const Allfilespage = (props) => {

    
    
    const params = useParams()
    const {errorModal, AdminBasics, warningModal, successModal, assets_url, formLoading, pagePermissions} = CommonFunc()
    const [loading, setLoading] = useState(false)
    let getToken = cookies.get('token')
    const [courseslist, setCourseslist] = useState([])
    const [files, setFiles] = useState([])
    const [allcount, setAllcount] = useState(0)
    const [refresh, setRefresh] = useState("No")
    const [segment, setSegment] = useState(0)
    
    const [showLearnersPop, setShowLearnersPop] = useState(false)
    const [showLearnersLoading, setShowLearnersLoading] = useState(false)
    const [showLearnersTab, setShowLearnersTab] = useState("All")
    const [showLearnersList, setShowLearnersList] = useState(null)
    const [showLearnersSegment, setShowLearnersSegment] = useState(0)
    const [showFileType, setShowFileType] = useState("Live")
    const [showFileIDdata, setShowFileIDdata] = useState(null)
    const [search, setSearch] = useState("")
    

    const [filter, setFilter] = useState({
        start: "",
        end: "",
        user: "",
        modify: "",
        courseid: "",
        sortby: "updated_at",
        sort: "DESC",
        status: "Any",
        filename: "",
        filestype: params.type,
        scheduletype: "Any",
        schedulestart: "",
        scheduleend: "",
        classmin: "",
        classmax: "",
        classstatus: "",
    })
    const [filterLoading, setFilterLoading] = useState(false)


    const changeValue = (value, col) => {
        var temp = filter
        temp[col] = value
        setFilter(temp)
        setRefresh(refresh === "Yes" ? "No" : "Yes")
        // if(col !== "chatid"){
            // getGroups()
            // setFilterLoading(true)
        // }
        
    }
      
    const getLearnersList = (fileid, search = "") =>{
        fetch(apiurl+"api/getJoinedStudentsList", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                fileid: fileid,
                search: search
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setShowLearnersList(response.tabs)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setShowLearnersLoading(false)
            })
            .catch(err => {
                setShowLearnersLoading(true)
                // console.log(err);
            });
    }

      
    const getDurationStudentsList = (item, search = "") =>{
        fetch(apiurl+"api/getDurationStudentsList", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                id: item.id,
                search: search
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setShowLearnersList(response.tabs)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setShowLearnersLoading(false)
            })
            .catch(err => {
                setShowLearnersLoading(true)
                // console.log(err);
            });
    }


    const getGroups = (e) => {
        fetch(apiurl+"api/getAllFiles", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                staff: AdminBasics(),
                accecible: 1,
                page_url: window.location.href,
                filter: filter
            })
            })
            .then(response => response.json())
            .then(response => { 
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setFiles(response.list)
                    setCourseslist(response.courses)
                    setAllcount(response.allcount)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
                setFilterLoading(false)
            })
            .catch(err => {
                setLoading(false)
                setFilterLoading(false)
                // console.log(err);
            });
    }

    
    const filterFeedbacks = (e) => {
        e.preventDefault();
        getGroups()
        setFilterLoading(true)
    }


    const [downloading, setDownloading] = useState(false)
    const downloadRequest = () => {
        setDownloading(true)
        const th = ["File Name", "Path", "Created At", "Created By Name", "Created By Email", "Created By Contact", "Updated At", "Schedule Type", "Start Date", "End Date", "Days From", "Access Days", "Class Time", "Class Status"]
        
        var segmentTemp = files
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = files[j]
            for(var i = 0;i<temp.length;i++){
                
                var schedule = (temp[i]['scheduledata'] !== null && temp[i]['scheduledata'] !== "") ? JSON.parse(temp[i]['scheduledata']) : null
                var path = temp[i]['path']
                moment.locale('en');
                var ct = temp[i]['created_at'];
                var ut = temp[i]['updated_at'];
                var staff = JSON.parse(temp[i]['staff'])
                var modified = JSON.parse(temp[i]['last_modified_by'])
                var pathString = "";
                for(var k = 0;k < path.length; k++){
                    pathString += path[k]['name']+"/ "
                }

                td.push(
                    [
                        temp[i]['name'], pathString, moment(ct).format("D-M-Y HH:mm:ss"), 
                        staff.name + " ("+staff.role+")", staff.email, staff.contact,
                        moment(ut).format("D-M-Y HH:mm:ss"), schedule !== null ? schedule.type : "-", 
                        (schedule !== null && schedule.startdate !== undefined) ? moment(schedule.startdate).format("D-M-Y HH:mm:ss") : "-", 
                        (schedule !== null && schedule.enddate !== undefined) ? moment(schedule.enddate).format("D-M-Y HH:mm:ss") : "-", 
                        (schedule !== null && schedule.daysFrom !== undefined) ? schedule.daysFrom : "-", 
                        (schedule !== null && schedule.accessDays !== undefined) ? schedule.accessDays : "-", 
                        temp[i]['classtime'], temp[i]['status']
                    ]
                )
            }
        }
        console.log(td);
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download "+params.type+" List of ("+td.length+") Entries from all files page"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }


    
    const studentsDownloadRequest = () => {
        setDownloading(true)
        const th = ["Student Name", "Student Email", "Student Contact", "Student ID", "Course Name", "Joined"]
        if(showFileType !== "Live"){
            th.push("Web View")
            th.push("Android View")
            th.push("IOS View")
            th.push("Total View")
        }
        var segmentTemp = showLearnersList[showLearnersTab]
        var td = [];

        for(var j = 0; j < segmentTemp.length; j++){
            var temp = showLearnersList[showLearnersTab][j]
            for(var i = 0;i<temp.length;i++){
                if(showFileType === "Live"){
                    td.push(
                        [
                            temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['userid'], 
                            temp[i]['course_name'], temp[i]['joined'] ? "Yes" : "No"
                        ]
                    )
                }else{
                    td.push(
                        [
                            temp[i]['name'], temp[i]['email'], temp[i]['contact'], temp[i]['userid'], 
                            temp[i]['course_name'], temp[i]['joined'] ? "Yes" : "No",
                            temp[i]['web'], temp[i]['android'], temp[i]['ios'], temp[i]['total']
                        ]
                    )
                }
            }
        }
        console.log(td);
        
        fetch(apiurl+"api/downloadLists", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    th: th,
                    td: td,
                    for: "Request to download "+params.type+" List of ("+td.length+") Student Joined Class Entries from all files page"
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    const changeLearnerTab = (tabname) => {
        setShowLearnersTab(tabname)
        setShowLearnersSegment(0)
    }

    const searchList = (e) => {
        
        if(params.type !== "Live"){
            getDurationStudentsList(showFileIDdata, e.target.value)
        }
        if(params.type === "Live"){
            getLearnersList(showFileIDdata, e.target.value)
        }
        
    }
    const clearFilter = (e) => {
        var temp = filter
        temp = {
            start: filter.start,
            end: filter.end,
            user: "",
            modify: "",
            courseid: "",
            sortby: "updated_at",
            sort: "DESC",
            status: "Any",
            filename: "",
            filestype: params.type,
            scheduletype: "Any",
            schedulestart: "",
            scheduleend: "",
            classmin: "",
            classmax: "",
            classstatus: "",
        }
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }
    var a = 0
    useEffect(() => {
        if(a === 0) {
            setLoading(true)
            getGroups()
            a++
        }
    }, [])

    return(
        <>  
        {
        !showLearnersPop &&
        <>
            <div className='col-md-4'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Filter Files 
                            <br />
                            {
                                filter.start === "" && filter.end === "" &&
                                <small style={{color:"red"}} >
                                    <strong>
                                    &nbsp;&nbsp;Currently Showing Data Of 7 Days
                                    </strong>
                                </small>
                            }
                        </h3>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)}>
                            Clear Filter
                        </button>
                        <div style={{float:"right", overflow:"hidden", color:"red"}} >
                            {
                                filterLoading  &&  "Applying Filters..."
                            }
                        </div>
                    </div>
                    <form action='' method='POST' onSubmit={(e) => filterFeedbacks(e)} >
                        <div style={{overflow:"hidden"}}>
                            <div className='box-body col-md-12'>
                                <label>Select Course</label>
                                <select className='form-control' defaultValue={filter.courseid} onChange={(e) => changeValue(e.target.value, "courseid")} >
                                    <option value={""}>Any</option>
                                    {
                                        courseslist.length > 0 &&
                                        courseslist.map((item, index) => {
                                            return(
                                                <option key={"op"+index} value={item.otherid.toString()}>{item.name}</option>
                                            )
                                        })
                                    }
                                    
                                </select>
                            </div>
                      
                            <div className='box-body col-md-12'>
                                <label>File Name</label>
                                <input type={"text"} className={"form-control"} value={filter.filename} onChange={(e) => changeValue(e.target.value, "filename")} placeholder={"e.g Notes"} />
                            </div>  
                            <div className='box-body col-md-12'>
                                <label>Created By</label>
                                <input type={"text"} className={"form-control"} value={filter.user} onChange={(e) => changeValue(e.target.value, "user")} placeholder={"e.g Shivam"} />
                            </div>  
                            <div className='box-body col-md-12'>
                                <label>Last Modified By</label>
                                <input type={"text"} className={"form-control"} value={filter.modify} onChange={(e) => changeValue(e.target.value, "modify")} placeholder={"e.g Shivam"} />
                            </div>  
                            <div className='box-body col-md-6'>
                                <label>Created Date (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.start} onChange={(e) => changeValue(e.target.value, "start")} />
                            </div>   
                            <div className='box-body col-md-6'>
                                <label>Created Date (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.end} onChange={(e) => changeValue(e.target.value, "end")} />
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Sort By</label>
                                <select className='form-control' defaultValue={filter.sortby} onChange={(e) => changeValue(e.target.value, "sortby")} >
                                    <option value={"name"}>Name</option>
                                    <option value={"created_at"}>Created At</option>
                                    <option value={"updated_at"}>Updated At</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-6'>
                                <label>Order</label>
                                <select className='form-control' defaultValue={filter.sort} onChange={(e) => changeValue(e.target.value, "sort")} >
                                    <option value={"ASC"}>Ascending Order</option>
                                    <option value={"DESC"}>Descending Order</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-4'>
                                <label>Schedule Type</label>
                                <select className='form-control' defaultValue={filter.scheduletype} onChange={(e) => changeValue(e.target.value, "scheduletype")} >
                                    <option value={"Any"}>Any</option>
                                    <option value={"Fixed"}>Fixed</option>
                                    <option value={"Enrollment"}>Enrollment</option>
                                </select>
                            </div> 
                            <div className='box-body col-md-4'>
                                <label>Schedule Date (Min)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.schedulestart} onChange={(e) => changeValue(e.target.value, "schedulestart")} />
                            </div>   
                            <div className='box-body col-md-4'>
                                <label>Schedule Date (Max)</label>
                                <input type={"datetime-local"} className={"form-control"} value={filter.scheduleend} onChange={(e) => changeValue(e.target.value, "scheduleend")} />
                            </div> 
                            {
                                params.type === "Live" &&
                                <>
                                    <div className='box-body col-md-4'>
                                        <label>Class (Min)</label>
                                        <input type={"datetime-local"} className={"form-control"} value={filter.classmin} onChange={(e) => changeValue(e.target.value, "classmin")} />
                                    </div>   
                                    <div className='box-body col-md-4'>
                                        <label>Class (Max)</label>
                                        <input type={"datetime-local"} className={"form-control"} value={filter.classmax} onChange={(e) => changeValue(e.target.value, "classmax")} />
                                    </div> 
                                    <div className='box-body col-md-4'>
                                        <label>Class Status</label>
                                        <select className='form-control' value={filter.classstatus} onChange={(e) => changeValue(e.target.value, "classstatus")}>
                                            <option value={""} >Any</option>
                                            <option value={"Inactive"} >Upcomming</option>
                                            <option value={"Active"} >Ongoing</option>
                                            <option value={"Finished"} >Finished</option>
                                        </select>
                                    </div> 
                                </>
                            }
                            <div className="box-footer">
                                <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={"Filter Now"} />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='col-md-8'>
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                        { params.type } List ({allcount})
                        </h3>
                        {
                            !downloading ?
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => downloadRequest()} >
                                Download
                            </button>
                            :
                            <button className='btn btn-warning btn-flat pull-right' >
                                Preparing...
                            </button>
                        }
                    </div>
                    <div className='box-body'>
                        <table id="staff_table" className="table file_manager_table">
                            <thead>
                                <tr>
                                    <th>
                                        S.No
                                    </th>
                                    <th>
                                        Name & Path
                                    </th>
                                    <th>
                                        Created At
                                    </th>
                                    <th>
                                        Created By
                                    </th>
                                    <th>
                                        Updated At
                                    </th>
                                    <th>
                                        Last Modified By
                                    </th>
                                    <th>
                                        Schedule
                                    </th>
                                    {
                                        params.type === "Live" &&
                                        <>
                                        <th>
                                            Class Time
                                        </th>
                                        <th>
                                            Class Status
                                        </th>
                                        </>
                                    }
                                    <th>
                                        Students
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    typeof files[segment] !== "undefined" &&
                                    files[segment].length > 0 &&
                                    files[segment].map((item, index) => {
                                        var schedule = (item.scheduledata !== null && item.scheduledata !== "") ? JSON.parse(item.scheduledata) : null
                                        var path = item.path
                                        var staff = JSON.parse(item.staff)
                                        var modified = JSON.parse(item.last_modified_by)
                                        moment.locale('en');
                                        var ct = item.created_at;
                                        var ut = item.updated_at;
                                        return(
                                            <tr key={"index" + index} >
                                                <td>
                                                    {(index+1)}
                                                </td>
                                                <td>
                                                <strong>{item.name}</strong><br />
                                                    
                                                    <small>
                                                            <a href={'/admin/course-content/'+item.main_parent+'/0'} target="_blank" >
                                                                <strong>({getCourseName(courseslist, item.main_parent)}): &nbsp;&nbsp;</strong>
                                                            </a>
                                                            {
                                                                path.length > 0 &&
                                                                path.map((item2, index2) => {
                                                                    var createlink = false
                                                                    if(path.filetype === "Folder"){ 
                                                                        createlink = true 
                                                                    }else{
                                                                        if((index2+1) !== path.length){
                                                                            createlink = true 
                                                                        }
                                                                    }

                                                                    return(
                                                                        createlink ?
                                                                            <a href={'/admin/course-content/'+item.main_parent+'/'+item2.id} key={"course"+index2} target={"_blank"}>
                                                                                {item2.name}/ &nbsp;
                                                                            </a>
                                                                        :
                                                                        <span key={"course"+index2}>
                                                                            {/*  item2.name */}
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                        
                                                    </small>
                                                </td>
                                                <td>
                                                    {moment(ct).format("D-M-Y HH:mm:ss")}
                                                </td>
                                                <td>
                                                    { staff.name } (<small>{ staff.role }</small>)<br />
                                                    <strong>
                                                            <a href={ "mailto:" + staff.email } title={"Mail On " + staff.email } >
                                                            { staff.email }
                                                            </a>
                                                    </strong><br />
                                                    <strong>
                                                            <a href={ "tel:" + staff.contact } title={"Call On " + staff.contact } >
                                                            { staff.contact }
                                                            </a>
                                                    </strong><br />
                                                </td>
                                                <td>
                                                    {moment(ut).format("D-M-Y HH:mm:ss")}
                                                </td>
                                                <td>
                                                    { modified.name } (<small>{ modified.role }</small>)<br />
                                                    <strong>
                                                            <a href={ "mailto:" + modified.email } title={"Mail On " + modified.email } >
                                                            { modified.email }
                                                            </a>
                                                    </strong><br />
                                                    <strong>
                                                            <a href={ "tel:" + modified.contact } title={"Call On " + modified.contact } >
                                                            { modified.contact }
                                                            </a>
                                                    </strong><br />
                                                </td>
                                                <td>
                                                    {
                                                        schedule !== null ? 
                                                        <>
                                                            <strong>{schedule.type}</strong>
                                                            {
                                                                typeof schedule.startdate !== 'undefined' &&
                                                                <><br />Start Date: {moment(schedule.startdate).format("D-M-Y HH:mm:ss")}</>
                                                            }
                                                            {
                                                                typeof schedule.enddate !== 'undefined' &&
                                                                <><br />End Date: {moment(schedule.enddate).format("D-M-Y HH:mm:ss")}</>
                                                            }
                                                            {
                                                                typeof schedule.daysFrom !== 'undefined' &&
                                                                <><br />Days From: {schedule.daysFrom}</>
                                                            }
                                                            {
                                                                typeof schedule.accessDays !== 'undefined' &&
                                                                <><br />Access Days: {schedule.accessDays}</>
                                                            }
                                                        </>
                                                        :
                                                        "-"
                                                    }
                                                </td>
                                                {
                                                    params.type === "Live" &&
                                                    <>
                                                        <td>
                                                            {item.classtime}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.status == "Inactive" &&
                                                                <span className='badge bg-red' style={{padding:"10px"}} >
                                                                    Inactive
                                                                </span>
                                                            }
                                                            {
                                                                item.status == "Actiive" &&
                                                                <span className='badge bg-green' style={{padding:"10px"}} >
                                                                    Ongoing
                                                                </span>
                                                            }
                                                            {
                                                                item.status == "Finished" &&
                                                                <span className='badge bg-blue' style={{padding:"10px"}} >
                                                                    Finished
                                                                </span>
                                                            }
                                                        </td>
                                                    </>
                                                }
                                                <td>
                                                    {
                                                        params.type === "Live" &&
                                                            <span className='badge bg-blue' style={{padding:"10px"}} onClick={() => {
                                                                    // if(item.fileid !== undefined && item.fileid !== null){
                                                                        setShowLearnersLoading(true)
                                                                        setShowLearnersPop(true)
                                                                        setShowLearnersList(null)
                                                                        getLearnersList(item.fileid)
                                                                        setShowFileType(item.type)
                                                                        setShowFileIDdata(item.fileid)
                                                                        window.document.body.scrollTop = 0; // For Safari
                                                                        window.document.documentElement.scrollTop = 0;
                                                                    // }
                                                                }} >
                                                                {item.joinedclass}
                                                            </span>
                                                    }
                                                    {
                                                        params.type !== "Live" &&
                                                        <span className='badge bg-blue' style={{padding:"10px"}} onClick={() => {
                                                                setShowLearnersLoading(true)
                                                                setShowLearnersPop(true)
                                                                setShowLearnersList(null)
                                                                // getDurationStudentsList(item.id)
                                                                setShowFileType(item.type)
                                                                getDurationStudentsList(item)
                                                                setShowFileIDdata(item)
                                                                window.document.body.scrollTop = 0; // For Safari
                                                                window.document.documentElement.scrollTop = 0;
                                                                // }
                                                            }} >
                                                            {item.joined}
                                                        </span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='box-footer'>
                        <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                            {
                                files.length > 0 && files.length <= 10 &&
                                files.map((item, index) => {
                                    const active = index === segment ? " btn-primary" : ""
                                    return(
                                        <button className={'btn btn-flat'+active} key={"btn"+index} style={{marginLeft:"5px"}} onClick={() => setSegment(index)} >
                                            {index+1}
                                        </button>
                                    )
                                })
                            }
                            {
                                files.length > 10 &&
                                <div style={{width:"200px", float:"right"}}>
                                    <label>Last Page: {files.length}</label>
                                    <input type={"number"} className="form-control" placeholder='e.g 1' value={segment+1} onChange={(e) => setSegment(e.target.value - 1)} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </>
        }
        {
            showLearnersPop &&
            <div className='col-md-12'>
                {
                    showLearnersLoading &&
                    <div className='box no-border'>
                        <div className='box-body' style={{position:"relative"}} >
                            <br /><br /><br /><br /><br /><br /><br />
                            <Loading />
                            <br /><br /><br /><br /><br /><br /><br />
                        </div>
                    </div>
                }
                {
                    !showLearnersLoading &&
                    <div className="nav-tabs-custom">
                        <ul className="nav nav-tabs">
                            <li className={showLearnersTab === "All" ? 'active' : ''} 
                            onClick={() => changeLearnerTab("All")}
                            ><a href='#All' data-toggle="tab" aria-expanded="false">All Students</a></li>
                            <li className={showLearnersTab === "Joined" ? 'active' : ''} onClick={() => changeLearnerTab("Joined")}
                            ><a href='#Joined' data-toggle="tab" aria-expanded="false">Joined</a></li>
                            <li className={showLearnersTab === "Not_Joined" ? 'active' : ''} onClick={() => changeLearnerTab("Not_Joined")}
                            ><a href='#Not_Joined' data-toggle="tab" aria-expanded="false">Not Joined</a></li>
                            
                            <li className="pull-right" onClick={() => setShowLearnersPop(false)}><span className="badge bg-red"><i className="fa fa-times"></i></span></li>
                            

                            {
                                downloading ? 
                                <li className="pull-right" ><span className="badge bg-yellow" style={{padding:"10px"}} >Loading...</span></li>
                                :
                                <li className="pull-right" onClick={() => studentsDownloadRequest()}><span className="badge bg-blue"><i className="fa fa-download"></i></span></li>
                            }
                            <li className="pull-right" >
                                <input type={"text"} className="form-control" placeholder='e.g Shivam' onChange={(e) => searchList(e)} />
                            </li>

                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="activity">
                                {
                                    showLearnersLoading ? 
                                    <div className='box-body' style={{position:"relative"}}>
                                        <br /><br /><br /><br />
                                        <Loading />
                                        <br /><br /><br /><br />
                                    </div>    
                                    :
                                    <>
                                <div className='box-body'>
                                    <table className='table table-bordered table-hover'>
                                        <thead>
                                            <tr>
                                                <th className='text-center' style={{width:"50px"}}>S.No</th>
                                                <th>Student Name</th>
                                                <th>Student Email</th>
                                                <th>Student Contact</th>
                                                <th>Student ID</th>
                                                <th>Course Name</th>
                                                <th>Joined?</th>
                                                {
                                                    showFileType !== "Live" &&
                                                <>
                                                    <th>Web View</th>
                                                    <th>Android View</th>
                                                    <th>IOS View</th>
                                                    <th>Total View</th>
                                                </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody id='searchList'>
                                            
                                            {
                                                // searchList
                                                showLearnersList !== null &&
                                                showLearnersList[showLearnersTab][showLearnersSegment] !== undefined &&
                                                showLearnersList[showLearnersTab][showLearnersSegment].length > 0 &&
                                                showLearnersList[showLearnersTab][showLearnersSegment].map((item, index) => {
                                                    return(
                                                        <tr key={"list"+index} data-bind={item.name + " " + item.email + " " + item.contact}>
                                                            <td className='text-center' style={{width:"50px"}}>
                                                                {index+1}
                                                            </td>
                                                            <td>
                                                                {item.name}
                                                            </td>
                                                            <td>
                                                                <a href={"mailto:"+item.email} title={"Main On " + item.email}>
                                                                {item.email}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a href={"tel:"+item.contact} title={"Call On " + item.contact}>
                                                                {item.contact}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {item.userid}
                                                            </td>
                                                            <td>
                                                                {item.course_name}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.joined ?
                                                                        <span className='badge bg-green' style={{padding:"10px"}} >
                                                                            Yes
                                                                        </span>
                                                                    :
                                                                        <span className='badge bg-red' style={{padding:"10px"}} >
                                                                            No
                                                                        </span>
                                                                }
                                                            </td>
                                                            {
                                                                showFileType !== "Live" &&
                                                            <>
                                                                <td>{item.web}</td>
                                                                <td>{item.android}</td>
                                                                <td>{item.ios}</td>
                                                                <td>{item.total}</td>
                                                            </>
                                                            }
                                                        </tr>
                                                        
                                                        )
                                                    })
                                                }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='box-footer'>
                                    
                                    <div className='segMentPagination text-right' style={{width:"100%", margin:"20px 0"}} >
                                        {
                                            
                                            showLearnersList[showLearnersTab].length > 0 && showLearnersList[showLearnersTab].length <= 10 &&
                                            showLearnersList[showLearnersTab].map((item, index) => {
                                                const active = index === showLearnersSegment ? " btn-primary" : ""
                                                return(
                                                    <button key={"paginate" + index} className={'btn btn-flat'+active} style={{marginLeft:"5px"}} onClick={() => setShowLearnersSegment(index)} >
                                                        {index+1}
                                                    </button>
                                                )
                                            })
                                        }
                                        {
                                            showLearnersList[showLearnersTab].length > 10 &&
                                            <div style={{width:"200px", float:"right"}}>
                                                <label>Last Page: {showLearnersList[showLearnersTab].length}</label>
                                                <input type={"number"} className="form-control" placeholder='e.g 1' value={showLearnersSegment+1} onChange={(e) => setShowLearnersSegment(e.target.value - 1)} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                </>

                            }
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
        </>
    )
  }
  return (
    <>
      <Sidebar page={Allfilespage} permission="All_Files"/>
    </>
  )
}
