import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import Liveclassimport from '../Assets_Liabrary/Liveclassimport';
import Assignment from '../Assets_Liabrary/Assignment';

const cookies = new Cookies();

export default function Coursecontent() {

    const mainparams = useParams()
    const courseindex = "course"+mainparams.id;
    
    const[selectedFiles, setSelectedFiles] = useState( (typeof cookies.get('selectedFiles') !== "undefined" && cookies.get('selectedFiles') !== null ) ? cookies.get('selectedFiles') : []) 
    const[selectionType, setSelectionType] = useState( (typeof cookies.get('selectedType') !== "undefined" && cookies.get('selectedType') !== null ) ? cookies.get('selectedType') : []) 
    
// console.log(selectedFiles)

    const CoursecontentPage = (props) => {

        const [iframeURL, setIframeURL] = useState("")
        const params = useParams()
        const navigate = useNavigate()
        const admin = cookies.get('admin')
        let getToken = cookies.get('token')
        var a = 0;
        const [loading, setLoading] = useState(true)
        const [loadingck, setLoadingCk] = useState(false)
        const [name, setName] = useState("")
        const [editor, setEditor] = useState("")
        const [editorLoading, setEditorLoading] = useState(false)
        const[description, setDescription] = useState("")
        const [p_id, setPid] = useState((typeof params.p_id !== "undefined") ? params.p_id : 0)
        const [list, setList] = useState([])
        const [id, setId] = useState("")
        const [type, setType] = useState("")
        const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal,alertModal, assets_url} = CommonFunc()
        const[pathList, setPathList] = useState([])
        const[previewLoading, setPreviewLoading] = useState(false)
        const[preview, setPreview] = useState("")
        const[refresh, setRefresh] = useState("No")
        const[importFileType, setImportFileType] = useState("")
        const[importFilePoition, setImportFileTypePoition] = useState("")
        const[aboveBelow, setaboveBelow] = useState("")
        const [coursedata, setCourseData] = useState("")
        const [scheduleType, setScheduleType] = useState("Enrollment")
        
        const [addLiveClass, setAddLiveClass] = useState(false)
        const [addLiveClassData, setAddLiveClassData] = useState(false)

        // const [id, setId] = useState("")
        const [addAssignment, setAddAssignment] = useState(false)
        const [assignmentType, setAssignmentType] = useState("Add")
        const [assignmentId, setAssignmentId] = useState([])

        const [notifications, setNotifications] = useState({
            "course_content_updated" : {
                "title" : "",
                "link" : "",
                "body" : "",
                "app_body" : "",
                "sendto" : [],
            },
        })
        const setColNotification = (data, main, col) => {
            var temp = notifications
            temp[main][col] = data
            setNotifications(temp)
            setRefresh(refresh === "No" ? "Yes" : "No")
          }
        

        const callLiveEdit = (item) => {
            
            if(item.type === "Quiz"){
                // rename(item.quizid)
                navigate("/admin/edit-quiz/"+item.quizid)
            }else if(item.type === "Live"){
                setAddLiveClassData(item)
                setAddLiveClass(true)
            }else if(item.type === "Assignment"){

                setId(item.id)
                setAssignmentType("Edit")
                setAssignmentId(item.fileid)
                setAddAssignment(true)
                // rename(item.id, item.type, item.name, item.description)
            }else{
                rename(item)
            }
        }
        
    const addFolder = () => {
        var url = id === "" ? "add_course_folder" : "rename_content_folder"
      fetch(apiurl+"api/"+url, {
          "method": "POST",
          "headers": {
              "content-type": "application/json",
              "accept": "application/json",
              "Authorization" : "Bearer "+getToken
          },
          "body": JSON.stringify({
                    id: id,
                    type: type,
                    name: name,
                    description: description,
                    p_id: p_id,
                    aboveBelowPosition: importFilePoition,
                    aboveBelow: aboveBelow,
                    courseid: params.id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
              })
          })
          .then(response => response.json())
          .then(response => {
              if(typeof response.status !== "undefined" && response.status == "OK"){
                  successModal("Success", response.message)
                  window.$("#renameDialogBG").fadeOut()
                  fetchList()
              }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                  // alert(response.message)
                  if(typeof response.errors !== "undefined"){
                      var i = 0
                      $.each(response.errors, function(index, item){
                          setTimeout(function(){
                              errorModal("Oops Something Went Wrong", item[0])
                          }, i*1000)
                          i++
                          console.log(i)
                      });
                  }else{
                      errorModal("Oops Something Went Wrong", response.message)
                  }
              }
              window.$('#addNewCourse').fadeOut()
          })
          .catch(err => {
            window.$('#addNewCourse').fadeOut()
              // console.log(err);
          });
      }


      const fetchList = () => {
        setLoading(true)
        fetch(apiurl+"api/course_content_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      p_id: p_id,
                      courseid: params.id,
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    // successModal("Success", response.message)
                    setCourseData(response.course)
                    setList(response.list)
                    setPathList(response.path)
                    var temp = notifications
                    temp['course_content_updated'] = response.notification.data
                    setNotifications(temp)
                    setRefresh(refresh === "No" ? "Yes" : "No")
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    checkCount()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                window.$('#addNewCourse').fadeOut()
                setLoading(false)
                setTimeout(function(){
                    setLoadingCk(false)
                    if(window.$(".select2-selection").length > 0){
                        window.$("select.select2-hidden-accessible").select2('destroy');
                    }
                    setTimeout(function(){
                        
                        window.$(".select22").each(function(){
                            var id = window.$(this).attr("id")
                            window.$("#"+id).select2()
                        });
                    
                    }, 500) 
                }, 500)
            })
            .catch(err => {
              window.$('#addNewCourse').fadeOut()
              setLoading(false)
                // console.log(err);
            });
      }

      const rename = (item) => {
        if(item.type !== "Quiz" && item.type !== "Assignment"){
            setId(item.id)
            setType(item.type)
            setName(item.name)
            setDescription(item.description)
            setEditor("")
            if(item.type !== "Question" && item.type !== "Folder"){
                setEditorLoading(true)
                setTimeout(function(){
                    console.log(apiurl + 'api/ckeditor/image_upload')
                    setEditor(
                        <CKEditor
                            id={"ck-editor-text"}
                            config={{ simpleUpload: { uploadUrl: apiurl + 'api/ckeditor/image_upload', }, }} 
                            editor={ ClassicEditor }
                            data={item.description}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDescription(data)
                            } }
                        />
                    )
                    setEditorLoading(false)
                }, 500)
            }
            window.$("#renameDialogBG").fadeIn()
        }else{
            if(item.type === "Quiz"){
                navigate("/admin/edit-quiz/"+item.id)
            }else if(item.type === "Assignment"){
                setId(item.id)
                setAssignmentType("Edit")
                setAssignmentId(item.fileid)
                setAddAssignment(true)
            }
        }
      }

      const changeFolder = (item) => {
        if(item.type === "Folder"){
            navigate("/admin/course-content/"+params.id+"/"+item.id)
        }
      }
      const importPopup = (filetype) => {
        setImportFileType("")
        setImportFileType(filetype)
        
        if(filetype === "Live"){
            setIframeURL("/admin/import/5/5?importType="+filetype+"&courseid="+params.id+"&folderid="+p_id+"&aboveBelow="+aboveBelow+"&importPosition="+importFilePoition)
        }else if(filetype === "Assignment"){
            setIframeURL("/admin/import/8/8?importType="+filetype+"&courseid="+params.id+"&folderid="+p_id+"&aboveBelow="+aboveBelow+"&importPosition="+importFilePoition)
        }else{
            setIframeURL("/admin/import/2/2?importType="+filetype+"&courseid="+params.id+"&folderid="+p_id+"&aboveBelow="+aboveBelow+"&importPosition="+importFilePoition)
        }
        window.$("#importFrame").fadeIn()
      }
      
        const changePreviewTab = (tab) => {
            var prev = preview
            prev.tab = tab
            setPreview(prev)
            setRefresh(refresh == "No" ? "Yes" : "No")
        }
        
    const fileInfo = (id, type) => {
        if(type !== "Question"){
            setPreviewLoading(true)
            fetch(apiurl+"api/file_information", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        accecible: 1,
                        id: id,
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics())
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){

                        var resp = response.info;
                        if(resp.type == "Folder"){
                            resp.color = "#FCD462"
                        }else if(resp.type == "Question"){
                            resp.color = "#5691F3"
                        }else if(resp.type == "Video"){
                            resp.color = "#f46a6a"                       

                        }else if(resp.type == "Live"){
                            resp.color = "#f1b44c"
                        }else if(resp.type == "Document"){
                            resp.color = "#f46a6a"
                        }else if(resp.type == "Image"){
                            resp.color = "#50a5f1"
                        }else if(resp.type == "Youtube"){
                            resp.color = "#f46a6a"
                        }else if(resp.type == "Unknown"){
                            resp.color = "#74788d"
                        }else if(resp.type === "Live"){
                            resp.color = "#f46a6a"
                        }else if(resp.type === "Recording"){
                            resp.color = "#f46a6a"
                        }else if(resp.type === "Assignment"){
                            resp.color = "#98afba"
                        }
                        resp.tab = "English"
                        resp.id=id
                        setPreview(resp)
                        

                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    setPreviewLoading(false)
                })
                .catch(err => {
                    setPreviewLoading(false)
                });
            }else{
                navigate("/admin/questions/"+params.id+"/"+params.p_id+"?qid="+id)
            }
    }


    const callInfo = (item, permission) => {
        if(item.type === "Folder"){
            changeFolder(item)
        }
        if(permission && (item.type !== "Folder")){
            if(item.type === "Quiz"){
                window.open( "/admin/add-questions/"+item.quizid )
            }else{
                fileInfo(item.fileid, item.type)   
            }
        }
    }
    const [contextMenuItems, setContextMenuItems] = useState("")
    
    const contextMenu = (e, id, position, type, name, fileid, description) => {
        e.preventDefault()
        
        var pageX = e.pageX
        var pageY = e.pageY
        setContextMenuItems(
            { 
            "id":id ,
            "position":position,
            "type":type,
            "pageX": pageX,
            "pageY": pageY,
            "name": name,
            "fileid": fileid,
            "description" : description
            }
        )
        setRefresh(refresh == "No" ? "Yes" : "No")
        setTimeout(function(){
            $("body").unbind().on("click", function(){
                $(".myContextMenu").hide();
            });
        },500);
        if($(".myContextMenu").length > 0){
            $(".myContextMenu").show();
        }
    }

    const changeAddTab = (type, abovebelow, position) => {
        setImportFileTypePoition(position)
        setaboveBelow(abovebelow)
        

        if(type === "Folder"){
            window.$("#renameDialogBG").fadeIn()
        }else{
            
            setImportFileType(type)
            importPopup(type)
            // window.$("#importFrame").fadeIn()
        }
    }

    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_course_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    courseid:params.id,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }
    const markAll = () => {
        if($("#markAll").prop('checked')){
            $('.mark_checkbox:checkbox').prop('checked', true);
        }else{
            $('.mark_checkbox:checkbox').prop('checked', false);
        }
        checkCount()
    }
    const copyFiles = (x) => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length > 0){

            
            let d = new Date();
            d.setTime(d.getTime() + (60*60*1000*1000));
            cookies.set('selectedFiles', JSON.stringify(check), { path: '/', expires:d });
            cookies.set('selectedType', x, { path: '/', expires:d });
            setSelectedFiles(check)
            setSelectionType(x)

            successModal("Success", check.length+" selected successfully")
            // window.location.reload()
        }else{
            warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
        }
    }
    
    const copySingle = (x, type) => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });
        if(check.length === 0){
            window.$("#id"+x).find("input").prop("checked", true)
        }
        copyFiles(type)
    }

    const [pasteLoading, setPasteLoading] = useState(false)

    const pasteFiles = (type = 'unique') => {
        setPasteLoading(true)
        var pasteUrl = (selectionType === "Copy") ? "paste_course_files" : "move_course_files"
        fetch(apiurl+"api/"+pasteUrl, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    source_id: selectedFiles,
                    destination_id: p_id,
                    main_id: params.id,
                    type: type
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    for(var i = 0;i<response.response.length;i++){
                        if(response.response[i].status === "OK"){
                            successModal("Success", response.response[i].message)
                        }else{
                            errorModal("Oops Something Went Wrong", response.response[i].message)
                        }
                    }
                    setRefresh(refresh == "No" ? "Yes" : "No")

                    setSelectedFiles([])
                    setSelectionType([])
                    document.cookie = 'selectedFiles=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    document.cookie = 'selectedType=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setPasteLoading(false)
            })
            .catch(err => {
                setPasteLoading(false)
                // console.log(err);
            });
        }



        const removeFiles = (x, type) => {
            var check = []
            window.$(".mark_checkbox:checkbox:checked").each(function () {
                check.push($(this).val());
            });
            if(check.length === 0){
                window.$("#id"+x).find("input").prop("checked", true)
            }
            removeDialog()
        }
    
        const removeFilesList = (check) => {
            
            alertModal("Loading...", "Removing files this may take a while.")
    
            fetch(apiurl+"api/remove_course_files", {
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json",
                    "Authorization" : "Bearer "+getToken
                },
                "body": JSON.stringify({
                        page_url: window.location.href,
                        staff: JSON.stringify(AdminBasics()),
                        accecible: 1,
                        source_id: check,
                    })
                })
                .then(response => response.json())
                .then(response => {
                    if(typeof response.status !== "undefined" && response.status == "OK"){
                        fetchList()
                        successModal("Sucess", response.message)
                        setRefresh(refresh == "No" ? "Yes" : "No")
    
                    }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                        if(typeof response.errors !== "undefined"){
                            var i = 0
                            $.each(response.errors, function(index, item){
                                setTimeout(function(){
                                    errorModal("Oops Something Went Wrong", item[0])
                                }, i*1000)
                                i++
                                console.log(i)
                            });
                        }else{
                            errorModal("Oops Something Went Wrong", response.message)
                        }
                    }
                    
                })
                .catch(err => {
                    // console.log(err);
                });
    
        }
    
        const removeDialog = () => {
            var check = []
            window.$(".mark_checkbox:checkbox:checked").each(function () {
                check.push($(this).val());
            });
            if(check.length > 0){
                
                confirmAlert({
                    title: 'Are You Sure?',
                    message: "You want to remove this file?",
                    buttons: [
                      {
                        label: 'Yes',
                        onClick: () => removeFilesList(check)
                      },
                      {
                        label: 'No',
                        onClick: () => console.log("Canceled")
                      }
                    ]
                });
    
            }else{
                warningModal("Oops!!!", "You Haven't Selected Any Items Yet.")
            }
        }

    const changeDownloadPermission = (item, type) => {
        
        fetch(apiurl+"api/donwloadPermission", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    id:item.id,
                    type: type
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    fetchList()
                    successModal("Sucess", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => { });
    }

    const changeSchedulingStatus = (id, value) => {
        
        fetch(apiurl+"api/change_scheduling_status", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    id: id,
                    value: value,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                window.location.reload()
            })
            .catch(err => { });
    }
    const courseBasics = () => {
        setLoading(true)
        setLoadingCk(true)
        fetchList()
        // fetch(apiurl+"api/course_basics", {
        //     "method": "POST",
        //     "headers": {
        //         "content-type": "application/json",
        //         "accept": "application/json",
        //         "Authorization" : "Bearer "+getToken
        //     },
        //     "body": JSON.stringify({
        //             courseid: params.id,
        //             staff: AdminBasics(),
        //             accecible: 1,
        //             page_url: window.location.href,
        //         })
        //     })
        //     .then(response => response.json())
        //     .then(response => {
        //         if(typeof response.status !== "undefined" && response.status === "OK"){
        //             setCourseData(response.course)
        //             fetchList()
        //         }else if(typeof response.message !== "undefined" && response.status !== "OK"){
        //             // alert(response.message)
        //             if(typeof response.errors !== "undefined"){
        //                 var i = 0
        //                 $.each(response.errors, function(index, item){
        //                     setTimeout(function(){
        //                         errorModal("Oops Something Went Wrong", item[0])
        //                     }, i*1000)
        //                     i++
        //                     console.log(i)
        //                 });
        //             }else{
        //                 errorModal("Oops Something Went Wrong", response.message)
        //             }
        //         }
        //         // setLoading(false)
        //     })
        //     .catch(err => {
                
        //         // console.log(err);
        //     });
    }

    const [daysFromLearner, setDaysFromLearner] = useState(0)
    const [accessDays, setAccessDays] = useState('')
    
    const [startdate, setStartDate] = useState('')
    const [enddate, setEndDate] = useState('')

    const [scheduleId, setScheduleId] = useState('')

    
    const scheduleData = (e) => {
        e.preventDefault()
        formLoading(e.target)
        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('type', scheduleType);
        formData.append('id', scheduleId);
        formData.append('courseid', params.id);

        if(scheduleType === "Enrollment"){
            formData.append('daysfromleraner', daysFromLearner);
            formData.append('accessDays', accessDays);
        }

        if(scheduleType === "Fixed"){
            formData.append('startdate', startdate);
            formData.append('enddate', enddate);
        }
        

        fetch(apiurl+"api/schedule_items", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").fadeOut()
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    fetchList()
                    window.$("#schedulePopup").fadeOut()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => { });

    }

    const clearSchedule = (e) => {
        
        formLoading(window.$(e.target).parents("form"))
        
        const formData = new FormData();
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('id', scheduleId);
        formData.append('courseid', params.id);

        fetch(apiurl+"api/unschedule_items", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                window.$(e.target).parents("form").find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    fetchList()
                    window.$("#schedulePopup").fadeOut()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => { });
    }

    const scheduleItem = (item) => {
        setScheduleId(item.id)
        if(item.scheduledata !== null){
            var schedule = JSON.parse(item.scheduledata)
            setScheduleType(schedule.type)
            if(schedule.type === "Enrollment"){
                setDaysFromLearner(schedule.daysFrom)
                setAccessDays(schedule.accessDays)
            }else if(schedule.type === "Fixed"){
                setStartDate(schedule.startdate)
                setEndDate(schedule.enddate)
            }
        }else{
            setDaysFromLearner(0)
            setAccessDays('')
            setStartDate('')
            setEndDate('')
        }
        $("#schedulePopup").fadeIn();
    }
    const sendContentNotification = (e) => {
        e.preventDefault()
        formLoading(e.target)
        notifications['course_content_updated']['sendto'] = window.$("#course_content_updated").val()
        fetch(apiurl+"api/sendContentNotification", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    course_id: params.id,
                    notification: notifications['course_content_updated'],
                    col: "course_content_updated",
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").fadeOut()
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                
            });
    }
    
    const downloadall = () => {

        fetch(apiurl+"api/downloadall", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    courseid: params.id,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                successModal("Success", response.message)
                fetchList()
            })
    }

    const launchClass = (item) => {
        warningModal("Loading...", "Launching Class Please Wait...");
        fetch(apiurl+"api/launchliveclass", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    classid: item.id,
                    staff: AdminBasics(),
                    accecible: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    window.open(response.class_url)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                
            });
    }

    const checkCount = () => {
        var check = []
        window.$(".mark_checkbox:checkbox:checked").each(function () {
            check.push($(this).val());
        });

        window.$("#error_success").html('')
        if(check.length > 0){
            alertModal("Files Selection", "You have selected ("+check.length+") Items.")
        }
    }

      var a = 0
      useEffect(() => {
        if(a === 0){
            courseBasics()
            a++
        }
      }, [])

        return(
          <>

    {pagePermissions(props.pagepermissions, courseindex, "course_content") ?
          <>
        {
            contextMenuItems !== "" &&
                <div className='myContextMenu' style={{left:contextMenuItems.pageX, top:contextMenuItems.pageY}}>
                    <ul>
                        {
                            // pagePermissions(props.pagepermissions, "Assets", "show_create_files_button") &&
                            <>
                                <li>
                                {/* importPopup("Image", "") */}
                                    <div className='contextNameCon'>
                                        <FeatherIcon icon={"arrow-up"} />
                                        Add Above
                                    </div>
                                    <FeatherIcon icon={"chevron-right"} />
                                    <div className='contextMenu2'>
                                        <ul>
                                            
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_folder") &&
                                                <li onClick={() => changeAddTab("Folder", "Above", contextMenuItems.position)} title="Add New Folder">
                                                    <div className='contextNameCon'>
                                                        <FeatherIcon icon={"folder-plus"} />
                                                        New Folder
                                                    </div>
                                                </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_document") &&
                                            <li onClick={() => changeAddTab("Document", "Above", contextMenuItems.position)} title="Upload Document">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"file-text"} />
                                                    Upload Document
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_video") &&
                                            <li onClick={() => changeAddTab("Video,Youtube", "Above", contextMenuItems.position)} title="Upload Video">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"video"} />
                                                    Upload Video
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_image") &&
                                            <li onClick={() => changeAddTab("Image", "Above", contextMenuItems.position)} title="Upload Image">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"image"} />
                                                    Upload Image
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_assignment") &&
                                            <li onClick={() => changeAddTab("Assignment", "Above", contextMenuItems.position)} title="Upload Assignment">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"file"} />
                                                    Upload Assignment
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_quiz") &&
                                            <li onClick={() => changeAddTab("Quiz", "Above", contextMenuItems.position)} title="Upload Quiz">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"check-circle"} />
                                                    Upload Quiz
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_live_classes") &&
                                            <li onClick={() => changeAddTab("Live", "Above", contextMenuItems.position)} title="Upload Live Class">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"video"} />
                                                    Upload Live Classes
                                                </div>
                                            </li>
                                            }
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className='contextNameCon'>
                                        <FeatherIcon icon={"arrow-down"} />
                                        Add Below
                                    </div>
                                    <FeatherIcon icon={"chevron-right"} />
                                    <div className='contextMenu2'>
                                        <ul>
                                            
                                        {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_folder") &&
                                                <li onClick={() => changeAddTab("Folder", "Below", contextMenuItems.position)} title="Add New Folder">
                                                    <div className='contextNameCon'>
                                                        <FeatherIcon icon={"folder-plus"} />
                                                        New Folder
                                                    </div>
                                                </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_document") &&
                                            <li onClick={() => changeAddTab("Document", "Below", contextMenuItems.position)} title="Upload Document">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"file-text"} />
                                                    Upload Document
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_video") &&
                                            <li onClick={() => changeAddTab("Video,Youtube", "Below", contextMenuItems.position)} title="Upload Video">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"video"} />
                                                    Upload Video
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_image") &&
                                            <li onClick={() => changeAddTab("Image", "Below", contextMenuItems.position)} title="Upload Image">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"image"} />
                                                    Upload Image
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_assignment") &&
                                            <li onClick={() => changeAddTab("Assignment", "Below", contextMenuItems.position)} title="Upload Assignment">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"file"} />
                                                    Upload Assignment
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_quiz") &&
                                            <li onClick={() => changeAddTab("Quiz", "Below", contextMenuItems.position)} title="Upload Quiz">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"check-circle"} />
                                                    Upload Quiz
                                                </div>
                                            </li>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "add_course_live_classes") &&
                                            <li onClick={() => changeAddTab("Live", "Below", contextMenuItems.position)} title="Upload Live Class">
                                                <div className='contextNameCon'>
                                                    <FeatherIcon icon={"video"} />
                                                    Upload Live Classes
                                                </div>
                                            </li>
                                            }
                                        </ul>
                                    </div>
                                </li>
                            </>
                        }
                        
                            <>
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                    <li  onClick={() => copySingle(contextMenuItems.id, "Copy")}>
                                        <div className='contextNameCon' title='Copy' >
                                            <FeatherIcon icon={"copy"} />
                                            Copy
                                        </div>
                                    </li>
                                }
                                
                                {
                                    pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                    <li onClick={() => copySingle(contextMenuItems.id, "Cut")}>
                                        <div className='contextNameCon' title='Move' >
                                            <FeatherIcon icon={"scissors"} />
                                            Cut
                                        </div>
                                    </li>
                                }
                            </>
                        {
                            selectedFiles.length > 0 &&
                            <li>
                                <div className='contextNameCon' title='Paste'  onClick={() => pasteFiles('unique')} >
                                    <FeatherIcon icon={"clipboard"} />
                                    Paste
                                </div>
                            </li>
                        }
                        
                            {
                                pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                <li>
                                    <div className='contextNameCon' title='View Information' onClick={() => fileInfo(contextMenuItems.fileid, contextMenuItems.type)}>
                                        <FeatherIcon icon={"info"} />
                                        View Details
                                    </div>
                                </li>
                            }
                            {
                                pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                    <li>
                                        <div className='contextNameCon' onClick={() =>  rename(contextMenuItems) }>
                                            <FeatherIcon icon={"edit"} />
                                            Rename {contextMenuItems.type}
                                        </div>
                                    </li>
                                }
                            {
                                pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                <li>
                                    <div className='contextNameCon' onClick={() => removeFiles(contextMenuItems.id)}>
                                        <FeatherIcon icon={"trash"} />
                                        Remove {contextMenuItems.type}
                                    </div>
                                </li>
                            }

                    </ul>
                </div>
            }

            <div className='col-md-12 no-left-padding no-right-padding' style={{marginTop:"20px"}}>
                
                <div className='col-md-4'>
                    
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                {coursedata.name}
                            </h3>
                        </div>
                    </div>
                    
                    {
                        (pagePermissions(props.pagepermissions, courseindex, "add_course_folder") ||
                        pagePermissions(props.pagepermissions, courseindex, "add_course_image") ||
                        pagePermissions(props.pagepermissions, courseindex, "add_course_video") ||
                        pagePermissions(props.pagepermissions, courseindex, "add_course_document") ||
                        pagePermissions(props.pagepermissions, courseindex, "add_course_quiz") ||
                        pagePermissions(props.pagepermissions, courseindex, "add_course_assignment") ||
                        pagePermissions(props.pagepermissions, courseindex, "add_course_live_classes") ) &&
                            <div className='box no-border'>
                                <div className='box-body'>
                                    <ul className='assets_list'>
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "add_course_folder") &&
                                            <li 
                                            onClick={() => {
                                                setId("")
                                                setType("")
                                                setaboveBelow("")
                                                setImportFileTypePoition("")
                                                window.$("#renameDialogBG").fadeIn()
                                            }}>
                                                <span>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/folder.png'} />
                                                    Create Folder
                                                </span>
                                                <span className='arrow-r'>
                                                    <FeatherIcon icon={"plus"} />
                                                </span>
                                            </li>
                                        }
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "add_course_image") &&
                                            <li onClick={() => importPopup("Image", "")}>
                                                <span>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/photo.png'} />
                                                    Add Image
                                                </span>
                                                <span className='arrow-r'>
                                                    <FeatherIcon icon={"plus"} />
                                                </span>
                                            </li>
                                        }
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "add_course_video") &&
                                            <li onClick={() => importPopup("Video,Youtube", "")}>
                                                <span>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/play-button.png'} />
                                                    Add Video
                                                </span>
                                                <span className='arrow-r'>
                                                    <FeatherIcon icon={"plus"} />
                                                </span>
                                            </li>
                                        }
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "add_course_document") &&
                                            <li onClick={() => importPopup("Document", "")}>
                                                <span>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/pdf.png'} />
                                                    Add Notes
                                                </span>
                                                <span className='arrow-r'>
                                                    <FeatherIcon icon={"plus"} />
                                                </span>
                                            </li>
                                        }
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "add_course_quiz") &&
                                            <li onClick={() => navigate("/admin/create-quiz/"+params.id+"/"+p_id)}>
                                                <span>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/answer.png'} />
                                                    Create Quiz
                                                </span>
                                                <span className='arrow-r'>
                                                    <FeatherIcon icon={"plus"} />
                                                </span>
                                            </li>
                                        }
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "add_course_live_classes") &&
                                            <li onClick={() => importPopup("Live", "")}>
                                                <span>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/voice.png'} />
                                                    Add Live Classes
                                                </span>
                                                <span className='arrow-r'>
                                                    <FeatherIcon icon={"plus"} />
                                                </span>
                                            </li>
                                        }
                                        {
                                            pagePermissions(props.pagepermissions, courseindex, "add_course_assignment") &&
                                            <li onClick={() => importPopup("Assignment", "")}>
                                                <span>
                                                    <img src={process.env.PUBLIC_URL+'/assets/assets/images/list.png'} />
                                                    Add Assignment
                                                </span>
                                                <span className='arrow-r'>
                                                    <FeatherIcon icon={"plus"} />
                                                </span>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                    }
                    
                    {
                        typeof coursedata.name !== "undefined" &&
                        <>
                        {
                            
                            pagePermissions(props.pagepermissions, courseindex, "all_content_schedule_button") &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Content Scheduling
                                    </h3>
                                    <span className='pull-right'>
                                        <label className="switch">
                                            <input type="checkbox" defaultChecked={(coursedata.scheduling === 1) ? true : false} onChange={(e) => changeSchedulingStatus(params.id, window.$(e.target).prop('checked'))} />
                                            <span className="slider round"></span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                        }
                        {
                            
                            pagePermissions(props.pagepermissions, courseindex, "download_all_button") &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Download All Files
                                    </h3>
                                    <span className='pull-right'>
                                        <button className='btn btn-primary btn-flat' onClick={() => downloadall()} >
                                            {coursedata.alldownload === null ? "Allow?" : "Disallow?"}
                                        </button>
                                    </span>
                                </div>
                            </div>
                        }
                        {
                            
                            pagePermissions(props.pagepermissions, courseindex, "send_notification_box") &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title' style={{textTransform:"capitalize"}} >
                                        Send Notification
                                    </h3>
                                    <span className='pull-right' >
                                        {"{{userid}}, {{username}}, {{usercontact}}, {{useremail}}"}
                                    </span>
                                </div>
                                {
                                    loadingck ? 
                                    <div className='box-body' style={{position:"relative"}} >
                                        <br /><br />
                                            <Loading />
                                        <br /><br /><br /><br />
                                    </div>
                                    :
                                    <>
                                    <form action='' method='POST' onSubmit={(e) => sendContentNotification(e)} >
                                        <div className='box-body' style={{position:"relative"}} >
                                            <div className='col-md-6 no-left-padding'>
                                                <label>Notification Title<span className='text-red' >*</span></label>
                                                <input type={"text"} className="form-control" required value={notifications['course_content_updated']["title"]} onChange={(e) => setColNotification(e.target.value, 'course_content_updated', "title") } placeholder={"Enter Your Notification Title For : " + 'course_content_updated'.replace(/_/g, " ")} />
                                            </div>
                                            <div className='col-md-6 no-right-padding'>
                                                <label>URL (If Any)</label>
                                                <input type={"url"} className="form-control" value={(notifications['course_content_updated']["link"] === null) ? "" : notifications['course_content_updated']["link"]} onChange={(e) => setColNotification(e.target.value, 'course_content_updated', "link") } placeholder={"Enter Your Notification Link For : " + 'course_content_updated'.replace(/_/g, " ")} />
                                            </div>
                                        </div>
                                        <div className='box-body' style={{position:"relative"}} >
                                        <label>Notification Body<span className='text-red' >*</span></label>
                                            <CKEditor 
                                            editor={ ClassicEditor } 
                                            
                                            config={
                                                { 
                                                simpleUpload: { 
                                                    uploadUrl: apiurl + 'api/ckeditor/image_upload' 
                                                }, 
                                                }

                                            } 
                                            data={notifications['course_content_updated']["body"]} onChange={ ( event, editor ) => { const data = editor.getData(); setColNotification(data, 'course_content_updated', "body") } } />
                                        </div>
                                        <div className='box-body' style={{position:"relative"}} >
                                            <label>Notification Body (APP)<span className='text-red' >*</span></label>
                                            <textarea className="form-control" required onChange={(e) => setColNotification(e.target.value, 'course_content_updated', "app_body") } placeholder={"Enter Your Notification App Body For : " + 'course_content_updated'.replace(/_/g, " ")} defaultValue={notifications['course_content_updated']["app_body"]}></textarea>
                                            
                                        </div>
                                        
                                        <div className='box-body'>
                                            <label>Send On</label>
                                            <select className='form-control select22' multiple id={'course_content_updated'} value={notifications['course_content_updated']["sendto"]} onChange={(e) => setColNotification(e.target.value, 'course_content_updated', "sendto") } data-placeholder={"Select Notification Send Via for : " + 'course_content_updated'.replace(/_/g, " ")}  >
                                                <option value={"Web"}>Web Push</option>
                                                <option value={"App"}>App Push</option>
                                                <option value={"In App"}>In App</option>
                                                <option value={"Whatsapp"}>Whatsapp</option>
                                            </select>
                                        </div>

                                        <div className='box-footer'>
                                            <button className='btn btn-primary btn-flat btn-block' style={{textTransform:"capitalize"}} >
                                                Send Notification
                                            </button>
                                        </div>
                                    </form>
                                    </>
                                }
                            </div>
                        }
                        </>
                        
                    }

                </div>
                <div className='col-md-8'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Course Content
                            </h3>
                                    {
                                        <div className="input-group pull-right btn btn-success btn-flat" onClick={() => window.location.reload()}>
                                            <i className='fa fa-refresh'></i>
                                        </div>
                                    }
                                {
                                    // pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                <>
                                    {
                                        pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                        <div className="input-group pull-right btn btn-danger btn-flat" onClick={() => removeDialog()}>
                                            Delete
                                        </div>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                        <div className="input-group pull-right btn btn-flat" id='cutBtn' onClick={() => copyFiles("Cut")}>
                                            Cut
                                        </div>
                                    }
                                    {
                                        pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                        <div className="input-group pull-right btn btn-flat" id='copyBtn' onClick={() => copyFiles("Copy")}>
                                            Copy
                                        </div>
                                    }
                                    {
                                        selectedFiles.length > 0 && !pasteLoading &&
                                        <div className="input-group btn-primary pull-right btn btn-flat" onClick={() => pasteFiles('clone')}>
                                            Paste as Clone
                                        </div>
                                    }
                                    {
                                        selectedFiles.length > 0 && !pasteLoading &&
                                        <div className="input-group btn-primary pull-right btn btn-flat" onClick={() => pasteFiles('unique')}>
                                            Paste AS Unique
                                        </div>
                                    }
                                    {
                                        pasteLoading &&
                                        <div className="input-group btn-warning pull-right btn btn-flat" >
                                            Loading...
                                        </div>
                                    }
                                </>
                            }
                        </div>

                        {
                            !loading && pathList.length > 0 &&
                            <div className='box-body breadCrums'>
                                <ul>
                                { 
                                    <li onClick={() => navigate("/admin/course-content/"+params.id+"/0")}>
                                        Root <FeatherIcon icon={"chevron-right"}/>
                                    </li>
                                }
                                {
                                    pathList.map((item, index) => (
                                        <li title={item.name} onClick={(e) => changeFolder(item)} key={"path"+index}>
                                            {item.name}
                                            <FeatherIcon icon={"chevron-right"} />
                                        </li>    
                                    ))
                                }
                                </ul>    
                            </div>
                        }
                        {
                            !loading ?
                            <div className='box-body'>
                                <table id="contenttable" className="table file_manager_table">
                                    <thead>
                                        <tr>
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "course_drag_all") &&
                                                <th className='text-center' style={{width:"50px"}}>
                                                    
                                                </th>
                                            }
                                            {
                                                pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                                <th className='text-center' style={{width:"50px"}}>
                                                    <input type={"checkbox"} id={"markAll"}  onChange={() => markAll()}  />
                                                </th>
                                            }
                                            <th>
                                                Name
                                            </th>
                                            <th className='text-center'>
                                                Created On
                                            </th>
                                            <th className='text-center'>
                                                Last Modified
                                            </th>
                                            <th className='text-right'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='connected-sortable droppable-area1' onLoad={() => callSorting()}>
                                        {
                                            list.length > 0 &&
                                            list.map((item, index) => {

                                                let img = "folder"
                                                let permission_name = "folder"
                                                

                                                if(item.type == "Document"){
                                                    img = "pdf"
                                                    permission_name = "document"
                                                }else if(item.type == "Question"){
                                                    img = "answer"
                                                    permission_name = "quiz"
                                                }else if(item.type == "Quiz"){
                                                    img = "answer"
                                                    permission_name = "quiz"
                                                }else if(item.type == "Video"){
                                                    img = "play-button"
                                                    permission_name = "video"
                                                }else if(item.type == "Recording"){
                                                    img = "live"
                                                    permission_name = "video"
                                                }else if(item.type == "Image"){
                                                    img = "photo"
                                                    permission_name = "image"
                                                }else if(item.type == "Youtube"){
                                                    img = "youtube"
                                                    permission_name = "video"
                                                }else if(item.type == "Live"){
                                                    img = "voice"
                                                    permission_name = "live_classes"
                                                }else if(item.type == "Assignment"){
                                                    img = "list"
                                                    permission_name = "assignment"
                                                }
                                                
                                                moment.locale('en');
                                                var dt = item.updated_at;
                                                var ct = item.created_at;
                                                

                                                // console.log(pagePermissions(props.pagepermissions, courseindex, "view_course_"+permission_name))

                                                return (

                                                    pagePermissions(props.pagepermissions, courseindex, "view_course_"+permission_name) &&

                                                    <tr 
                                                    key={"data"+index}
                                                    id={"id"+item.id}
                                                    onContextMenu={(e) => contextMenu(e, (item.type === "Quiz") ? item.quizid : item.id, item.position, item.type, item.name, item.fileid, item.description)}
                                                    style={{opacity: ( selectionType === "Cut" && selectedFiles.includes(item.id.toString())) ? 0.7 : 1 }}
                                                    >
                                                    {
                                                        pagePermissions(props.pagepermissions, courseindex, "course_drag_all") &&
                                                        <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                            <i className='fa fa-arrows-v'></i>
                                                        </td>
                                                    }
                                                        
                                                    {
                                                        pagePermissions(props.pagepermissions, courseindex, "course_mark_all") &&
                                                        <td className='text-center' style={{width:"50px"}}>
                                                            {
                                                                item.prime === null &&
                                                                <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.id} onChange={() => checkCount()} />
                                                            }
                                                        </td>
                                                    }
                                                        <td className='file_name_cell' >
                                                            <div className='fileTitleList' onClick={() => callInfo(item, pagePermissions(props.pagepermissions, courseindex, "info_course_" + permission_name))}>
                                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                                {item.name}
                                                                {
                                                                    item.type === "Quiz" && " ("+item.quizid+")"
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>
                                                            {moment(ct).format("D-M-Y HH:mm:ss")}
                                                        </td>
                                                        <td className='text-center'>
                                                            {moment(dt).format("D-M-Y HH:mm:ss")}
                                                        </td>
                                                        <td className='text-right'>
                                                            <div className='actionDiv'>
                                                                {
                                                                    item.type === "Document" && pagePermissions(props.pagepermissions, courseindex, "download_course_"+permission_name) &&
                                                                    <div 
                                                                    className={(item.watermark === 1) ? 'pinnedoff nodownload' : 'pinnedoff'} 
                                                                    title={(item.watermark === 1) ? 'Remove Watermark for download?' : 'Allow watermark for download?' } 
                                                                    onClick={(e) => changeDownloadPermission(item, 'watermark')}
                                                                    >
                                                                        {
                                                                            (item.watermark === 1) &&
                                                                            <span></span>
                                                                        }
                                                                        <FeatherIcon icon={"file-minus"} />
                                                                    </div>
                                                                }
                                                                {
                                                                    item.type === "Document" && pagePermissions(props.pagepermissions, courseindex, "download_course_"+permission_name) &&
                                                                    <div 
                                                                    className={(item.download === 1) ? 'pinnedoff nodownload' : 'pinnedoff'} 
                                                                    title={(item.download === 1) ? 'Remove Download Permission?' : 'Allow for download?' } 
                                                                    onClick={(e) => changeDownloadPermission(item, 'download')}
                                                                    >
                                                                        {
                                                                            (item.download === 1) &&
                                                                            <span></span>
                                                                        }
                                                                        <FeatherIcon icon={"download"} />
                                                                    </div>
                                                                }

                                                                {
                                                                    item.type !== "Folder" && 
                                                                    item.type !== "Quiz" && 
                                                                    item.type !== "Live" &&
                                                                    pagePermissions(props.pagepermissions, courseindex, "info_course_"+permission_name) &&
                                                                    <div className='pinnedoff' title='View Information' onClick={() => fileInfo(item.fileid, item.type)}>
                                                                        <FeatherIcon icon={"info"} />
                                                                    </div>
                                                                }
                                                                {
                                                                    item.type === "Quiz" &&
                                                                    pagePermissions(props.pagepermissions, courseindex, "add_course_"+permission_name) &&
                                                                    <div className='pinnedoff' title='Add Question' onClick={() => window.open( "/admin/add-questions/"+item.quizid )}>
                                                                        <FeatherIcon icon={"plus"} />
                                                                    </div>
                                                                }
                                                                {
                                                                    item.prime === null &&
                                                                    <>
                                                                        {
                                                                            coursedata.scheduling === 1 && item.type !== "Live" && item.type !== "Assignment" &&
                                                                            pagePermissions(props.pagepermissions, courseindex, "schedule_course_"+permission_name) &&
                                                                            <div className='pinnedoff' title='View Information' onClick={() => scheduleItem(item)}>
                                                                                {
                                                                                    item.scheduledata !== null &&
                                                                                    <span className='dotmark'></span>
                                                                                }
                                                                                <FeatherIcon icon={"calendar"} />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            pagePermissions(props.pagepermissions, courseindex, "edit_course_"+permission_name) &&
                                                                            <div className='pinnedoff' title={'Rename '+item.type} onClick={(e) => callLiveEdit(item) }>
                                                                                <FeatherIcon icon={"edit"} />
                                                                            </div>
                                                                        }
                                                                        {
                                                                            pagePermissions(props.pagepermissions, courseindex, "delete_course_"+permission_name) &&
                                                                            <div className='pinnedoff' title={'Remove '+item.type} onClick={() => removeFiles(item.id)} >
                                                                                <FeatherIcon icon={"trash"} />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                
                                {
                                    !loading && list.length < 1 && 
                                    <div className='no-files'>
                                        <img src={process.env.PUBLIC_URL+'/assets/assets/images/nofiles.png'} />
                                        <p className='no-files-heading'>
                                            <strong>Oops!</strong>
                                        </p>
                                        <p className='no-files-msg'>
                                            You Haven't Added Any Item Yet.
                                        </p>


                                    </div>
                                }
                            </div>
                            :
                            <>
                                <br /><br /><br />
                                <Loading />
                                <br /><br /><br />
                            </>
                        }
                    </div>
                </div>
            </div>
        

            <div className='renameDialogBG' id="schedulePopup" style={{display:"none"}} >
                <div className='renameBody'>
                    <div className='renameHeading'>
                        <p className='text-left'>
                            Schedule Item
                            <span className='pull-right cursor-pointer' onClick={() => window.$("#schedulePopup").fadeOut()} >
                                <i className='fa fa-times'></i>
                            </span>
                        </p>
                    </div>
                    
                    <div className='renameInputCon scheduleTabButtons'>
                        <button className={(scheduleType !== "Enrollment") ? 'btn btn-block' : 'btn btn-block btn-primary'} onClick={() => setScheduleType("Enrollment")} >
                            Enrollment Based
                        </button>
                        <button className={(scheduleType !== "Fixed") ? 'btn btn-block' : 'btn btn-block btn-primary'} onClick={() => setScheduleType("Fixed")} >
                            Fixed Date
                        </button>
                    </div>

                    {
                        scheduleType === "Enrollment" &&
                        <form action='' method='POST' onSubmit={(e) => scheduleData(e)}>
                            <div className='renameInputCon'>
                                <br />
                                <label>Number of Days from Learner Enrollment</label>
                                <input type={"number"} className={"form-control"} placeholder={"e.g 1"} required value={daysFromLearner}  onChange={(e) => setDaysFromLearner(e.target.value)} />
                            </div>
                            <div className='renameInputCon'>
                                <br />
                                <label>Access for Number of days (keep it blank for no limit)</label>
                                <input type={"number"} className={"form-control"} placeholder={"e.g 15"} value={accessDays}  onChange={(e) => setAccessDays(e.target.value)} />
                            </div>
                            <div className='renameFooter'>
                                <br />
                                <div style={{display:"flex", flexDirection:"row"}} >

                                    {
                                        (daysFromLearner !== 0 || accessDays !== '') &&
                                            <button className='btn btn-flat btn-block pull-right' type='button' style={{marginTop:"10px",marginRight:5}} onClick={(e) => clearSchedule(e)} >
                                                Clean Schedule
                                            </button>
                                    }

                                    <button className='btn btn-primary btn-flat btn-block pull-right' style={{marginTop:"10px"}} >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    }
                    {
                        scheduleType === "Fixed" &&
                        <form action='' method='POST' onSubmit={(e) => scheduleData(e)}>
                            <div className='renameInputCon'>
                                <br />
                                
                                <label>Start Date & Time</label>
                                <input type={"datetime-local"} className={"form-control"} placeholder={"e.g 1"} required value={startdate}  onChange={(e) => setStartDate(e.target.value)} />
                            </div>
                            <div className='renameInputCon'>
                                <br />
                                <label>End Date & Time</label>
                                <input type={"datetime-local"} className={"form-control"} placeholder={"e.g 15"} required value={enddate} min={startdate}  onChange={(e) => setEndDate(e.target.value)} />
                            </div>
                            <br />
                            <div className='renameFooter' style={{display:"flex", gap:"10px"}}>
                                
                                {
                                    (startdate !== "" || enddate !== '') &&
                                        <button className='btn btn-flat btn-block pull-right' type='button' style={{marginTop:"10px",marginRight:5}} onClick={(e) => clearSchedule(e)} >
                                            Clean Schedule
                                        </button>
                                }
                                <button className='btn btn-primary btn-flat btn-block pull-right' style={{marginTop:"10px"}} >
                                    Save
                                </button>
                            </div>
                        </form>
                    }
                </div>
            </div>


            <div className='renameDialogBG' style={{display:"none"}} id="renameDialogBG">
                <div className='renameBody bigRenameBody'>
                    <div className='renameHeading'>
                        <p className='text-left'>
                            {
                                id === "" && type === "" &&
                                   "Create Folder"
                            }
                            {
                                id !== "" && type !== "" &&
                                   "Rename "+type
                            }
                            <span className='pull-right cursor-pointer' onClick={(e) => {
                                setId("")
                                setName("")
                                setPid("")
                                setDescription("")
                                window.$("#renameDialogBG").fadeOut()
                            }}  title={"Close"} >
                                <i className='fa fa-times'></i>
                            </span>
                        </p>
                    </div>
                    <div className='renameInputCon'>
                        <input type={"text"} name={"name"} className="renameInput" required placeholder='e.g New Folder' onChange={(e) => setName(e.target.value)} value={name} />
                    </div>
                    
                    <br />
                    <div className='renameInputCon'>
                        {
                            editorLoading ? 
                            <>
                                <br /><br />
                                <Loading />
                                <br /><br />
                            </>
                            :
                            editor
                        }
                    </div>
                    <div className='renameFooter'>
                        <button className='btn btn-primary btn-flat pull-right' style={{marginTop:"10px"}} onClick={(e) => addFolder(p_id)}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div className='renameDialogBG' id="importFrame" style={{display:"none"}}>
                <div className='renameBody bigRenameBody'>
                        <div className='renameHeading'>
                            <p className='text-left'>
                                Import Item(s)
                                <span className='pull-right closePopup' onClick={() => {
                                    fetchList()
                                    window.$('#importFrame').fadeOut()
                                }} title="Close">
                                    <i className='fa fa-times'></i>
                                </span>
                            </p>
                        </div>
                        <div className='iframeCon'>
                            {
                                importFileType !== "" &&
                                <iframe src={iframeURL} className="importIframe"></iframe>
                            }
                        </div>
                </div>
            </div>




                            {
                                preview !== "" && !previewLoading &&
                        <div className='renameDialogBG ' id="renameDialogBG">
                            <div className='renameBody contentpreview' >
                                <div className='previewPanel'>
                                    <div className='previewContent' style={{backgroundColor:preview.color+"17"}}>
                                        <br />
                                        
                                        <span className='pull-right previewClose' style={{cursor:'pointer'}} title={"Close Preview"} onClick={() => setPreview("")}>
                                            <FeatherIcon icon={"x"} />
                                        </span>

                                        {
                                            preview.type !== "Image" && preview.type !== "Video" && preview.type !== "Youtube" && preview.type !== "Recording" &&
                                            <div className='chenterCircle forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+preview.thumb+'.png'} />
                                            </div>
                                        }
                                        
                                        {
                                            preview.type === "Image" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <img src={ assets_url + preview.path} />
                                            </div>
                                        }
                                        {
                                            (preview.type === "Video" || preview.type === "Recording") && 
                                            <>
                                            <div className='centerPreview' style={{backgroundColor:preview.color+"17"}}>
                                                {
                                                    preview.tab == "English" ?
                                                    <>
                                                    {
                                                        preview.encrypted_file !== "" && preview.encrypted_file !== null &&
                                                        <>
                                                            {/* <Video id={preview.id} type={"videoUrl"} /> */}
                                                            <Video id={preview.id} type={"encrypt"} />
                                                        </>
                                                    }
                                                    </>
                                                    :
                                                    <>
                                                    {
                                                        preview.encrypted_hindi_file !== "" && preview.encrypted_hindi_file !== null &&
                                                        // <Video id={preview.id} type={"hindi_videoUrl"} />
                                                        <Video id={preview.id} type={"hindi_encrypt"} />
                                                    }
                                                    </>
                                                }
                                            </div>
                                            <div className='changeTabBtns'>
                                                <button className={ preview.tab !== "English" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='English' onClick={() => changePreviewTab("English")}>
                                                    English
                                                </button>
                                                <button className={ preview.tab !== "Hindi" ? 'btn btn-flat' : 'btn btn-flat btn-danger'} title='Hindi' onClick={() => changePreviewTab("Hindi")}>
                                                    Hindi
                                                </button>
                                            </div>
                                            </>
                                        }
                                        {
                                            preview.type === "Youtube" && 
                                            <div className='centerPreview forStar' style={{backgroundColor:preview.color+"17"}}>
                                                <iframe src={preview.youtubeEmbedUrl} style={{border:"none"}}></iframe>
                                            </div>
                                        }
                                        <ul>
                                            <li>
                                                <strong>File Name: </strong> 
                                                {
                                                    
                                                    preview.type === "Document" || preview.type === "Assignment" ?
                                                        <Link to={"#"} onClick={(e) => window.open('/admin/preview/'+preview.id)} title={"Show Preview of "+preview.name}>
                                                            {preview.name}
                                                        </Link>
                                                    :
                                                    preview.name
                                                    
                                                }
                                            </li>


                                            {
                                                preview.type !== "" && typeof preview.type !== "undefined" &&
                                                <li>
                                                    <strong>File Type: </strong> {preview.type}
                                                </li>
                                            }
                                            {
                                                preview.extention !== "" && typeof preview.extention !== "undefined" &&
                                            <li>
                                                <strong>File Extension: </strong> {preview.extention}
                                            </li>
                                            }
                                            
                                            {
                                                preview.width !== "" && typeof preview.width !== "undefined" &&
                                            <li>
                                                <strong>Image Dimentions: </strong> {preview.width + "PX X " + preview.height+"PX"}
                                            </li>
                                            }
                                            {
                                                preview.org_size !== "" && typeof preview.org_size !== "undefined" && preview.tab === "English" &&
                                            <li>
                                                <strong>File Size: </strong> {preview.org_size}
                                            </li>
                                            }

                                            {
                                                preview.hindiItem !== "" && preview.hindi_size !== null && typeof preview.hindi_size !== "undefined" && preview.tab === "Hindi" &&
                                            <li>
                                                <strong>File Size: </strong> {preview.hindi_size}
                                            </li>
                                            }

                                            {
                                                preview.items !== "" && typeof preview.items !== "undefined" &&
                                            <li>
                                                <strong>Item{"(s)"} : </strong> {preview.items}
                                            </li>
                                            }
                                            {
                                                preview.pagecount !== "" && typeof preview.pagecount !== "undefined" && preview.pagecount !== 0 &&
                                            <li>
                                                <strong>Document Length: </strong> {preview.pagecount}
                                            </li>
                                            }
                                            
                                            
                                            {
                                                typeof preview.durationHour !== "undefined" && preview.durationHour !== "" && preview.tab === "English" &&
                                            <li>
                                                <strong>Video Duration: </strong> {preview.durationHour}
                                            </li>
                                            }
                                            {
                                                preview.tab === "Hindi" && typeof preview.hindiItem.durationHour !== "undefined" && preview.hindiItem !== "" && preview.hindiItem.durationHour !== "" && 
                                            <li>
                                                <strong>Video Duration: </strong> {preview.hindiItem.durationHour}
                                            </li>
                                            }
                                            {
                                                typeof preview.created_at !== "undefined" &&
                                            <li>
                                                <strong>Created On: </strong> {preview.created_at}
                                            </li>
                                            }
                                            {
                                                typeof preview.updated_at !== "undefined" &&
                                            <li>
                                                <strong>Last Modified On: </strong> {preview.updated_at}
                                            </li>
                                            }
                                            {
                                                preview.type === "Live" &&
                                                <li>
                                                    {
                                                        preview.liveClass.status == 1 &&
                                                        <button className={ 'btn btn-flat btn-block btn-success' } title='Launch Class' onClick={() => launchClass(preview)}>
                                                            {preview.liveClass.message}
                                                        </button>
                                                    }
                                                    {
                                                        preview.liveClass.status == 2 &&
                                                        <button className={ 'btn btn-flat btn-block btn-warning' } title='Launch Class' onClick={() => launchClass(preview)}>
                                                            {preview.liveClass.message}
                                                        </button>
                                                    }
                                                    {
                                                        preview.liveClass.status == 3 &&
                                                        <button className={ 'btn btn-flat btn-block btn-danger' } title='Launch Class' >
                                                            {preview.liveClass.message}
                                                        </button>
                                                    }
                                                </li>
                                            }
                                        </ul>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    
        {
            addLiveClass &&
            
        <div className='renameDialogBG' id='LiveClassPop' >
            <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        Edit Live Class
                        <span className='cursor-pointer pull-right' onClick={() => {
                            setAddLiveClass(false)
                            fetchList()
                            }} >
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInputCon'>
                    <Liveclassimport courseid={params.id} folderid={p_id} data={addLiveClassData} />
                </div>
            </div>
        </div>
        }
        
        {
            addAssignment &&
            
        <div className='renameDialogBG' id='LiveClassPop' >
            <div className='renameBody bigRenameBody'>
                <div className='renameHeading'>
                    <p className='text-left'>
                        {assignmentType} Assignment
                        <span className='cursor-pointer pull-right' onClick={() => {
                            setAddAssignment(false)
                            }} >
                            <i className='fa fa-times'></i>
                        </span>
                    </p>
                </div>
                <div className='renameInputCon'>
                    <Assignment main_id={params.id} p_id={params.p_id} aboveBelow={""} aboveBelowPosition={""} type={assignmentType} assignmentId={assignmentId} contentid={id} from={"Course"} />
                </div>
            </div>
        </div>
        }


          </>
          
          :
          <Notfound />
          }
          </>
        )
      }

  return (
    <>
        <Sidebar page={CoursecontentPage} permission={courseindex} />
    </>
  )
}
