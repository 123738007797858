import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import apiurl from '../../Global';
import Studentdashboard from './Birdview/Studentdashboard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Myorders from './Birdview/Myorders';
import Myaddress from './Birdview/Myaddress';
import Mytransactions from './Birdview/Mytransactions';
import Myforum from './Birdview/Myforum';
import Mynotifications from './Birdview/Mynotifications';
import Myprofile from './Birdview/Myprofile';
import Purchase_History from './Birdview/PurchaseHistory';
const cookies = new Cookies();


const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export default function Studentprofile() {

    const query = useQuery();
    

  const Studentprofilepage = (props) => {
        
    const params = useParams()
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }
    const [tab, setTab] = useState("")
    // const [tab, setTab] = useState("Dashboad")
    
    const userid = params.userid
    const dummyToken = "988|1em1PN8gmRpYUXiA2XHOxsSffvaVRvpwdsLVQdDH"
    const [loading, setLoading] = useState(false)
    const [udashboard, setUdashboard] = useState(null)

    const getUserDashboard = () => {
        setLoading(true)
        fetch(apiurl+"api/user/dashboard", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                userid: userid,
                mode: "Web"
            })
            })
            .then(response => response.json()) 
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setUdashboard(response.dashboard)
                    setLoading(false)
                    
                    if(pagePermissions(props.pagepermissions, "Students", "edit_profile_tab") && tab === ""){ setTab("Profile") }
                    if(pagePermissions(props.pagepermissions, "Students", "notifications_tab") && tab === ""){ setTab("Notifications") }
                    if(pagePermissions(props.pagepermissions, "Students", "forum_tab") && tab === ""){ setTab("Forum") }
                    if(pagePermissions(props.pagepermissions, "Students", "wallet_tab") && tab === ""){ setTab("Wallet") }
                    if(pagePermissions(props.pagepermissions, "Students", "address_tab") && tab === ""){ setTab("Address") }
                    if(pagePermissions(props.pagepermissions, "Students", "my_purchases_tab") && tab === ""){ setTab("Purchases") }

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const logoutUserFunc = () => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => logoutUser()
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }
    const changeStatus = (item) => {
        
        fetch(apiurl+"api/change_student_status", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    activeid: item.id,
                    value: item.status === "1" ? "0" : "1",
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                successModal("Success", "Status Changed")
                getUserDashboard()
            })
            .catch(err => { });
    }
    const logoutUser = () => {
        fetch(apiurl+"api/logoutUser", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    userid: params.userid,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                successModal("Success", "Student Logged Out Successfully")
                getUserDashboard()
            })
            .catch(err => { });
    }

    const logoutSingle = (token) => {
        fetch(apiurl+"api/logoutUserSingle", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    userid: params.userid,
                    token: token,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                successModal("Success", "Student Logged Out Successfully")
                getUserDashboard()
            })
            .catch(err => { });
    }

    var a = 0;
    useEffect(() => {
        if(a === 0){
            getUserDashboard()
            // getStudents()
            a++
        }
    }, [])

    return(
        <>
        {
            pagePermissions(props.pagepermissions, "Students", "view_profile") &&
        <>
            <div className='col-md-3'>
                <div className="box no-border">
                    <div className="box-body box-profile" style={{position:"relative"}} >
                        {
                            loading || udashboard === null ? 
                                <>
                                    <br /><br /><br />
                                    <Loading />
                                    <br /><br /><br /><br /><br /><br />
                                </>
                            :
                            <>
                            {
                                udashboard.user.image === null || udashboard.user.image === "" ?
                                <></>
                                :
                                <img className="profile-user-img img-responsive img-circle" src={assets_url + udashboard.user.image} alt="User profile picture" style={{width:"100px", height:"100px"}} />
                            }
                                <h3 className="profile-username text-center">
                                    { udashboard.user.name }
                                </h3>

                                <p className="text-muted text-center">
                                    <a href={ "mailto:"+udashboard.user.email } title={"Mail To "+ udashboard.user.email }>
                                        { udashboard.user.email }
                                    </a>
                                </p>
                                <p className="text-muted text-center">
                                    <a href={ "tel:"+udashboard.user.contact } title={"Call to "+udashboard.user.contact} >
                                        { udashboard.user.contact }
                                    </a>
                                </p>

                                <ul className="list-group list-group-unbordered">
                                    <li className="list-group-item">
                                    <b>Wallet Balance</b> <a className="pull-right">{ udashboard.balance }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Active Purchases</b> <a className="pull-right">{ udashboard.activepurchase }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Total Purchases</b> <a className="pull-right">{ udashboard.totalpurchase }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>User ID</b> <a className="pull-right">{ params.userid }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Number of Logins Till Now</b> <a className="pull-right">{ typeof udashboard.logins !== "undefined" && udashboard.logins.length }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Total Android Login</b> <a className="pull-right">{ udashboard.android_logins }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Total IOS Login</b> <a className="pull-right">{ udashboard.ios_logins }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Total Web Login</b> <a className="pull-right">{ udashboard.web_logins }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Total Unknown Login</b> <a className="pull-right">{ udashboard.unknown_logins }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Currently Loggedins</b> <a className="pull-right">{ udashboard.currectntly_loggedin }</a>
                                    </li>
                                    <li className="list-group-item">
                                    <b>Very First Interaction</b> <a className="pull-right">{ udashboard.firstinteract }</a>
                                    </li>
                                    
                                </ul>
                                
                                {
                                    pagePermissions(props.pagepermissions, "Students", "logout_button") &&
                                    <div className='col-md-12 no-padding'>
                                        <div className='col-md-6 no-left-padding'>
                                            <button className="btn btn-danger btn-block" onClick={() => logoutUserFunc()} ><b>Logout User?</b></button>
                                        </div>
                                        <div className='col-md-6 no-right-padding'>
                                            <button className="btn btn-danger btn-block" onClick={() => changeStatus(udashboard.user)} >
                                                <b>
                                                    {
                                                        udashboard.user.status === "1" ? "Block User?" : "Un-Block User?"
                                                    }
                                                </b>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
                {
                    !loading && udashboard !== null && typeof udashboard.logins !== "undefined" && udashboard.logins.length > 0 &&
                    pagePermissions(props.pagepermissions, "Students", "loggedin_summary") &&
                    <div className="box no-border">
                        <div className="box-header with-border">
                            <h3 className="box-title">Last Login On</h3>
                        </div>
                        <div className='box-body'>
                            <div >
                                <strong>
                                    <i className="fa fa-calendar margin-r-5"></i> 
                                    {udashboard.lastlogin.created_at}
                                    <span className='pull-right'>
                                    {
                                        udashboard.lastlogin.device === "Android" &&
                                        <i className={"fa fa-android"}></i>
                                    }
                                    {
                                        udashboard.lastlogin.device === "Ios" &&
                                        <i className={"fa fa-apple"}></i>
                                    }
                                    {
                                        udashboard.lastlogin.device === "Web" &&
                                        <i className={"fa fa-desktop"}></i>
                                    }
                                    </span>
                                </strong>
                                <p className="text-muted">
                                    IP Address: {udashboard.lastlogin.info.ip}
                                </p>
                                <p className="text-muted">
                                    <strong>City: </strong>{udashboard.lastlogin.info.cityName+", "+udashboard.lastlogin.info.countryName} ({udashboard.lastlogin.info.regionCode+" - "+udashboard.lastlogin.info.countryCode})
                                </p>
                                {
                                    udashboard.lastlogin.info.additional_information !== undefined &&
                                    <>
                                        {
                                            udashboard.lastlogin.info.additional_information.browserName !== "" && udashboard.lastlogin.info.additional_information.browserName !== null &&
                                            <p className="text-muted">
                                                <strong>Browser: </strong>{udashboard.lastlogin.info.additional_information.browserName}
                                            </p>
                                        }
                                        {
                                            udashboard.lastlogin.info.additional_information.deviceFamily !== "" && udashboard.lastlogin.info.additional_information.deviceFamily !== null &&
                                            <p className="text-muted">
                                                <strong>Device Brand: </strong>{udashboard.lastlogin.info.additional_information.deviceFamily}
                                            </p>
                                        }
                                        {
                                            udashboard.lastlogin.info.additional_information.deviceModel !== "" && udashboard.lastlogin.info.additional_information.deviceModel !== null &&
                                            <p className="text-muted">
                                                <strong>Device Model: </strong>{udashboard.lastlogin.info.additional_information.deviceModel}
                                            </p>
                                        }
                                    </>
                                }
                                <hr />
                            </div>
                        </div>
                    </div>
                }
                
                {
                    !loading && udashboard !== null && typeof udashboard.logins !== "undefined" && udashboard.logins.length > 0 && udashboard.frequentry_logins !== null &&
                    pagePermissions(props.pagepermissions, "Students", "loggedin_summary") &&
                    <div className="box no-border">
                        <div className="box-header with-border">
                            <h3 className="box-title">Frequently Loggedin In</h3>
                        </div>
                        <div className='box-body'>
                            <div >
                                <strong>
                                    <i className="fa fa-calendar margin-r-5"></i> 
                                    {udashboard.frequentry_logins.created_at}
                                    <span className='pull-right'>
                                    {
                                        udashboard.frequentry_logins.device === "Android" &&
                                        <i className={"fa fa-android"}></i>
                                    }
                                    {
                                        udashboard.frequentry_logins.device === "Ios" &&
                                        <i className={"fa fa-apple"}></i>
                                    }
                                    {
                                        udashboard.frequentry_logins.device === "Web" &&
                                        <i className={"fa fa-desktop"}></i>
                                    }
                                    </span>
                                </strong>
                                <p className="text-muted">
                                    IP Address: {udashboard.frequentry_logins.info.ip}
                                </p>
                                <p className="text-muted">
                                    <strong>City: </strong>{udashboard.frequentry_logins.info.cityName+", "+udashboard.frequentry_logins.info.countryName} ({udashboard.frequentry_logins.info.regionCode+" - "+udashboard.frequentry_logins.info.countryCode})
                                </p>
                                {
                                    udashboard.frequentry_logins.info.additional_information !== undefined &&
                                    <>
                                        {
                                            udashboard.frequentry_logins.info.additional_information.browserName !== "" && udashboard.frequentry_logins.info.additional_information.browserName !== null &&
                                            <p className="text-muted">
                                                <strong>Browser: </strong>{udashboard.frequentry_logins.info.additional_information.browserName}
                                            </p>
                                        }
                                        {
                                            udashboard.frequentry_logins.info.additional_information.deviceFamily !== "" && udashboard.frequentry_logins.info.additional_information.deviceFamily !== null &&
                                            <p className="text-muted">
                                                <strong>Device Brand: </strong>{udashboard.frequentry_logins.info.additional_information.deviceFamily}
                                            </p>
                                        }
                                        {
                                            udashboard.frequentry_logins.info.additional_information.deviceModel !== "" && udashboard.frequentry_logins.info.additional_information.deviceModel !== null &&
                                            <p className="text-muted">
                                                <strong>Device Model: </strong>{udashboard.frequentry_logins.info.additional_information.deviceModel}
                                            </p>
                                        }
                                    </>
                                }
                                <hr />
                            </div>
                        </div>
                    </div>
                }
            {
                loading || udashboard === null ?
                <div className="box no-border">
                    <div className="box-body">
                        <>
                            <br /><br /><br />
                            <Loading />
                            <br /><br /><br /><br /><br /><br />
                        </>
                    </div>
                </div>
                : 
                
                pagePermissions(props.pagepermissions, "Students", "loggedin_summary") &&
                <div className="box no-border">
                    <div className="box-header with-border">
                    <h3 className="box-title">Logged In Summary</h3>
                    </div>
                    <div className="box-body" style={{maxHeight:"400px", overflow:"auto"}} >
                    {
                        typeof udashboard.logins !== "undefined" && udashboard.logins.length > 0 &&
                        udashboard.logins.map((item, index) => {
                            moment.locale('en');
                            var dt = item.created_at;
                            const ip = JSON.parse(item.info) 
                            var icon = "desktop"
                            if(item.device === "Android"){ icon = "android" }
                            if(item.device === "Ios" || item.device === "IOS"){ icon = "apple" }
                            
                            return(
                                <div key={"login"+index} >
                                    <strong>
                                        <i className="fa fa-calendar margin-r-5"></i> {moment(dt).format("D-M-Y HH:mm:ss")}
                                        <span className='pull-right'><i className={"fa fa-"+icon}></i></span>
                                    </strong>
                                    <p className="text-muted">
                                        IP Address: {ip.ip}
                                    </p>
                                    <p className="text-muted">
                                        <strong>City: </strong>{ip.cityName+", "+ip.countryName} ({ip.regionCode+" - "+ip.countryCode})
                                    </p>
                                    {
                                        ip.additional_information !== undefined &&
                                        <>
                                            {
                                                ip.additional_information.browserName !== "" && ip.additional_information.browserName !== null &&
                                                <p className="text-muted">
                                                    <strong>Browser: </strong>{ip.additional_information.browserName}
                                                </p>
                                            }
                                            {
                                                ip.additional_information.deviceFamily !== "" && ip.additional_information.deviceFamily !== null &&
                                                <p className="text-muted">
                                                    <strong>Device Brand: </strong>{ip.additional_information.deviceFamily}
                                                </p>
                                            }
                                            {
                                                ip.additional_information.deviceModel !== "" && ip.additional_information.deviceModel !== null &&
                                                <p className="text-muted">
                                                    <strong>Device Model: </strong>{ip.additional_information.deviceModel}
                                                </p>
                                            }
                                        </>
                                    }
                                    {
                                        udashboard.user.remember_token !== null && udashboard.user.remember_token.includes(item.token) &&
                                        pagePermissions(props.pagepermissions, "Students", "logout_button") &&
                                        <button className='btn btn-danger btn-flat btn-block' onClick={() => logoutSingle(item.token)} >
                                            Logout?
                                        </button>
                                    }
                                    <hr />
                                </div>
                                
                                )
                            })
                        }
                    </div>
                    
                </div>
            }
            </div>

            <div className="col-md-9">
                <div className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                    {/* <li className={tab === "Dashboad" ? "active" : ""} onClick={() => setTab("Dashboad")} ><a href="#Dashboard" data-toggle="tab">My Activities</a></li> */}
                    {
                        pagePermissions(props.pagepermissions, "Students", "my_purchases_tab") &&
                        <li className={tab === "Purchases" ? "active" : ""} onClick={() => setTab("Purchases")} ><a href="#Purchases" data-toggle="tab">My Purchases</a></li>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Students", "purchase_history_tab") &&
                        <li className={tab === "Purchase_History" ? "active" : ""} onClick={() => setTab("Purchase_History")} ><a href="#Purchase_History" data-toggle="tab">Purchase History</a></li>
                    }
                    
                    {
                        pagePermissions(props.pagepermissions, "Students", "address_tab") &&
                        <li className={tab === "Address" ? "active" : ""} onClick={() => setTab("Address")} ><a href="#Address" data-toggle="tab">Address List</a></li>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Students", "wallet_tab") &&
                        <li className={tab === "Wallet" ? "active" : ""} onClick={() => setTab("Wallet")} ><a href="#Wallet" data-toggle="tab">Wallet</a></li>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Students", "forum_tab") &&
                        <li className={tab === "Forum" ? "active" : ""} onClick={() => setTab("Forum")} ><a href="#Forum" data-toggle="tab">Forum</a></li>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Students", "notifications_tab") &&
                        <li className={tab === "Notifications" ? "active" : ""} onClick={() => setTab("Notifications")} ><a href="#Notifications" data-toggle="tab">Notifications</a></li>
                    }
                    {
                        pagePermissions(props.pagepermissions, "Students", "edit_profile_tab") &&
                        <li className={tab === "Profile" ? "active" : ""} onClick={() => setTab("Profile")} ><a href="#Profile" data-toggle="tab">Edit Profile</a></li>
                    }
                    </ul>
                    <div className="tab-content">
                        <div className="active tab-pane" id="activity">
                            {
                                tab === "Dashboad" && !loading &&
                                <Studentdashboard dummyToken={dummyToken} />
                            }
                            {
                                tab === "Purchases" && !loading &&
                                <Myorders dummyToken={dummyToken} />
                            }
                            {
                                tab === "Purchase_History" && !loading &&
                                <Purchase_History dummyToken={dummyToken} />
                            }
                            {
                                tab === "Address" && !loading &&
                                <Myaddress dummyToken={dummyToken} />
                            }
                            {
                                tab === "Wallet" && !loading &&
                                <Mytransactions dummyToken={dummyToken} />
                            }
                            {
                                tab === "Forum" && !loading &&
                                <Myforum dummyToken={dummyToken} />
                            }
                            {
                                tab === "Notifications" && !loading &&
                                <Mynotifications dummyToken={dummyToken} />
                            }
                            {
                                tab === "Profile" && !loading &&
                                <Myprofile dummyToken={dummyToken} user={udashboard.user} updateUser={getUserDashboard} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
        {
            !pagePermissions(props.pagepermissions, "Students", "view_profile") &&
            <>
            
            <Notfound />
            </>
        }
        </>
        
    )
  }
  return (
    <>
        {
            (query.get("manage") !== null) ?
            <Sidebar page={Studentprofilepage} permission="Searchable_Students"/>
            :   
            <Sidebar page={Studentprofilepage} permission="Students"/>
            
        }
    </>
  )
}
