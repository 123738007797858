import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import apiurl from '../../Global';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../includes/Loading';
import base_config from '../../Config';
import Sidebar from '../includes/Sidebar';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
const cookies = new Cookies();

export default function Rules() {
  const RulesPages = () => {
    
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
    const [rules, setRules] = useState({
        "cbcoin" : "",
        "signupcb" : "",
        "cbexpire" : "",
        "cartpercent" : "",
        "show_promocodes" : "",
        "show_gst" : "",
        "auto_approve_forum" : "",

        "show_whatsapp" : "",
        "whatsapp_link" : "",
        
        "menutopshow1" : "",
        "menutopshow2" : "",
        "menutopshow3" : "",
        "menutopshow4" : "",
        "menutopshow5" : "",
        "menutopshow6" : "",
        "menutopshow7" : "",
        "menutopshow8" : "",
        "menutopshow9" : "",
        "menutopshow10" : "",

        "menutop1" : "",
        "menutop2" : "",
        "menutop3" : "",
        "menutop4" : "",
        "menutop5" : "",
        "menutop6" : "",
        "menutop7" : "",
        "menutop8" : "",
        "menutop9" : "",
        "menutop10" : "",

        "popup_show_time" : "",
        "popup_autohide_time" : "",
        "autoplay_time" : "",

        "showslide1" : "",
        "slideimg1" : "",
        "slideimgurl1" : "",
        "showslide2" : "",
        "slideimg2" : "",
        "slideimgurl2" : "",
        "showslide3" : "",
        "slideimg3" : "",
        "slideimgurl3" : "",
        "showslide4" : "",
        "slideimg4" : "",
        "slideimgurl4" : "",
        "showslide5" : "",
        "slideimg5" : "",
        "slideimgurl5" : "",


        "menutop1url" : "",
        "menutop2url" : "",
        "menutop3url" : "",
        "menutop4url" : "",
        "menutop5url" : "",
        "menutop6url" : "",
        "menutop7url" : "",
        "menutop8url" : "",
        "menutop9url" : "",
        "menutop10url" : "as",

        "testimonials_heading" : "",
        "testimonials_sub_heading" : "",
        
        "counters_heading" : "",
        "counters_sub_heading" : "",

        "counter1icon" : "",
        "counter1title" : "",
        "counter1body" : "",
        
        "counter2icon" : "",
        "counter2title" : "",
        "counter2body" : "",
        
        "counter3icon" : "",
        "counter3title" : "",
        "counter3body" : "",
        
        "counter4icon" : "",
        "counter4title" : "",
        "counter4body" : "",
        
        "counter5icon" : "",
        "counter5title" : "",
        "counter5body" : "",

        "blogs_heading" : "",
        "blogs_sub_heading" : "",
        
        "download_app_heading" : "",
        "download_app_sub_heading" : "",
        "android_app_url" : "",
        "ios_app_url" : "",
        "app_image" : "",
        
        "footer_paragraph" : "",

        "home_seo_title" : "",
        "home_seo_keywords" : "",
        "home_seo_description" : "",

        "home_founder_status" : "",
        "home_founder_title" : "",
        "home_founder_body" : "",
        "home_founder_name" : "",
        "home_founder_designation" : "",
        "home_founder_photo" : "",

        "all_page_head_script" : "",
        "all_page_body_script" : "",

        "top_banner_timing" : "",
        "bottom_banner_timing" : "",

        "WAREHOUSE_NAME": "",
        "WAREHOUSE_EMAIL": "",
        "WAREHOUSE_CONTACT": "",
        "WAREHOUSE_PINCODE": "",
        "WAREHOUSE_CITY": "",
        "WAREHOUSE_STATE": "",
        "WAREHOUSE_ADDRESS_LINE_1": "",
        "WAREHOUSE_ADDRESS_LINE_2": "",

        "CUSTOMER_CODE": "",
        "CUSTOMER_API": "",
        "DTDC_URLS": "",
        "DEFAULT_SERVICE_TYPE": "",
        "DEFAULT_LOAD_TYPE": "",
        
        
    })
    const [refresh, setRefresh] = useState("No")
    const [loading, setLoading] = useState(true)

    const changeRule = (name, value) => {
        var temp = rules
        temp[name] = value
        setRules(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const submitRule = (e) => {
        e.preventDefault()
        formLoading(e.target)

        
        const formData = new FormData();
        
        formData.append('counter1icon', rules.counter1icon);
        formData.append('counter2icon', rules.counter2icon);
        formData.append('counter3icon', rules.counter3icon);
        formData.append('counter4icon', rules.counter4icon);
        formData.append('counter5icon', rules.counter5icon);
        formData.append('slideimg1', rules.slideimg1);
        formData.append('slideimg2', rules.slideimg2);
        formData.append('slideimg3', rules.slideimg3);
        formData.append('slideimg4', rules.slideimg4);
        formData.append('slideimg5', rules.slideimg5);
        formData.append('app_image', rules.app_image);
        formData.append('home_founder_photo', rules.home_founder_photo);
        for(var i=1;i<=10;i++){
            rules['menutopshow'+i] =  window.$("#select"+i).val()
        }
        console.log(rules)
        formData.append('rules', JSON.stringify(rules));
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        

        fetch(apiurl+"api/setRules", {
            "method": "POST",
            "headers": {
                // "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").fadeOut()
                setTimeout(function(){
                    $(e.target).find(".loaderbody").remove()
                }, 2000)
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
            });    
    }

    const getRules = () => {
        
        fetch(apiurl+"api/getRules", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    page_url: window.location.href,
                    staff: AdminBasics()
                })
            })
            .then(response => response.json())
            .then(response => {
                
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    
                    // var temp = rules
                    // temp['cbcoin'] = response.rules.coinvalue
                    // temp['signupcb'] = response.rules.signupcb
                    // temp['cbexpire'] = response.rules.signupcbdays
                    // temp['cartpercent'] = response.rules.cartpercent
                    setRules(JSON.parse(response.rules.jsondata))
                    setRefresh(refresh === "No" ? "Yes" : "No")
                    setTimeout(function(){
                        window.$(".select2").each(function(){
                            var id = window.$(this).attr("id")
                            window.$("#"+id).unbind().select2()
                        })
                    }, 2000)

                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => { });
    }

    var a = 0
    useEffect(() => {
      if(a === 0){
        getRules()
        a++
      }  
    }, [])


    return(
        <>
        {
            loading ?
        <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-body' style={{paddingTop:"100px", paddingBottom: "100px"}}>
                    <Loading />
                </div>
            </div>
        </div>
    :
        <form action='' method='POST' onSubmit={(e) => submitRule(e)}  >
            <div className='box no-border' style={{overflow:"hidden", backgroundColor:"transparent", boxShadow:"none"}}>
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Coin Rule
                            </h3>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-6 no-left-padding'>
                                <label>Value of 1 Coin</label>
                                <input type={"number"} value={rules.cbcoin} className="form-control" placeholder='e.g 1' onChange={(e) => changeRule('cbcoin', e.target.value)} />
                            </div>
                            <div className='col-md-6 no-left-padding'>
                                <label>Maximum Cart %</label>
                                <input type={"number"} value={rules.cartpercent} className="form-control" placeholder='e.g 1' onChange={(e) => changeRule('cartpercent', e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Signup CB Rule
                            </h3>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-6 no-left-padding'>
                                <label>Cashback Coin(s)</label>
                                <input type={"number"} value={rules.signupcb} className="form-control" placeholder='e.g 50' onChange={(e) => changeRule('signupcb', e.target.value)} />
                            </div>
                            <div className='col-md-6 no-right-padding'>
                                <label>Expire in (Days)</label>
                                <input type={"number"} value={rules.cbexpire} className="form-control" placeholder='e.g 50' onChange={(e) => changeRule('cbexpire', e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Whatsapp Settings
                            </h3>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-6 no-left-padding'>
                                <label>Show Whatsapp?</label>
                                <select className='form-control' value={rules.show_whatsapp} onChange={(e) => changeRule('show_whatsapp', e.target.value)} >
                                    <option value={"Yes"} >Yes</option>
                                    <option value={""} >No</option>
                                </select>
                            </div>
                            <div className='col-md-6 no-right-padding'>
                                <label>Whatsapp Link</label>
                                <input type={"url"} value={rules.whatsapp_link} className="form-control" placeholder='e.g http://wa.me/9711111111' onChange={(e) => changeRule('whatsapp_link', e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                               Forum Post?
                            </h3>
                        </div>
                        <div className='box-body'>
                            <label>Auto Approve Forum Post?</label>
                            <select className='form-control' value={rules.auto_approve_forum} onChange={(e) => changeRule('auto_approve_forum', e.target.value)} >
                                <option value={""}>No</option>
                                <option value={"1"}>Yes</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                               Show Promocodes Option
                            </h3>
                        </div>
                        <div className='box-body'>
                            <label>Show Promocode Option?</label>
                            <select className='form-control' value={rules.show_promocodes} onChange={(e) => changeRule('show_promocodes', e.target.value)} >
                                <option value={""}>No</option>
                                <option value={"1"}>Yes</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                               Show GST Option
                            </h3>
                        </div>
                        <div className='box-body'>
                            <label>Show GST Option?</label>
                            <select className='form-control' value={rules.show_gst} onChange={(e) => changeRule('show_gst', e.target.value)} >
                                <option value={""}>No</option>
                                <option value={"1"}>Yes</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Popup Settings
                            </h3>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-4 no-left-padding'>
                                <label>
                                    Popup Show In (Seconds)<br />
                                    <span style={{color:"red"}}>(Leave Blank For Hide Popup)</span>
                                </label>
                                <input type={"number"} value={rules.popup_show_time} className="form-control" placeholder='e.g 5' onChange={(e) => changeRule('popup_show_time', e.target.value)} />
                            </div>
                            <div className='col-md-4'>
                                <label>
                                    Popup Hide In (Seconds)<br />
                                    <span style={{color:"red"}}>(Leave Blank For Always Show)</span>
                                </label>
                                <input type={"number"} value={rules.popup_autohide_time} className="form-control" placeholder='e.g 10' onChange={(e) => changeRule('popup_autohide_time', e.target.value)} />
                            </div>
                            <div className='col-md-4 no-right-padding'>
                                <label>
                                    Autoplay Time<br />
                                    <span style={{color:"red"}}>(In Seconds)</span>
                                </label>
                                <input type={"number"} value={rules.autoplay_time} className="form-control" placeholder='e.g 5' onChange={(e) => changeRule('autoplay_time', e.target.value)} />
                            </div>
                        </div>
                        {
                            [...Array(5)].map((item, index) => {
                                item = (index+1)
                                return(
                                    <div className='box-body' key={"slide"+index}>
                                        <div className='col-md-4 no-left-padding'>
                                            <label>
                                                Show Slide {item}
                                            </label>
                                            <select className='form-control' value={rules['showslide'+item]} onChange={(e) => changeRule('showslide'+item, e.target.value)} >
                                                <option value={""}>No</option>
                                                <option value={"1"}>Yes</option>
                                            </select>
                                        </div>
                                        <div className='col-md-4'>
                                            <label style={{display:"block"}} >
                                                Image
                                                {
                                                    rules['slideimg'+(item)] !== undefined && rules['slideimg'+(item)]['path'] !== undefined &&
                                                    <span className='pull-right'>
                                                        <a href={assets_url + rules['slideimg'+(item)]['path']} target={"_blank"} >
                                                            <i className='fa fa-eye' ></i>
                                                        </a>
                                                    </span>
                                                }
                                            </label>
                                            <input type={"file"} accept={".png,.svg,.jpg,.jpeg,.gif"} className="form-control" onChange={(e) => changeRule('slideimg'+(item), e.target.files[0])} />
                                        </div>
                                        <div className='col-md-4 no-right-padding'>
                                            <label>
                                                Redirect URL/Link
                                            </label>
                                            <input type={"url"} value={rules['slideimgurl'+item]} className="form-control" placeholder='e.g https://www.google.com' onChange={(e) => changeRule('slideimgurl'+item, e.target.value)} />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Menu Top Slider
                            </h3>
                        </div>
                        {
                            [...Array(10)].map((item, index) => {
                                return(
                                    <div className='box-body' key={"slide"+index} >
                                        <div className='col-md-4 no-left-padding'>
                                            <label>Slide Show On{index+1}</label>
                                            <select className='form-control select2' multiple id={'select'+(index+1)} defaultValue={rules['menutopshow'+(index+1)]} data-placeholder="Select Show On" >
                                                <option value="Web">Web</option>
                                                <option value="Mobile">Web Mobile</option>
                                            </select>

                                            {/* <input type={"text"} value={rules['menutopshow'+(index+1)]} className="form-control" placeholder='e.g Show On' onChange={(e) => changeRule('menutopshow'+(index+1), e.target.value)} /> */}
                                        </div>
                                        <div className='col-md-4 '>
                                            <label>Slide {index+1}</label>
                                            <input type={"text"} value={rules['menutop'+(index+1)]} className="form-control" placeholder='e.g Slide Text' onChange={(e) => changeRule('menutop'+(index+1), e.target.value)} />
                                        </div>
                                        <div className='col-md-4 no-right-padding'>
                                            <label>Slide {index+1} URL</label>
                                            <input type={"text"} value={rules['menutop'+(index+1)+'url']} className="form-control" placeholder='e.g Slide Text' onChange={(e) => changeRule('menutop'+(index+1)+'url', e.target.value)} />
                                        </div>
                                    </div>
                                    )
                                })
                            }
                    </div>
                </div>
                
                <div className='col-md-6'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Top Banner Autoplay Time <span style={{color:"red"}} >(in Seconds)</span>
                            </h3>
                        </div>
                        <div className='box-body'>
                            <input type={"number"} value={rules['top_banner_timing']} className="form-control" placeholder='e.g 5' onChange={(e) => changeRule('top_banner_timing', e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Bottom Banner Autoplay Time <span style={{color:"red"}} >(in Seconds)</span>
                            </h3>
                        </div>
                        <div className='box-body'>
                            <input type={"number"} value={rules['bottom_banner_timing']} className="form-control" placeholder='e.g 5' onChange={(e) => changeRule('bottom_banner_timing', e.target.value)} />
                        </div>
                    </div>
                </div>
                
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Testimonials
                            </h3>
                        </div>
                        <div className='box-body' >
                            <label>Testimonials Heading</label>
                            <input type={"text"} value={rules['testimonials_heading']} className="form-control" placeholder='e.g Heading' onChange={(e) => changeRule('testimonials_heading', e.target.value)} />
                        </div>
                        <div className='box-body' >
                            <label>Testimonials Sub Heading</label>
                            <input type={"text"} value={rules['testimonials_sub_heading']} className="form-control" placeholder='e.g Sub Heading' onChange={(e) => changeRule('testimonials_sub_heading', e.target.value)} />
                        </div>
                    </div>
                </div>
                
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Counters
                            </h3>
                        </div>
                        <div className='box-body' >
                            <label>Counters Heading</label>
                            <input type={"text"} value={rules['counters_heading']} className="form-control" placeholder='e.g Heading' onChange={(e) => changeRule('counters_heading', e.target.value)} />
                        </div>
                        <div className='box-body' >
                            <label>Counters Sub Heading</label>
                            <input type={"text"} value={rules['counters_sub_heading']} className="form-control" placeholder='e.g Sub Heading' onChange={(e) => changeRule('counters_sub_heading', e.target.value)} />
                        </div>
                    </div>
                </div>

                
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Counters 5 Points
                            </h3>
                        </div>
                        
                        {
                            [...Array(5)].map((item, index) => {
                                return(
                                    <div className='box-body' key={"counter"+index}>
                                        <div className='col-md-4 no-left-padding'>
                                            <label style={{display:"block"}} >
                                                Icon
                                                
                                                {
                                                    rules['counter'+(index+1)+'icon']['path'] !== undefined &&
                                                    <span className='pull-right'>
                                                        <a href={assets_url + rules['counter'+(index+1)+'icon']['path']} target={"_blank"} >
                                                            <i className='fa fa-eye' ></i>
                                                        </a>
                                                    </span>
                                                }
                                            </label>
                                            <input type={"file"} accept={".png,.svg,.jpg,.jpeg,.gif"} className="form-control" placeholder='e.g Heading' onChange={(e) => changeRule('counter'+(index+1)+'icon', e.target.files[0])} />
                                        </div>
                                        <div className='col-md-4'>
                                            <label>Heading</label>
                                            <input type={"text"} value={rules['counter'+(index+1)+'title']} className="form-control" placeholder='e.g Heading' onChange={(e) => changeRule('counter'+(index+1)+'title', e.target.value)} />
                                        </div>
                                        <div className='col-md-4 no-right-padding'>
                                            <label>Sub Heading</label>
                                            <input type={"text"} value={rules['counter'+(index+1)+'body']} className="form-control" placeholder='e.g Sub Heading' onChange={(e) => changeRule('counter'+(index+1)+'body', e.target.value)} />
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Blogs Section
                            </h3>
                        </div>
                        <div className='box-body' >
                            <label>Blogs Heading</label>
                            <input type={"text"} value={rules['blogs_heading']} className="form-control" placeholder='e.g Heading' onChange={(e) => changeRule('blogs_heading', e.target.value)} />
                        </div>
                        <div className='box-body' >
                            <label>Blogs Sub Heading</label>
                            <input type={"text"} value={rules['blogs_sub_heading']} className="form-control" placeholder='e.g Sub Heading' onChange={(e) => changeRule('blogs_sub_heading', e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Download Our App Section
                            </h3>
                        </div>
                        <div className='box-body' >
                            <label>Heading</label>
                            <input type={"text"} value={rules['download_app_heading']} className="form-control" placeholder='e.g Heading' onChange={(e) => changeRule('download_app_heading', e.target.value)} />
                        </div>
                        <div className='box-body' >
                            <label>Sub Heading</label>
                            <input type={"text"} value={rules['download_app_sub_heading']} className="form-control" placeholder='e.g Sub Heading' onChange={(e) => changeRule('download_app_sub_heading', e.target.value)} />
                        </div>
                        <div className='box-body' >
                        
                            <div className='col-md-4 no-left-padding'>
                                <label>Thumbnail</label>
                                {
                                    rules['app_image']['path'] !== undefined &&
                                    <span className='pull-right'>
                                        <a href={assets_url + rules['app_image']['path']} target={"_blank"} >
                                            <i className='fa fa-eye' ></i>
                                        </a>
                                    </span>
                                }
                                <input type={"file"} accept=".png,.jpg,.jpeg,.gif,.svg" className="form-control" onChange={(e) => changeRule('app_image', e.target.files[0])} />
                            </div>
                            <div className='col-md-4'>
                                <label>Android App URL</label>
                                <input type={"url"} value={rules['android_app_url']} className="form-control" placeholder='e.g URL' onChange={(e) => changeRule('android_app_url', e.target.value)} />
                            </div>
                            <div className='col-md-4 no-right-padding'>
                                <label>IOS App URL</label>
                                <input type={"url"} value={rules['ios_app_url']} className="form-control" placeholder='e.g IOS App URL' onChange={(e) => changeRule('ios_app_url', e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Footer Paragraph
                            </h3>
                        </div>
                        <div className='box-body' >
                            <label>Paragraph</label>
                            <textarea type={"text"} defaultValue={rules['footer_paragraph']} className="form-control" placeholder='e.g Paragraph' onChange={(e) => changeRule('footer_paragraph', e.target.value)} ></textarea>
                            {/* <input type={"text"} value={rules['footer_paragraph']} className="form-control" placeholder='e.g Paragraph' onChange={(e) => changeRule('footer_paragraph', e.target.value)} /> */}
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Home Founder Message Section
                            </h3>
                        </div>
                        

                        <div className='box-body' >
                            <div className='col-md-2 no-left-padding'>
                                <label>Show Founder Section</label>
                                <select value={rules['home_founder_status']} className='form-control' onChange={(e) => changeRule('home_founder_status', e.target.value)}  >
                                    <option value={""}>No</option>
                                    <option value={"Yes"}>Yes</option>
                                </select>
                            </div>
                            <div className='col-md-2 no-left-padding'>
                                <label>Title</label>
                                <input type={"text"} value={rules['home_founder_title']} className="form-control" placeholder='e.g Message from chandan poddar sir' onChange={(e) => changeRule('home_founder_title', e.target.value)} />
                            </div>
                            <div className='col-md-2 '>
                                <label>Name</label>
                                <input type={"text"} value={rules['home_founder_name']} className="form-control" placeholder='e.g Chandan Poddar' onChange={(e) => changeRule('home_founder_name', e.target.value)} />
                            </div>
                            <div className='col-md-2 '>
                                <label>Designation</label>
                                <input type={"text"} value={rules['home_founder_designation']} className="form-control" placeholder='e.g Best Founder of Escholars' onChange={(e) => changeRule('home_founder_designation', e.target.value)} />
                            </div>
                            <div className='col-md-4 no-right-padding'>
                                <label>Photograph</label>
                                {
                                    rules['home_founder_photo']['path'] !== undefined &&
                                    <span className='pull-right'>
                                        <a href={assets_url + rules['home_founder_photo']['path']} target={"_blank"} >
                                            <i className='fa fa-eye' ></i>
                                        </a>
                                    </span>
                                }
                                <input type={"file"} accept=".png,.jpg,.jpeg,.gif,.svg" className="form-control" onChange={(e) => changeRule('home_founder_photo', e.target.files[0])} />
                            </div>
                        </div>
                        <div className='box-body'>
                            <CKEditor 
                                editor={ ClassicEditor } 
                                
                                config={
                                    { 
                                    simpleUpload: { 
                                        uploadUrl: apiurl +'api/ckeditor/image_upload'  
                                    }, 
                                    }

                                } 
                                data={rules['home_founder_body']} onChange={ ( event, editor ) => { const data = editor.getData(); changeRule('home_founder_body', data) } } />
                        </div>
                    </div>
                </div>
                
                
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Home SEO
                            </h3>
                        </div>
                        <div className='box-body' >
                            <div className='col-md-6 no-left-padding'>
                                <label>Seo Title</label>
                                <input type={"text"} value={rules['home_seo_title']} className="form-control" placeholder='e.g Best CA Foundation Course' onChange={(e) => changeRule('home_seo_title', e.target.value)} />
                            </div>
                            <div className='col-md-6 no-right-padding'>
                                <label>Seo Keywords</label>
                                <input type={"text"} value={rules['home_seo_keywords']} className="form-control" placeholder='e.g Best CA Foundation Course' onChange={(e) => changeRule('home_seo_keywords', e.target.value)} />
                            </div>
                        </div>
                        
                        <div className='box-body' >
                            <label>Seo Description</label>
                            <input type={"text"} value={rules['home_seo_description']} className="form-control" placeholder='e.g Best CA Foundation Course' onChange={(e) => changeRule('home_seo_description', e.target.value)} />
                        </div>
                    </div>
                </div>
                

                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                All Page Head Script
                            </h3>
                        </div>
                        <div className='box-body'>
                            <textarea className='form-control' rows={8} defaultValue={rules['all_page_head_script']}  placeholder='e.g <script>...</script>' onChange={(e) => changeRule('all_page_head_script', e.target.value)} ></textarea>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                All Page Body Script
                            </h3>
                        </div>
                        <div className='box-body'>
                            <textarea className='form-control' rows={8} defaultValue={rules['all_page_body_script']}  placeholder='e.g <script>...</script>' onChange={(e) => changeRule('all_page_body_script', e.target.value)} ></textarea>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Warehouse Details
                            </h3>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-4 no-left-padding'>
                                <label>Name</label>
                                <input type={"text"} value={rules['WAREHOUSE_NAME']} className="form-control" placeholder='e.g Escholars' onChange={(e) => changeRule('WAREHOUSE_NAME', e.target.value)} />
                            </div>
                            <div className='col-md-4 '>
                                <label>Email</label>
                                <input type={"email"} value={rules['WAREHOUSE_EMAIL']} className="form-control" placeholder='e.g warehouse@escholars.in' onChange={(e) => changeRule('WAREHOUSE_EMAIL', e.target.value)} />
                            </div>
                            <div className='col-md-4 no-right-padding'>
                                <label>Contact</label>
                                <input type={"text"} value={rules['WAREHOUSE_CONTACT']} className="form-control" placeholder='e.g 9197xxxxxxxx' onChange={(e) => changeRule('WAREHOUSE_CONTACT', e.target.value)} />
                            </div>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-4 no-left-padding'>
                                <label>Pincode</label>
                                <input type={"text"} value={rules['WAREHOUSE_PINCODE']} className="form-control" placeholder='e.g 110033' onChange={(e) => changeRule('WAREHOUSE_PINCODE', e.target.value)} />
                            </div>
                            <div className='col-md-4 '>
                                <label>City</label>
                                <input type={"text"} value={rules['WAREHOUSE_CITY']} className="form-control" placeholder='e.g Delhi' onChange={(e) => changeRule('WAREHOUSE_CITY', e.target.value)} />
                            </div>
                            <div className='col-md-4 no-right-padding'>
                                <label>State</label>
                                <input type={"text"} value={rules['WAREHOUSE_STATE']} className="form-control" placeholder='e.g Delhi' onChange={(e) => changeRule('WAREHOUSE_STATE', e.target.value)} />
                            </div>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-6 no-left-padding'>
                                <label>Address Line 1</label>
                                <input type={"text"} value={rules['WAREHOUSE_ADDRESS_LINE_1']} className="form-control" placeholder='e.g Address Line 1' onChange={(e) => changeRule('WAREHOUSE_ADDRESS_LINE_1', e.target.value)} />
                            </div>
                            <div className='col-md-6 no-right-padding'>
                                <label>Address Line 2</label>
                                <input type={"text"} value={rules['WAREHOUSE_ADDRESS_LINE_2']} className="form-control" placeholder='e.g Address Line 2' onChange={(e) => changeRule('WAREHOUSE_ADDRESS_LINE_2', e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                DTDC Settings
                            </h3>
                        </div>
                        <div className='box-body'>
                            <div className='col-md-2 no-left-padding'>
                                <label>CUSTOMER CODE</label>
                                <input type={"text"} value={rules['CUSTOMER_CODE']} className="form-control" placeholder='e.g NL3825' onChange={(e) => changeRule('CUSTOMER_CODE', e.target.value)} />
                            </div>
                            <div className='col-md-4'>
                                <label>CUSTOMER API</label>
                                <input type={"text"} value={rules['CUSTOMER_API']} className="form-control" placeholder='e.g 07c4be1ba7de645ebb47cc1bc526ab' onChange={(e) => changeRule('CUSTOMER_API', e.target.value)} />
                            </div>
                            <div className='col-md-2'>
                                <label>CUSTOMER URL</label>
                                
                                <select className='form-control' value={rules.DTDC_URLS} onChange={(e) => changeRule('DTDC_URLS', e.target.value)} >
                                    <option value={"app"} >Live API</option>
                                    <option value={"demodashboardapi"} >Demo Dashboard API</option>
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <label>SERVICE TYPE</label>
                                <select className='form-control' value={rules.DEFAULT_SERVICE_TYPE} onChange={(e) => changeRule('DEFAULT_SERVICE_TYPE', e.target.value)} >
                                    <option value={"GROUND EXPRESS"} >GROUND EXPRESS</option>
                                    <option value={"PRIORITY"} >PRIORITY</option>
                                    <option value={"EXPRESS"} >EXPRESS</option>
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <label>LOAD TYPE</label>
                                <select className='form-control' value={rules.DEFAULT_LOAD_TYPE} onChange={(e) => changeRule('DEFAULT_LOAD_TYPE', e.target.value)} >
                                    <option value={"NON-DOCUMENT"} >NON-DOCUMENT</option>
                                    <option value={"DOCUMENT"} >DOCUMENT</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className='col-md-12'>
                    <div className='box no-border'>
                        <div className='box-body'>
                            <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={'Update Rule(s) & Settings'} />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    }
        </>
    )
  }
  return (
    <>
      <Sidebar page={RulesPages} permission="Rules"/>
    </>
  )
}
