const CoursesFacilitiesList = [
    {
        page_name : 'GLOBALCOURSE',
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "acivity_panel", value: false},
            { name : "basic_information", value: false},
            { name : "price_variations", value: false},
            { name : "link_promocodes", value: false},
            { name : "upsale", value: false},
            { name : "seo", value: false},
            // { name : "live_classes", value: coursePermissionsCheck(x, courseindex, "live_classes") },
            { name : "package_content", value: false},
            { name : "course_content", value: false},
            { name : "mail_notification", value: false},
            { name : "recycle_bin", value: false},
            { name : "daily_traffic_chart", value: false},
            { name : "daily_revenue_chart", value: false},
            { name : "india_chart", value: false},
            { name : "files_feedback", value: false},
            { name : "enrolled_tab", value: false},
            { name : "banners_tab", value: false},
            { name : "expire_notification", value: false},
            { name : "all_content_schedule_button", value: false},
            { name : "download_all_button", value: false},
            { name : "send_notification_box", value: false},
            { name : "course_mark_all", value: false},
            { name : "course_drag_all", value: false},
            { name : "course_chats_tab", value: false},
            
            
            { name : "test_attempts_tab", value: false},
            { name : "view_test_summary", value: false},
            { name : "alter_quiz_marks", value: false},
            { name : "show_staff_details", value: false},


            
            { name : "assignments_tab", value: false},
            { name : "all_assignment_tab", value: false},
            { name : "teacher_review_tab", value: false},
            { name : "accepted_by_teacher_tab", value: false},
            { name : "rejected_by_teacher_tab", value: false},
            { name : "finished_assignment_tab", value: false },
            { name : "rejected_assignment_tab", value: false },
            { name : "show_assignment_trail_staff", value: false},
            { name : "allow_reupload", value: false},
            
            { name : "view_course_folder", value: false },
            { name : "view_course_image", value: false },
            { name : "view_course_video", value: false },
            { name : "view_course_document", value: false },
            { name : "view_course_quiz", value: false },
            { name : "view_course_live_classes", value: false },
            { name : "view_course_assignment", value: false },
            
            { name : "add_course_folder", value: false },
            { name : "add_course_image", value: false },
            { name : "add_course_video", value: false },
            { name : "add_course_document", value: false },
            { name : "add_course_quiz", value: false },
            { name : "add_course_live_classes", value: false },
            { name : "add_course_assignment", value: false },
            
            { name : "edit_course_folder", value: false },
            { name : "edit_course_image", value: false },
            { name : "edit_course_video", value: false },
            { name : "edit_course_document", value: false },
            { name : "edit_course_quiz", value: false },
            { name : "edit_course_live_classes", value: false },
            { name : "edit_course_assignment", value: false },
            
            { name : "delete_course_folder", value: false },
            { name : "delete_course_image", value: false },
            { name : "delete_course_video", value: false },
            { name : "delete_course_document", value: false },
            { name : "delete_course_quiz", value: false },
            { name : "delete_course_live_classes", value: false },
            { name : "delete_course_assignment", value: false },

            // { name : "schedule_course_folder", value: coursePermissionsCheck(x, courseindex, "schedule_course_folder") },
            { name : "schedule_course_image", value: false },
            { name : "schedule_course_video", value: false },
            { name : "schedule_course_document", value: false },
            { name : "schedule_course_quiz", value: false },
            { name : "schedule_course_live_classes", value: false },
            { name : "schedule_course_assignment", value: false },

            { name : "info_course_folder", value: false },
            { name : "info_course_image", value: false },
            { name : "info_course_video", value: false },
            { name : "info_course_document", value: false },
            { name : "add_course_quiz_question", value: false },
            { name : "info_course_live_classes", value: false },
            { name : "info_course_assignment", value: false },

            { name : "download_course_document", value: false },
            
            { name : "download_course_assignment", value: false },
        ]
    }
]
export default CoursesFacilitiesList