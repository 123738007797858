import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Sidebar from '../includes/Sidebar'
import CommonFunc from '../includes/Common'
import Cookies from 'universal-cookie';
import FeatherIcon from 'feather-icons-react';
import apiurl from '../../Global';
import base_config from '../../Config';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import Loading from '../includes/Loading';
import Video from '../Assets_Liabrary/Video';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();


export default function Recyclebin() {



    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, warningModal} = CommonFunc()

    const fetchList = () => {
        setLoading(true)
        fetch(apiurl+"api/course_recycle_bin", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                      courseid: params.id,
                      staff: AdminBasics(),
                      accecible: 1,
                      page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setList(response.list)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
                setLoading(false)
            })
            .catch(err => {
                
                // console.log(err);
            });
      }

      const markAll = () => {
        if($("#markAll").prop('checked')){
            $('.mark_checkbox:checkbox').prop('checked', true);
        }else{
            $('.mark_checkbox:checkbox').prop('checked', false);
        }
    }



    const restoreFile = (x) => {
        fetch(apiurl+"api/restoreCourseFiles", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                    id: x,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    fetchList()
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }


    const clearTrashBin = () => {
        fetch(apiurl+"api/clear_all_course_trash", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: JSON.stringify(AdminBasics()),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    fetchList()
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                
            })
            .catch(err => {
                // console.log(err);
            });
    }
      var a = 0
      useEffect(() => {
        if(a === 0){
            fetchList()
            a++
        }
      }, [])

  return (
    <div className='col-md-12'>
        <br />
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Recycle Bin 
                </h3>
                {
                    list.length > 0 &&
                    <div className="input-group pull-right btn btn-danger btn-flat"  onClick={() => clearTrashBin()}>
                        Clear Trash Bin
                    </div>  
                }
            </div>
            <div className='box-body'>
                
            {
                            !loading ?
                            <div className='box-body'>
                                { list.length > 0 &&
                                <table id="contenttable" className="table file_manager_table">
                                    <thead>
                                        <tr>
                                            {/* <th className='text-center' style={{width:"50px"}}>
                                                
                                            </th> */}
                                            {/* <th className='text-center' style={{width:"50px"}}>
                                                <input type={"checkbox"} id={"markAll"}  onChange={() => markAll()}  />
                                            </th> */}
                                            <th>
                                                Name
                                            </th>
                                            <th className='text-center'>
                                                Created On
                                            </th>
                                            <th className='text-center'>
                                                Last Modified
                                            </th>
                                            <th className='text-right'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className='connected-sortable droppable-area1'>
                                        {
                                           
                                            list.map((item, index) => {

                                                let img = "folder"
                                                if(item.type == "Document"){
                                                    img = "pdf"
                                                }else if(item.type == "Question"){
                                                    img = "answer"
                                                }else if(item.type == "Quiz"){
                                                    img = "answer"
                                                }else if(item.type == "Video"){
                                                    img = "play-button"
                                                }else if(item.type == "Recording"){
                                                    img = "live"
                                                }else if(item.type == "Image"){
                                                    img = "photo"
                                                }else if(item.type == "Youtube"){
                                                    img = "youtube"
                                                }
                                                
                                                moment.locale('en');
                                                var dt = item.updated_at;
                                                var ct = item.created_at;
                                                return (
                                                    <tr 
                                                    key={"data"+index}
                                                    id={"id"+item.id}
                                                    >
                                                        {/* <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                            <i className='fa fa-arrows-v'></i>
                                                        </td> */}
                                                        {/* <td className='text-center' style={{width:"50px"}}>
                                                            <input type={"checkbox"} name="mark[]" className='mark_checkbox' value={item.id}/>
                                                        </td> */}
                                                        <td className='file_name_cell' >
                                                            <div className='fileTitleList'>
                                                                <img src={process.env.PUBLIC_URL+'/assets/assets/images/'+img+'.png'} />
                                                                {item.name}
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>
                                                            {moment(ct).format("D-M-Y HH:mm:ss")}
                                                        </td>
                                                        <td className='text-center'>
                                                            {moment(dt).format("D-M-Y HH:mm:ss")}
                                                        </td>
                                                        <td className='text-right'>
                                                            <button className='btn btn-primary btn-flat' title='Restore' onClick={() => restoreFile(item.id)}>
                                                                <i className='fa fa-refresh'></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                }
                                {
                                    list.length < 1 &&
                                    <div className='col-md-12 text-center'>
                                        <div style={{maxWidth:"500px",margin:"0 auto"}} className={"text-center"}>
                                            <img src={process.env.PUBLIC_URL+'/assets/assets/images/emptytrash.png'} style={{width:"100%", objectFit:"contain"}}/>
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <>
                                <br /><br /><br />
                                <Loading />
                                <br /><br /><br />
                            </>
                        }
            </div>
        </div>
    </div>
  )
}
