const Facilites = [
    {
        page_name : "Staff",
        staff_facilities : [
            // { name : "show_in_sssidebar", value: false },
            { name : "show_in_sidebar", value: false },
            { name : "add_staff" , value: false },
            { name : "view_staff" , value: false },
            { name : "edit_staff" , value: false },
            { name : "facility_staff" , value: false },
            { name : "course_facility_staff" , value: false },
            { name : "remove_staff" , value: false },
            { name : "view_staff_info" , value: false },
            { name : "staff_modify_info" , value: false },
            { name : "staff_longgedin_summary" , value: false },
        ]
    },
    {
        page_name : "Permission_Groups",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Banner",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_banner" , value: false },
            { name : "view_banner" , value: false },
            { name : "edit_banner" , value: false },
            { name : "remove_banner" , value: false },
        ]
    },
    {
        page_name : "Assets",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },

            { name : "view_file_manager" , value: false },
            { name : "view_promotional" , value: false },
            { name : "view_uncategorized" , value: false },
            { name : "view_questions" , value: false },
            { name : "view_live_classes" , value: false },
            { name : "view_recorded_classes" , value: false },
            { name : "view_assignments" , value: false },
            { name : "view_trash_bin" , value: false },
            
            { name : "show_create_files_button" , value: false },
            { name : "add_folders" , value: false },
            { name : "add_document" , value: false },
            { name : "add_video" , value: false },
            { name : "add_image" , value: false },
            { name : "add_question" , value: false },
            { name : "add_live_class" , value: false },
            { name : "add_assignment" , value: false },


            { name : "edit_folders" , value: false },
            { name : "edit_document" , value: false },
            { name : "edit_video" , value: false },
            { name : "edit_image" , value: false },
            { name : "edit_question" , value: false },
            { name : "edit_live_class" , value: false },
            { name : "edit_assignment" , value: false },
            { name : "edit_recording" , value: false },

            
            { name : "remove_folders" , value: false },
            { name : "remove_document" , value: false },
            { name : "remove_video" , value: false },
            { name : "remove_image" , value: false },
            { name : "remove_question" , value: false },
            { name : "remove_live_class" , value: false },
            { name : "remove_assignment" , value: false },
            { name : "remove_recording" , value: false },
            { name : "cleane_trash_bin" , value: false },
            
            { name : "info_folders" , value: false },
            { name : "info_document" , value: false },
            { name : "info_video" , value: false },
            { name : "info_image" , value: false },
            { name : "info_question" , value: false },
            { name : "info_assignment" , value: false },
            { name : "info_recording" , value: false },
            
            // { name : "faq_folders" , value: false },
            // { name : "faq_document" , value: false },
            { name : "faq_video" , value: false },
            // { name : "faq_image" , value: false },
            // { name : "faq_question" , value: false },
            // { name : "faq_assignment" , value: false },
            { name : "faq_recording" , value: false },

            // { name : "url_folders" , value: false },
            { name : "url_document" , value: false },
            { name : "url_video" , value: false },
            { name : "url_image" , value: false },
            // { name : "url_question" , value: false },

            { name : "view_storage" , value: false },
            { name : "view_images_storage" , value: false },
            { name : "view_documents_storage" , value: false },
            { name : "view_questions_storage" , value: false },
            { name : "view_videos_storage" , value: false },
            { name : "view_youtube_storage" , value: false },
            { name : "view_recordings_storage" , value: false },
            { name : "view_live_storage" , value: false },
            { name : "view_assignment_storage" , value: false },
            { name : "view_trash_bin_storage" , value: false },
            { name : "show_usedin" , value: false },
            { name : "show_changelog" , value: false },
            { name : "view_recent_files" , value: false },


            { name : "bulk_selection" , value: false },
            { name : "copy" , value: false },
            { name : "cut" , value: false },
            { name : "download_permission" , value: false },

        ]
    },
    {
        page_name : "Courses",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_course", value: false },
            { name : "clone_course", value: false },
            { name : "remove_course", value: false },
            // { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Categories",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_category", value: false },
            { name : "view_category", value: false },
            { name : "edit_category", value: false },
            { name : "remove_category", value: false },
            { name : "courses_position", value: false },
        ]
    },
    {
        page_name : "Logs",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Download_Requests",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "view_file", value: false },
            { name : "view_request_response_by", value: false },
        ]
    },
    {
        page_name : "Login_Steps",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_steps", value: false },
            { name : "edit_steps", value: false },
            { name : "remove_steps", value: false },
        ]
    },
    {
        page_name : "Students",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_students", value: false },
            { name : "bulk_upload", value: false },
            { name : "change_status", value: false },
            { name : "view_profile", value: false },
            { name : "loggedin_summary", value: false },
            { name : "logout_button", value: false },
            { name : "my_purchases_tab", value: false },
            { name : "purchase_history_tab", value: false },
            { name : "address_tab", value: false },
            { name : "wallet_tab", value: false },
            { name : "forum_tab", value: false },
            { name : "notifications_tab", value: false },
            { name : "edit_profile_tab", value: false },
            { name : "add_coins", value: false },
            { name : "remove_coins", value: false },
        ]
    },
    {
        page_name : "Searchable_Students",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "change_status", value: false },
            { name : "view_profile", value: false },
        ]
    },
    {
        page_name : "Student_Removed_Requests",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "change_status", value: false },
            { name : "view_profile", value: false },
            { name : "remove_request", value: false },
        ]
    },
    {
        page_name : "Themes",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_theme", value: false },
            { name : "view_theme", value: false },
            { name : "remove_theme", value: false },
        ]
    },
    {
        page_name : "Rules",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Orders",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "orders_info", value: false },
            { name : "transfer_order", value: false },
            { name : "edit_order", value: false },
            { name : "remove_from_student", value: false },
            { name : "remove_from_portal", value: false },
            { name : "reset_progress", value: false },
            { name : "change_validity", value: false },
            { name : "edit_history", value: false },
        ]
    },
    {
        page_name : "Forum",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_forum", value: false },
            { name : "filter_forum", value: false },
            { name : "pin_forum", value: false },
            { name : "hide_forum", value: false },
            { name : "remove_forum", value: false },
            { name : "view_comments", value: false },
            { name : "make_comments", value: false },
            { name : "delete_comments", value: false },
        ]
    },
    {
        page_name : "Reported_Forum_And_Comments",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "remove_post", value: false },
            { name : "remove_comment", value: false },
        ]
    },
    {
        page_name : "Faq",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_faq", value: false },
            { name : "remove_faq", value: false },
        ]
    },
    {
        page_name : "Chat",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "report_chat", value: false },
            { name : "remove_msg", value: false },
            { name : "view_remove__restore_trail", value: false },
        ]
    },
    {
        page_name : "Sales_Page",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "show_graph", value: false },
            { name : "show_download_button", value: false },
            { name : "show_filters", value: false },
            { name : "paid_courses_enrolled_box", value: false },
            { name : "paid_package_enrolled_box", value: false },
            { name : "free_courses_enrolled_box", value: false },
            { name : "free_package_enrolled_box", value: false },
            { name : "total_amount_box", value: false },
            { name : "total_package_amount_box", value: false },
            { name : "total_course_amount_box", value: false },
            { name : "total_inits_box", value: false },
            { name : "top_cities", value: false },
            { name : "top_states", value: false },
            { name : "top_pincodes", value: false },
            { name : "hide_students_info", value: false },
            { name : "remove_bulk", value: false },
        ]
    },
    {
        page_name : "Dashboard",
        staff_facilities : [
            { name : "sales", value: false },
            { name : "coursessold", value: false },
            { name : "packagessold", value: false },
            { name : "total_signups", value: false },
            { name : "disk_usage_graph", value: false },
            { name : "bandwidth_graph", value: false },
            { name : "sales_graph", value: false },
            { name : "courses_enrolled_graph", value: false },
            { name : "packages_enrolled_graph", value: false },
            { name : "new_signups_graph", value: false },
        ]
    },
    {
        page_name : "Messanger",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Notifications",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Blogs",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_blog", value: false },
            { name : "edit_blog", value: false },
            { name : "remove_blog", value: false },
        ]
    },
    {
        page_name : "Pages",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_page", value: false },
            { name : "edit_page", value: false },
            { name : "remove_page", value: false },
        ]
    },
    {
        page_name : "Promocodes",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_promocode", value: false },
            { name : "edit_promocode", value: false },
            { name : "remove_promocode", value: false },
        ]
    },
    {
        page_name : "All_Test_Attempts",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Doubts",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Feedbacks",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "add_feedback", value: false },
            { name : "edit_feedback", value: false },
            { name : "remove_feedback", value: false },
        ]
    },
    {
        page_name : "All_Files",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "all_assignments", value: false },
            { name : "all_quiz", value: false },
            { name : "all_notes", value: false },
            { name : "all_video", value: false },
            { name : "all_images", value: false },
            { name : "all_live", value: false },
        ]
    },
    {
        page_name : "Social_Links",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Reported_Questions",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Manaege_Enquiries",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
        ]
    },
    {
        page_name : "Usage_Page",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "active_students", value: false },
            { name : "time_spent", value: false },
            { name : "most_used_course", value: false },
            { name : "active_students_graph", value: false },
            { name : "time_spent_graph", value: false },
            { name : "courses_graph", value: false },
        ]
    },
    {
        page_name : "Daily_Live_Content",
        staff_facilities : [
            { name : "show_in_sidebar", value: false },
            { name : "Document_Graph", value: false },
            { name : "Quiz_Graph", value: false },
            { name : "Video_Graph", value: false },
            { name : "Recording_Graph", value: false },
            { name : "Image_Graph", value: false },
            { name : "Youtube_Graph", value: false },
            { name : "Live_Graph", value: false },
            { name : "Assignment_Graph", value: false },
        ]
    },
]


export default Facilites