import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import apiurl from '../../Global';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'myCk/build/ckeditor';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import Loading from '../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Questions from '../Assets_Liabrary/Questions';
import Notfound from '../includes/Notfound';
const cookies = new Cookies();

export default function Addquestions() {


  const Addquestionspage = (props) => {
    
    const params = useParams()
    const navigate = useNavigate()
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const [quizdata, setQuizdata] = useState([])
    const [quizcourseid, setQuizcourseid] = useState("")
    const [quizpid, setQuizpid] = useState("")
    const {AdminBasics, successModal, warningModal, errorModal, pagePermissions, assets_url, updatTrail, formLoading} = CommonFunc()
    const[refresh, setRefresh] = useState("No") 
    const[loading, setLoading] = useState(true) 
    const[questions, setQuestions] = useState([]) 

    const[iframeURL, setIframeURL] = useState("")
    const[iframeTitle, setIframeTitle] = useState("")
    const [courseindex, setCourseIndex] = useState("");
    const[viewQuestion, setViewQuestion] = useState(null)
    
      const single_quiz = () => {
        setLoading(true)
        fetch(apiurl+"api/single_quiz", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    quizid: params.id,
                    p_id: params.p_id,
                    staff: AdminBasics(),
                    accecible: 1,
                    addquestion: 1,
                    page_url: window.location.href,
                })
            })
            .then(response => response.json())
            .then(response => {
  
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    setQuizcourseid(response.data.main_parent)
                    
                    setCourseIndex("course"+response.data.main_parent)

                    setQuizpid(response.data.p_id)
                    setQuizdata(response.data)
                    setQuestions(response.questions)

                    setTimeout(function(){
                        callSorting()
                    }, 2000)
                    
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setLoading(false)
            })
            .catch(err => {
              window.$('#addNewCourse').fadeOut()
                // console.log(err);
            });
      }

      
    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_questions_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    quizid: params.id,
                    p_id: params.p_id,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {
        
        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });

                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }
    const removeQuestion = (id) => {
        
        fetch(apiurl+"api/remove_quiz_question", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    removeid: id,
                    page_url: window.location.href,
                    quizid: params.id,
                    p_id: params.p_id,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
                single_quiz()
            })
            .catch(err => { });

    }

    const removeDialog = (id) => { 
        confirmAlert({
            title: 'Are You Sure?',
            message: "You want to remove this file?",
            buttons: [
                {
                label: 'Yes',
                onClick: () => removeQuestion(id)
                },
                {
                label: 'No',
                onClick: () => console.log("Canceled")
                }
            ]
        });
    }

    const changePositionNumber = (index, value) => {
        var temp = questions
        temp[index]['position'] = value
        setQuestions(temp)
        setRefresh(refresh ? false : true)
    }

    const savePosition = (e, id, position) => {
        e.preventDefault()
        fetch(apiurl+"api/saveQuestionPosition", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    id: id,
                    position: position,
                    page_url: window.location.href,
                    quizid: params.id,
                    p_id: params.p_id,
                    staff: JSON.stringify(AdminBasics())
                })
            })
            .then(response => response.json())
            .then(response => {
                single_quiz()
            })
            .catch(err => { });
    }



    const [downloading, setDownloading] = useState(false)
    const downloadAnswersheet = () => {

        setDownloading(true)
        
        
        fetch(apiurl+"api/downloadQuiz", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    quizid: params.id,
                    for: "Request to download Test paper With Quiz ID: " + params.id
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
                setDownloading(false)
            })
            .catch(err => {
                // console.log(err);
                setDownloading(false)
            });

    }

    var a = 0
    useEffect(() => {
    if(a === 0){
        single_quiz()
        a++ 
    }
    }, [])

    return(
        <>
        {
            pagePermissions(props.pagepermissions, courseindex, "course_content") ?
            <>
            <div className={viewQuestion === null ? 'col-md-12' : "col-md-6"}>
                
                <button className='btn btn-success btn-flat pull-right' style={{marginLeft:"10px"}} onClick={() => window.location.reload()} >
                    <i className='fa fa-refresh'></i>
                </button>
                
                {
                    !downloading ?
                    <button className='btn btn-primary btn-flat pull-right' style={{marginLeft:"10px"}} onClick={() => downloadAnswersheet()} >
                        Download?
                    </button>
                    :
                    <button className='btn btn-warning btn-flat pull-right' style={{marginLeft:"10px"}} >
                        Preparing...
                    </button>
                }
                
                <button className='btn btn-primary btn-flat pull-right' onClick={() => 
                    {
                        setIframeTitle("Import Question(s)")
                        setIframeURL("/admin/import/3/3?importType=Question&courseid="+quizcourseid+"&folderid="+quizpid+"&aboveBelow=&importPosition=&quizid="+params.id)
                        window.$("#importFrame").fadeIn()
                    }
                    } >
                    <i className='fa fa-plus'></i> Add New Question(s)
                </button>
                <br /> <br /> <br />
                <div className='box no-border'>
                    <div className='box-header'>
                        <h3 className='box-title'>
                            Questions List of Quiz {quizdata.name}
                        </h3>
                        <span className={ (questions.length < quizdata.quizquestions) ? 'pull-right text-red' : 'pull-right text-green'} >
                            <strong>
                                {questions.length}/{quizdata.quizquestions}
                            </strong>
                        </span>
                    </div>
                    {
                        loading ? 
                        <>
                            <br /><br /><br />
                            <Loading />
                            <br /><br /><br />
                        </>
                        :
                        questions.length > 0 && 
                        <>

                            <table id="contenttable" className="table file_manager_table questionsPageTable">
                                <thead>
                                    <tr>
                                        {/* <th className='text-center' style={{width:"50px"}}>
                                            
                                        </th> */}
                                        <th>
                                            S.No
                                        </th>
                                        <th>
                                            Question
                                        </th>
                                        
                                        {
                                            viewQuestion === null &&
                                            <>
                                                <th className='text-center'>
                                                    (+ve Marks)
                                                </th>
                                                <th className='text-center'>
                                                    (-ve Marks)
                                                </th>
                                                <th className='text-center'>
                                                    Question Type
                                                </th>
                                                <th className='text-center'>
                                                    Answer Type
                                                </th>
                                                <th className='text-center'>
                                                    Correct Answer
                                                </th>
                                            </>
                                        }
                                        <th className='text-center'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='connected-sortable droppable-area1' >
                                {
                                    questions.length > 0 &&
                                    questions.map((item, index) => {
                                        var edited = ""
                                        if(item.edited !== null && item.edited !== ""){
                                            edited = "<a class='#' style='font-size:14px'><i class='fa fa-edit'></i></a>"
                                        }
                                        var ques = (item.quizdata !== null) ? JSON.parse(item.quizdata) : []
                                        var ansType = (ques.correct !== null) ? ques.correct : ""
                                        var myarr = [item, ques, ansType]
                                        return(
                                        <tr 
                                        key={"data"+index}
                                        >
                                        {/* <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                            <i className='fa fa-arrows-v'></i>
                                        </td> */}
                                        <td className='text-center'>
                                            <form action='' method='POST' onSubmit={(e) => savePosition(e, item.id, item.position)}>
                                                <input type={"number"} name={""} value={item.position} className="form-control" style={{width:70+"px"}} onChange={(e) => changePositionNumber(index, e.target.value)} />
                                            </form>
                                        </td>
                                            <td className='' >
                                                <div dangerouslySetInnerHTML={{__html : "<strong>Question</strong> "+edited+"<br>"+ques.question}} className="ck-content">
                                                
                                                </div>
                                            </td>
                                            
                                        {
                                            viewQuestion === null &&
                                            <>
                                            <td className='text-center'>
                                            {ques.posMarks}
                                            </td>
                                            <td className='text-center'>
                                            {ques.negMarks}
                                            </td>
                                            <td className='text-center'>
                                            {ques.question_type}
                                            </td>
                                            <td className='text-center'>
                                            {ques.answerType}
                                            </td>
                                            <td className='text-center'>
                                            {
                                                ansType === "" ?
                                                "-"
                                                :
                                                ansType.join(',')
                                            }
                                            </td>
                                            </>
                                        }
                                            <td >
                                                <div style={{ whiteSpace:"nowrap" }}>
                                                    <button className='btn btn-primary btn-flat' onClick={() => {
                                                        setIframeTitle("Edit Question")
                                                        setIframeURL("/admin/editquestion/"+item.id)
                                                        window.$("#importFrame").fadeIn()
                                                    }} style={{marginRight:"10px"}} >
                                                        <i className='fa fa-edit'></i>
                                                    </button>
                                                    <button className='btn btn-success btn-flat' style={{marginRight:"10px"}} onClick={() => {
                                                        setViewQuestion(myarr)
                                                        document.body.scrollTop = document.documentElement.scrollTop = 0;

                                                        }}>
                                                        <i className='fa fa-eye'></i>
                                                    </button>
                                                    <button className='btn btn-danger btn-flat' onClick={() => removeDialog(item.id)}>
                                                        <i className='fa fa-trash'></i>
                                                    </button>
                                                </div>    
                                            </td>
                                        </tr>
                                    )})
                                }
                                </tbody>
                            </table>
                        </>
                    }
                </div>
            </div>
            {
                viewQuestion !== null &&
                <div className='col-md-6'>
                    <div className='box no-border'>
                        <div className='box-header'>
                            <h3 className='box-title'>
                                Question No {viewQuestion[0]['position']}
                            </h3>
                            <button className='btn btn-primary btn-flat pull-right' onClick={() => setViewQuestion(null)} >
                                <i className='fa fa-times'></i>
                            </button>
                        </div>
                    </div>
                    {
                        viewQuestion[1].essay !== null && viewQuestion[1].essay !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Essay/Comprehensive
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].essay}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].question !== null && viewQuestion[1].question !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Question
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].question}} ></div>
                            </div>
                    }
                    {
                        [...Array(6)].map((item, index) => {
                            return(
                                viewQuestion[1]['option'+(index+1)] !== null && viewQuestion[1]['option'+(index+1)] !== "" &&
                                <div className='box no-border' key={"key" + index}>
                                    <div className='box-header'>
                                        <h3 className='box-title'>
                                            Option {(index+1)}
                                        </h3>
                                    </div>
                                    <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1]['option'+(index+1)]}} ></div>
                                </div>
                            )
                        })
                    }
                    {
                        viewQuestion[1].explanation !== null && viewQuestion[1].explanation !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Explanation
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].explanation}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].subject !== null && viewQuestion[1].subject !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Subject
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].subject}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].topic !== null && viewQuestion[1].topic !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Topics
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].topic}} ></div>
                            </div>
                    }
                    {
                        viewQuestion[1].tags !== null && viewQuestion[1].tags !== "" &&
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Tags
                                    </h3>
                                </div>
                                <div className='box-body' dangerouslySetInnerHTML={{__html: viewQuestion[1].tags}} ></div>
                            </div>
                    }
                        <div className='col-md-3 no-left-padding'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        (+ve) Marks
                                    </h3>
                                </div>
                                <div className='box-body'>{viewQuestion[1].posMarks}</div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        (-ve) Marks
                                    </h3>
                                </div>
                                <div className='box-body'>{viewQuestion[1].negMarks}</div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Answer Type
                                    </h3>
                                </div>
                                <div className='box-body'>{viewQuestion[1].answerType}</div>
                            </div>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <div className='box no-border'>
                                <div className='box-header'>
                                    <h3 className='box-title'>
                                        Answer
                                    </h3>
                                </div>
                                <div className='box-body'>
                                    {
                                        viewQuestion[2] === "" ?
                                        "-"
                                        :
                                        viewQuestion[2].join(',')
                                    }    
                                </div>
                            </div>
                        </div>
                        

                </div>
            }
            
            <div className='renameDialogBG' id="importFrame" style={{display:"none"}}>
                <div className='renameBody bigRenameBody'>
                        <div className='renameHeading'>
                            <p className='text-left'>
                                {iframeTitle}
                                <span className='pull-right closePopup' onClick={() => {
                                    single_quiz()
                                    window.$('#importFrame').fadeOut()
                                }} title="Close">
                                    <i className='fa fa-times'></i>
                                </span>
                            </p>
                        </div>
                        <div className='iframeCon'>
                            {
                                <iframe src={iframeURL} className="importIframe"></iframe>
                            }
                        </div>
                </div>
            </div>
        </>
        :
        <Notfound />
        }
        </>
    )
  }
  return (
    <>
      <Sidebar page={Addquestionspage} permission="Courses"/>
    </>
  )
}
