import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import Sidebar from '../includes/Sidebar';
import Notfound from '../includes/Notfound';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CommonFunc from '../includes/Common';
import FeatherIcon from 'feather-icons-react';
import Loading from '../includes/Loading';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import apiurl from '../../Global';
const cookies = new Cookies();


function printimg(files){
  const obj = URL.createObjectURL(files)
  return obj;
}

function phpdate(date){
    var jsTimestamp = new Date(date * 1000);
    return jsTimestamp;
}

export default function Messanger() {
  const Managestudentpage = (props) => {
        
    const navigate = useNavigate()
    let getToken = cookies.get('token')
    const {AdminBasics, successModal, errorModal, warningModal, formLoading, pagePermissions, assets_url} = CommonFunc()

    const colors = { "a" : '#007FFF', "b" : '#0000FF', "c" : '#7B3F00', "d" : '#4B0082', "e" : '#05472A', "f" : '#E25822', "g" : '#FFD700', "h" : '#F0FFF0', "i" : '#4B0082', "j" : '#343434', "k" : '#C3B091', "l" : '#E6E6FA', "m" : '#FF00FF', "n" : '#000080', "o" : '#FFA500', "p" : '#FFE5B4', "q" : '#436B95', "r" : '#FF0000', "s" : '#C0C0C0', "t" : '#D2B48C', "u" : '#120A8F', "v" : '#EE82EE', "w" : '#FFFFFF', "x" : '#738678', "y" : '#FFFF00', "z" : '#0014A8', }

    

    const [refresh, setRefresh] = useState("No")
    const [showFilter, setShowFilter] = useState(false)
    const [students, setStudents] = useState("")
    const [loading, setLoading] = useState(false)
    const [campaignloading, setCampaignoading] = useState(false)
    const [showLearnersList, setShowLearnersList] = useState(false)
    const [showLearnersListLoading, setShowLearnersListLoading] = useState(false)
    const [learnersList, setLearnersList] = useState([])
    const [segment, setSegment] = useState(1)
    
    const [statecitylist, setStatecitylist] = useState({state:[], city:[], pincodes:[], courses:[], totalstudents:0, web:0, app:0, userids:[], whatsapp:0 })
    const [filter, setFilter] = useState({
        "signupfrom" : "",
        "signupto" : "",
        "enrolledfrom" : "",
        "enrolledto" : "",
        "enrolledin" : [],
        "notenrolledin" : [],
        "state" : [],
        "city" : [],
        "pincode" : [],
        "categories" : [],
        "verifiedemail" : "",
        "status" : "",
        "segment" : "",
        "walletmin" : "",
        "walletmax" : "",
        "noactivity" : "",
        "signedupvia" : "",
        "name" : "",
        "email" : "",
        "contact" : "",
        "userid" : "",
        "order" : "DESC",
        "tab" : "Web",
        "removerequest" : "Any",
        "recurring" : "",
    })

    const [icon, setIcon] = useState(null)
    const [form, setForm] = useState({
        notificationtitle: "",
        notificationheading: "",
        message: "",
        icon: "",
        link: "",
        type: "Now",
        sendon: "",
        every: "1",
        everytype: "Days",
        until: "",
        segment: "Web",
        userids: [],
    })
    const [camps, setCamps] = useState({web:[], app:[], whatsapp:[], none:[]})

    const clearForm = () => {
        setForm({
            notificationtitle: "",
            notificationheading: "",
            message: "",
            icon: "",
            link: "",
            type: "Now",
            sendon: "",
            every: "1",
            everytype: "Days",
            until: "",
            segment: "Web",
            userids: []
        })
        setIcon(null)
    }

    const changeFilter = (name, value) => {
        var temp = filter
        temp[name] = value
        setFilter(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const [webCount, setWebCount] = useState(0)
    const [appCount, setAppCount] = useState(0)
    const [whatsappCount, setWhatsappCount] = useState(0)

    const getStudents = (e = null) => {
        setStudents([])
        setLoading(true)
        var  temp = filter
        temp['enrolledin'] = window.$("#enrolledin").val()
        temp['notenrolledin'] = window.$("#notenrolledin").val()
        temp['state'] = window.$("#state").val()
        temp['city'] = window.$("#city").val()
        temp['pincode'] = window.$("#pincode").val()
        temp['categories'] = window.$("#categories").val()

        fetch(apiurl + "api/user/campaignStudents", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    filter: temp,
                    fromcampaign: 1
                })
            })
            .then(response => response.json())
            .then(response => {
                setWebCount(response.data.web)
                setAppCount(response.data.app)
                setWhatsappCount(response.data.whatsapp)
                setStatecitylist(response.statecitylist)
                // setStudents(response.students)
                if(e !== null){
                    $(e.target).find(".loaderbody").remove()
                }
                getCampaigns()
                setLoading(false)
            })
    }

    
    const getCampaigns = () => {
        setCampaignoading(true)

        fetch(apiurl + "api/getCampaigns", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setCamps(response.camps)
                setCampaignoading(false)
            })
    }

    const filterNow = (e) => {
        e.preventDefault()
        formLoading(e.target)
        getStudents(e)
    }

    const changeForm = (name, value) => {
        var temp = form
        temp[name] = value
        setForm(temp)
        setRefresh(refresh === "No" ? "Yes" : "No")
    }

    const addCampaign = (e) => {
        e.preventDefault()
        formLoading(e.target)

        const formData = new FormData();
        formData.append('accecible', 1);
        formData.append('notificationtitle', form.notificationtitle);
        formData.append('notificationheading', form.notificationheading);
        formData.append('message', form.message);
        formData.append('icon', icon);
        formData.append('link', form.link);
        formData.append('type', form.type);
        formData.append('sendon', form.sendon);
        formData.append('every', form.every);
        formData.append('everytype', form.everytype);
        formData.append('until', form.until);
        formData.append('segment', form.segment);
        formData.append('staff', JSON.stringify(AdminBasics()));
        
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);
        formData.append('filter', JSON.stringify(filter));

        // var a = 0
        // if(form.segment === "Web"){
        //     formData.append('userids', JSON.stringify(statecitylist.web));
        //     if(statecitylist.web.length < 1){ a++ }
        // }else if(form.segment === "App"){
        //     formData.append('userids', JSON.stringify(statecitylist.app));
        //     if(statecitylist.app.length < 1){ a++ }
        // }else{
        //     formData.append('userids', JSON.stringify(statecitylist.whatsapp));
        //     if(statecitylist.userids.length < 1){ a++ }
        // }
        // var tempusers = null
        // if((form.userids.length > 0)){
        //     formData.append('userids', JSON.stringify(form.userids));
        //     tempusers = 1
        // }
        
        fetch(apiurl + "api/addcampaign", {
            "method": "POST",
            "headers": {
                // "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => {
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    getCampaigns()
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    clearForm()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            

    }

    const copyCompaign = (item) => {
        setForm({
            notificationtitle: item.name,
            notificationheading: item.heading,
            message: item.message,
            icon: "",
            link: (item.link === null) ? "" : item.link,
            type: item.notificationtype,
            sendon: (item.notificationtype !== "Now") ? moment(phpdate(item.sendon)).format("YYYY-MM-DD H:mm:ss") : "",
            every: item.every,
            everytype: item.everytype,
            until: (item.notificationtype === "Recurring") ? moment(phpdate(item.until)).format("YYYY-MM-DD H:mm:ss") : "",
            segment: item.segment,
            userids: JSON.parse(item.users)
        })
        setIcon(null)
    }

    const removeCampaign = (item) => {
        warningModal("Loading...", "Removeing Campaign Please Wait...")
        fetch(apiurl + "api/removecampaign", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                page_url: window.location.href,
                staff: AdminBasics(),
                accecible: 1,
                campaignid: item.campaignid,
            })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    successModal("Success", response.message)
                    getCampaigns()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    // alert(response.message)
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
        
    }

    const removeCModal = (item) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
              {
                label: 'Yes',
                onClick: () => removeCampaign(item)
              },
              {
                label: 'No',
                onClick: () => console.log("Canceled")
              }
            ]
        });
    }

    const showLearners = (item) => {
        setShowLearnersList(true)
        setShowLearnersListLoading(true)
        setSegment(1)
        fetch(apiurl + "api/getSegmentList", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    campaignid: item.campaignid,
                })
            })
            .then(response => response.json())
            .then(response => {
                setLearnersList(response.notification)
                setShowLearnersListLoading(false)
            })
    }

    const abortCampaign = (item) => {
        if(window.confirm("Are you Sure you want to abort campaign?")){
            
        fetch(apiurl + "api/abortCampaign", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    campaignid: item.campaignid,
                })
            })
            .then(response => response.json())
            .then(response => {
                getCampaigns()
            })
        }
    }

    const clearFilter = (e) => {
        var temp = filter
        temp = {
            signupfrom: filter.signupfrom,
            signupto: filter.signupto,
            "enrolledfrom" : "",
            "enrolledto" : "",
            "enrolledin" : [],
            "notenrolledin" : [],
            "state" : [],
            "city" : [],
            "pincode" : [],
            "verifiedemail" : "",
            "status" : "",
            "segment" : "",
            "walletmin" : "",
            "walletmax" : "",
            "noactivity" : "",
            "signedupvia" : "",
            "name" : "",
            "email" : "",
            "contact" : "",
            "userid" : "",
            "order" : "DESC",
            "tab" : "Web",
            "removerequest" : "Any",
            "recurring" : "",
        }
        window.$("#categories").val('').change();
        setFilter(temp)
        // filterFeedbacks(e)
        setRefresh(refresh === 'No' ? "Yes" : "No")
    }
    var a = 0
    useEffect(() => {
        if(a === 0){
            getStudents()
            a++
        }
    }, [])

    return(
        <>
        {
            showFilter &&
        
         
            <div className='col-md-12'>
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        Filter Students
                    </h3>
                    <button className='btn btn-primary btn-flat pull-right' onClick={() => setShowFilter(false)} >
                        <i className='fa fa-times'></i>
                    </button>
                        <button className='btn btn-warning btn-flat pull-right' onClick={(e) => clearFilter(e)} style={{marginRight:"10px"}} >
                            Clear Filter
                        </button>
                </div>
                <form action='' method='POST' onSubmit={(e) => filterNow(e)} >
                
                <div className='box-body'>
                    <div className='col-md-2 no-left-padding'>
                        <label>
                            Signup From
                        </label>
                        <input type={"date"} className="form-control" value={filter.signupfrom} onChange={(e) => changeFilter("signupfrom", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2'>
                        <label>
                            Signup To
                        </label>
                        <input type={"date"} className="form-control" value={filter.signupto} onChange={(e) => changeFilter("signupto", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2'>
                        <label>
                            Enrolled From
                        </label>
                        <input type={"date"} className="form-control" value={filter.enrolledfrom} onChange={(e) => changeFilter("enrolledfrom", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2 no-right-padding'>
                        <label>
                            Enrolled To
                        </label>
                        <input type={"date"} className="form-control" value={filter.enrolledto} onChange={(e) => changeFilter("enrolledto", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2 no-right-padding'>
                        <label>
                            Expiring From
                        </label>
                        <input type={"date"} className="form-control" value={filter.expiringfrom} onChange={(e) => changeFilter("expiringfrom", e.target.value)}  /> 
                    </div>
                    <div className='col-md-2 no-right-padding'>
                        <label>
                            Expiring To
                        </label>
                        <input type={"date"} className="form-control" value={filter.expiringto} onChange={(e) => changeFilter("expiringto", e.target.value)}  /> 
                    </div>
                </div>
                <div className='box-body'>
                    <div className='col-md-5 no-left-padding'>
                        <label>
                            Enrolled In
                        </label>
                        <select data-placeholder='Select Course(s)' className='form-control' id='enrolledin' defaultValue={filter.enrolledin} onChange={(e) => changeFilter("enrolledin", e.target.value)} multiple > 
                            {
                                
                                statecitylist.courses.length > 0 &&
                                statecitylist.courses.map((item, index) => {
                                    return(
                                        <option value={item.otherid} key={"en"+index} >
                                            {item.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        
                    </div>
                    <div className='col-md-5'>
                        <label>
                            Not Enrolled In
                        </label>
                        
                        <select data-placeholder='Select Course(s)' className='form-control' id='notenrolledin' defaultValue={filter.notenrolledin} onChange={(e) => changeFilter("notenrolledin", e.target.value)} multiple > 
                            {
                                
                                statecitylist.courses.length > 0 &&
                                statecitylist.courses.map((item, index) => {
                                    return(
                                        <option value={item.otherid} key={"en"+index} >
                                            {item.name}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-2 no-right-padding '>
                        <label>
                            Show Expired?
                        </label>
                        <select className='form-control' defaultValue={filter.include_expired} onChange={(e) => changeFilter("include_expired", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Yes"}>Yes</option>
                            <option value={"No"}>No</option>
                        </select>
                    </div>
                </div>
                <div className='box-body'>
                    
                    <div className='col-md-3 no-left-padding '>
                        <label>
                            Email Verified?
                        </label>
                        <select className='form-control' defaultValue={filter.verifiedemail} onChange={(e) => changeFilter("verifiedemail", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Yes"}>Verified</option>
                            <option value={"No"}>Not Verified</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Status
                        </label>
                        <select className='form-control' defaultValue={filter.status} onChange={(e) => changeFilter("status", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Yes"}>Active</option>
                            <option value={"No"}>In Active</option>
                        </select>
                    </div>
                        <div className='col-md-3'>
                            <label>
                                State
                            </label>
                            <select className='form-control' data-placeholder='Select State(s)' id='state' defaultValue={filter.state} onChange={(e) => changeFilter("state", e.target.value)} multiple >
                                <option value={""}>Any</option>
                                {
                                    statecitylist.state.length > 0 &&
                                    statecitylist.state.map((item, index) => {
                                        return(
                                            <option key={"state"+index} value={item.state}>{item.state.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                City
                            </label>
                            <select className='form-control' data-placeholder='Select City' id='city' defaultValue={filter.city} onChange={(e) => changeFilter("city", e.target.value)} multiple >
                                <option value={""}>Any</option>
                                {
                                    statecitylist.city.length > 0 &&
                                    statecitylist.city.map((item, index) => {
                                        return(
                                            <option key={"city"+index} value={item.city}>{item.city.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                </div>
                
                <div className='box-body'>
                    <div className='col-md-3 no-left-padding'>
                        <label>
                            Name
                        </label>
                        <input type={"text"} className="form-control" value={filter.name} onChange={(e) => changeFilter("name", e.target.value)} placeholder={"e.g Shivam"} /> 
                    </div>
                    <div className='col-md-3 '>
                        <label>
                            Email
                        </label>
                        <input type={"text"} className="form-control" value={filter.email} onChange={(e) => changeFilter("email", e.target.value)} placeholder={"e.g info@example.com"} /> 
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Contact
                        </label>
                        <input type={"text"} className="form-control" value={filter.contact} onChange={(e) => changeFilter("contact", e.target.value)} placeholder={"e.g 9711111111"} /> 
                    </div>
                    <div className='col-md-3 no-right-padding'>
                        <label>
                            User ID
                        </label>
                        <input type={"text"} className="form-control" value={filter.userid} onChange={(e) => changeFilter("userid", e.target.value)} placeholder={"e.g 161221121"} /> 
                    </div>
                </div>
                <div className='box-body'>
                    
                    <div className='col-md-3 no-left-padding '>
                        <label>
                            Pincode
                        </label>
                        <select className='form-control' id='pincode'  data-placeholder='Select Pincode(s)' defaultValue={filter.pincode} onChange={(e) => changeFilter("pincode", e.target.value)} multiple >
                            <option value={""}>Any</option>
                            {
                                statecitylist.pincodes.length > 0 &&
                                statecitylist.pincodes.map((item, index) => {
                                    return(
                                        <option key={"pincode"+index} value={item.pincode}>{item.pincode.toUpperCase()}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Segment
                        </label>
                        <select className='form-control' defaultValue={filter.segment} onChange={(e) => changeFilter("segment", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Not Purchased"}>Not Purchased</option>
                            <option value={"Free"}>Free</option>
                            <option value={"Paid"}>Paid</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Wallet (Min)
                        </label>
                        <input type={"number"} className='form-control' value={filter.walletmin} onChange={(e) => changeFilter("walletmin", e.target.value)} placeholder={"e.g 1"} />
                    </div>
                    <div className='col-md-3  no-right-padding'>
                        <label>
                            Wallet (Max)
                        </label>
                        <input type={"number"} className='form-control' value={filter.walletmax} onChange={(e) => changeFilter("walletmax", e.target.value)} placeholder={"e.g 90"} />
                    </div>
                    {/* <div className='col-md-3 '>
                        <label>
                            No Activity Since
                        </label>
                        <input type={"datetime-local"} className="form-control" value={filter.noactivity} onChange={(e) => changeFilter("noactivity", e.target.value)}  /> 
                    </div> */}
                </div>
                <div className='box-body'>
                    <div className='col-md-3 no-left-padding'>
                        <label>
                            Signed Up Via
                        </label>
                        <select className='form-control' defaultValue={filter.signedupvia} onChange={(e) => changeFilter("signedupvia", e.target.value)}  >
                            <option value={""}>Any</option>
                            <option value={"Android"}>Android</option>
                            <option value={"Ios"}>IOS</option>
                            <option value={"Web"}>Web</option>
                            <option value={"Admin"}>Admin</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Order
                        </label>
                        <select className='form-control' defaultValue={filter.order} onChange={(e) => changeFilter("order", e.target.value)}  >
                            <option value={"ASC"}>Ascending Order</option>
                            <option value={"DESC"}>Descending Order</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        <label>
                            Last Login From
                        </label>
                        <input type={"date"} className="form-control" value={filter.last_login_from} onChange={(e) => changeFilter("last_login_from", e.target.value)}  /> 
                    </div>
                    <div className='col-md-3 no-right-padding'>
                        <label>
                            Last Login To
                        </label>
                        <input type={"date"} className="form-control" value={filter.last_login_to} onChange={(e) => changeFilter("last_login_to", e.target.value)}  /> 
                    </div>
                </div>
                    <div className='box-body'>
                        <div className="col-md-6 no-left-padding">
                            <label> Select Categories </label>
                            <select className='form-control' multiple id='categories' data-placeholder='Select Category'>
                                {
                                    statecitylist.categories.length > 0 &&
                                    statecitylist.categories.map((item, index) => {
                                        return(
                                            <option key={"cat"+index} value={item.value}>{item.lable.toUpperCase()}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className='col-md-3 '>
                            <label>
                                Recurring
                            </label>
                            <select className='form-control' defaultValue={filter.recurring} onChange={(e) => changeFilter("recurring", e.target.value)}  >
                                <option value={""}>Any</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                            </select>
                        </div>
                        <div className='col-md-3 no-right-padding'>
                            <label>
                                Reuqested for remove?
                            </label>
                            <select className='form-control' defaultValue={filter.removerequest} onChange={(e) => changeFilter("removerequest", e.target.value)}  >
                                <option value={"Any"}>Any</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                            </select>
                        </div>
                    </div>
                <div className='box-footer'>
                    <button className='btn btn-primary btn-flat btn-block'>Search Students</button>
                </div>
                </form>
            </div>
        </div>
    }

    <div className='col-md-4'>
        <div className='box no-border'>
            <div className='box-header'>
                <h3 className='box-title'>
                    Add New Campaign
                </h3>
                {
                    form.userids !== "" && form.userids !== null && form.userids.length > 0 &&
                    <span className='label bg-blue pull-right' >
                        {form.userids.length}
                    </span>
                }
            </div>
            
            <form action='' method='POST' onSubmit={(e) => addCampaign(e)} >
                <div className='box-body text-center'>
                    <div style={{width:"150px", position:"relative", margin:"0 auto"}}>
                        <span style={{
                            display:"inline-block", width:"20px", height:"20px", borderRadius: "30px", lineHeight:"20px", textAlign:"center",
                            position:'absolute', right:"-5px", top:"-5px", backgroundColor:"#FFF"
                        }} 
                        onClick={() => setIcon(null)}
                        >
                            <i className='fa fa-times'></i>
                        </span>
                        <img src={ (icon !== null) ? printimg(icon)  : assets_url+"assets/images/uploadimg.png"} onClick={(e) => window.$(e.target).parent().find('input').click()} className={"cursor-pointer"} title={"Upload Icon"} style={{width:"100%",height:"auto", objectFit:"contain"}} />
                        <input type={"file"} className="form-control" onChange={(e) => setIcon(e.target.files[0])} style={{display:"none"}} accept={".png, .jpg, .jpeg, .svg, .gif"} />
                    </div>
                </div>
                <div className='box-body'>
                    <label>Notification Heading</label>
                    <input type={"text"} className={"form-control"} required value={form.notificationheading} onChange={(e) => changeForm("notificationheading", e.target.value)} placeholder={"e.g All India Challenge"} />
                </div>
                <div className='box-body'>
                    <label>Notification Title</label>
                    <input type={"text"} className={"form-control"} required value={form.notificationtitle} onChange={(e) => changeForm("notificationtitle", e.target.value)} placeholder={"e.g All India Challenge"} />
                </div>
                <div className='box-body'>
                    <label>Notification Body</label>
                    <textarea className={"form-control"} rows="5" required value={form.message} onChange={(e) => changeForm("message", e.target.value)} placeholder="All India CA foundation Dec 22 MSLR full course test has been started. Paper will be strictly based on ICAI pattern."></textarea>
                </div>
                <div className='box-body'>
                    <label>Notification Link</label>
                    <input type={"url"} className={"form-control"} value={form.link} onChange={(e) => changeForm("link", e.target.value)} placeholder={"e.g https://escholars.com/"} />
                </div>
                <div className='box-body'>
                    <div className='col-md-12 no-padding'>
                        <label>Notification Type</label>
                    </div>
                    <div className='col-md-4 no-left-padding'>
                        <button type='button' className={'btn '+(form.segment === "Web" ? 'btn-primary' : '')+' btn-block btn-flat'} onClick={() => changeForm("segment", 'Web')} >
                            Web
                        </button>
                    </div>
                    <div className='col-md-4 no-left-padding'>
                        <button type='button' className={'btn '+(form.segment === "App" ? 'btn-primary' : '')+' btn-block btn-flat'} onClick={() => changeForm("segment", 'App')} >
                            App
                        </button>
                    </div>
                    <div className='col-md-4 no-right-padding no-left-padding'>
                        <button type='button' className={'btn '+(form.segment === "Whatsapp" ? 'btn-primary' : '')+' btn-block btn-flat'} onClick={() => changeForm("segment", 'Whatsapp')} >
                            Whatsapp
                        </button>
                    </div>
                    {/* <div className='col-md-3 no-right-padding'>
                        <button type='button' className={'btn '+(form.segment === "None" ? 'btn-primary' : '')+' btn-block btn-flat'} onClick={() => changeForm("segment", 'None')} >
                            In App
                        </button>
                    </div> */}
                </div>
                <div className='box-body'>
                    <div className='col-md-12 no-padding'>
                        <label>Schedule Type</label>
                    </div>
                    <div className='col-md-4 no-left-padding'>
                        <button type='button' className={'btn '+(form.type === "Now" ? 'btn-primary' : '')+' btn-block btn-flat'} onClick={() => changeForm("type", 'Now')} >
                            Send Now
                        </button>
                    </div>
                    <div className='col-md-4 no-left-padding'>
                        <button type='button' className={'btn '+(form.type === "Scheduled" ? 'btn-primary' : '')+' btn-block btn-flat'} onClick={() => changeForm("type", 'Scheduled')} >
                            Scheduled
                        </button>
                    </div>
                    <div className='col-md-4 no-right-padding no-left-padding'>
                        <button type='button' className={'btn '+(form.type === "Recurring" ? 'btn-primary' : '')+' btn-block btn-flat'} onClick={() => changeForm("type", 'Recurring')} >
                            Recurring
                        </button>
                    </div>
                </div>
                {
                    form.type === "Scheduled" &&
                    <div className='box-body'>
                        <label>Send On</label>
                        <input type={"datetime-local"} className="form-control" value={form.sendon} required onChange={(e) => changeForm("sendon", e.target.value)} />
                    </div>
                }
                {
                    form.type === "Recurring" &&
                    <>
                        <div className='box-body'>
                            <label>First Push Send On</label>
                            <input type={"datetime-local"} className="form-control" value={form.sendon} required onChange={(e) => changeForm("sendon", e.target.value)} />
                        </div>
                        <div className='box-body'>
                            <div className='col-md-12 no-padding'>
                                <label>Send push notifications every:</label>
                            </div>
                            <div className='col-md-4 no-left-padding'>
                                <input type={"number"} className="form-control" value={form.every} required onChange={(e) => changeForm("every", e.target.value)} />
                            </div>
                            <div className='col-md-8 no-right-padding'>
                                <select defaultValue={form.everytype} onChange={(e) => changeForm("everytype", e.target.value)} className={"form-control"} >
                                    <option value={"Days"}>Days</option>
                                    <option value={"Weeks"}>Weeks</option>
                                    <option value={"Months"}>Months</option>
                                </select>
                            </div>
                        </div>
                        <div className='box-body'>
                            <label>Until</label>
                            <input type={"datetime-local"} className="form-control" value={form.until} required onChange={(e) => changeForm("until", e.target.value)} />
                        </div>
                    </>
                }
                <div className='box-footer'>
                    <div className='col-md-6 no-left-padding'>
                        <input type={"button"} className="btn btn-warning btn-flat btn-block" value={"Clear Campaign"} onClick={() => clearForm()} />
                    </div>
                    <div className='col-md-6 no-right-padding'>
                        <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={"Add Campaign"} />
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div className='col-md-8'>
        <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
                
                <li className={ (filter.tab === 'Web') ? "active" : ''} onClick={(e) => changeFilter("tab", 'Web')}><a  title={"Web Push"} >Web Push ({webCount} Learner(s))</a></li>
                <li className={ (filter.tab === 'App') ? "active" : ''} onClick={(e) => changeFilter("tab", 'App')}><a title={"Mobile Push"}>Mobile Push ({appCount} Learner(s))</a></li>
                <li className={ (filter.tab === 'Whatsapp') ? "active" : ''} onClick={(e) => changeFilter("tab", 'Whatsapp')}><a title={"Whatsapp"}>Whatsapp ({whatsappCount} Learner(s))</a></li>
                {/* <li className={ (filter.tab === 'None') ? "active" : ''} onClick={(e) => changeFilter("tab", 'None')}><a title={"In App"}>In App ({statecitylist.totalstudents} Learner(s)) </a></li> */}
                {
                    !showFilter && !loading && !campaignloading &&
                        <li className='pull-right cursor-pointer '  onClick={(e) => {
                            setShowFilter(true)
                            
                            setTimeout(function(){
                                if(window.$(".select2-selection").length > 0){
                                window.$("select.select2-hidden-accessible").select2('destroy');
                                }else{
                                window.$("#enrolledin").select2();
                                window.$("#notenrolledin").select2()
                                window.$("#state").select2()
                                window.$("#city").select2()
                                window.$("#pincode").select2()
                                
                                window.$("#categories").select2()
                                }
                            }, 500)

                            }}><a title={"Show Filter"} className={"btn btn-flat btn-block btn-primary"} style={{color:"#FFF"}} ><i className='fa fa-filter'></i></a></li>
                }
                {
                    (loading || campaignloading) &&
                    <li className='pull-right'  style={{position:"relative", marginRight:"40px"}} >
                        <Loading />
                    </li>
                }
            </ul>
            <div className="tab-content" style={{overflow:"hidden"}} >
                {
                    showLearnersList ?
                    <>
                    {
                    showLearnersListLoading ?
                        <>
                            <br /><br />
                            <Loading />
                            <br /><br />
                        </>
                        :
                        <div className="tab-pane active" id="tab_1" style={{backgroundColor:"#FFFFFF", overflow:"auto"}} >
                            <button className='btn' onClick={() => {
                                setShowLearnersList(false)
                                setLearnersList([])
                            }} ><i className='fa fa-arrow-left'></i> Back</button>
                        <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    learnersList[segment-1]['successusers'] !== null &&
                                    JSON.parse(learnersList[segment-1]['successusers']).map((item, index) => {
                                        return(
                                            <tr key={"segment"+index} >
                                                <td style={{width:"80px"}} >{index+1}</td>
                                                <td>{item.name}</td>
                                                <td>{item.email}</td>
                                                <td>{item.contact}</td>
                                                <td>{item.date}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className='col-md-12 no-padding'>
                            <div className='box-footer text-center'>
                                {
                                    learnersList.length > 0 &&
                                    learnersList.map((item, index) => {
                                        const active = ((index+1) === segment) ? "btn-primary" : ''
                                        return(
                                            <button className={'btn btn-flat '+active} key={"page" + index} 
                                            onClick={() => {
                                                setSegment(index+1)
                                                document.body.scrollTop = document.documentElement.scrollTop = 0;
                                            }}
                                            >
                                                {index+1}
                                            </button>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    }
                    </>
                    :
                    <div className="tab-pane active" id="tab_1" style={{backgroundColor:"#FFFFFF", overflow:"auto"}} >
                        <table id="studenttable" className="table table-bordered table-hover datatable">
                            <thead>
                                <tr>
                                    <th style={{width:"80px"}} >S.No</th>
                                    <th>Icon</th>
                                    <th>Notification Title</th>
                                    <th>Notification Message</th>
                                    <th>Link</th>
                                    <th>Notification Type</th>
                                    <th>Students</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    camps[filter.tab.toLowerCase()].length > 0 &&
                                    camps[filter.tab.toLowerCase()].map((item, index) => {
                                        var users = (item.users !== null) ? JSON.parse(item.users) : []
                                        return(
                                            <tr key={"camps"+index}>
                                                <td >{index+1}</td>
                                                <td>
                                                    {
                                                        item.icon !== null &&
                                                        <img src={assets_url+item.icon} style={{width:"80px", height:"80px", objectFit:"contain"}} />
                                                    }
                                                </td>
                                                <td>
                                                    <strong>{item.heading}</strong><br />
                                                    {item.name}
                                                </td>
                                                <td>{item.message}</td>
                                                <td>
                                                    {
                                                        item.link !== null && item.link !== "null" && item.link !== "" &&
                                                        <a href={item.link} target={"_blank"}>
                                                            view
                                                        </a>
                                                    }
                                                </td>
                                                <td>{item.notificationtype}</td>
                                                <td onClick={() => showLearners(item)} className="cursor-pointer" title='Show Learners' >
                                                    {users.length}
                                                </td>
                                                <td>{item.status}</td>
                                                <td style={{whiteSpace:"nowrap"}} >
                                                    {
                                                        item.status === "Active" &&
                                                        <>
                                                            <button className='btn btn-warning btn-flat' onClick={() => abortCampaign(item)} >
                                                                <i className='fa fa-pause'></i>
                                                            </button>&nbsp;
                                                        </>
                                                    }
                                                    <button className='btn btn-success btn-flat' onClick={() => copyCompaign(item)} >
                                                        <i className='fa fa-copy'></i>
                                                    </button>&nbsp;
                                                    <button className='btn btn-danger btn-flat' onClick={() => removeCModal(item)} >
                                                        <i className='fa fa-trash'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    </div>

        </>
    )
  }
  return (
    <>
        {
            
            <Sidebar page={Managestudentpage} permission="Messanger"/>
            
        }
    </>
  )
}
