import React, { useState, useEffect } from 'react'
import $ from "jquery"
import CommonFunc from '../../includes/Common';
import Cookies from 'universal-cookie';
import apiurl from '../../../Global';
import {  Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../includes/Loading';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';

const cookies = new Cookies();

export default function Mytransactions(props) {
  
  const navigate = useNavigate()
  const params = useParams()
  let getToken = cookies.get('token')
  const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url} = CommonFunc()
  
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    
    const [transaction, setTransaction] = useState([])
    const [balance, setBalance] = useState([])
    const [used, setUsed] = useState([])
    const [expired, setExp] = useState([])
    const [addremoveShow, setAddremoveShow] = useState(false)
    const [refresh, setRefresh] = useState("No")
    const [addCoinVals, setAddCoinVals] = useState({
      "user_id" : params.userid,
      "coins" : 0,
      "exp" : "",
      "transaction_for" : "",
      "remarks" : "",
    })
    const [removeCoinVals, setRemoveCoinVals] = useState({
      "user_id" : params.userid,
      "coins" : 0,
      "transaction_for" : "",
      "remarks" : "",
    })

    const changeAddCoinVal = (col, val) => {
      var temp = addCoinVals
      temp[col] = val
      setAddCoinVals(temp)
      setRefresh(refresh === "No" ? "Yes" : "No")
    }
    const changeRemoveCoinVal = (col, val) => {
      var temp = removeCoinVals
      temp[col] = val
      setRemoveCoinVals(temp)
      setRefresh(refresh === "No" ? "Yes" : "No")
    }

  const getUserData = () => {
    setLoading(true)
    fetch(apiurl+"api/user/walletList", {
        "method": "POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "Authorization" : "Bearer "+props.dummyToken
        },
        "body": JSON.stringify({
            userid: params.userid,
        })
        })
        .then(response => response.json()) 
        .then(response => {
            if(typeof response.status !== "undefined" && response.status === "OK"){
                setTransaction(response.transactions.transaction)
                setBalance(response.transactions.balance)
                setUsed(response.transactions.debit)
                setExp(response.transactions.exp)
            }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                // alert(response.message)
                if(typeof response.errors !== "undefined"){
                    var i = 0
                    $.each(response.errors, function(index, item){
                        setTimeout(function(){
                            errorModal("Oops Something Went Wrong", item[0])
                        }, i*1000)
                        i++
                        console.log(i)
                    });
                }else{
                    errorModal("Oops Something Went Wrong", response.message)
                }
            }
        })
        .catch(err => {
            console.log(err)
        });
}
const addCoins = (e) => {
  e.preventDefault()
  formLoading(e.target)
  fetch(apiurl+"api/walletAddCoins", {
      "method": "POST",
      "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+props.dummyToken
      },
      "body": JSON.stringify({
            page_url: window.location.href,
            staff: AdminBasics(),
            accecible: 1,
            data: addCoinVals
        })
      })
      .then(response => response.json()) 
      .then(response => {
        $(e.target).find(".loaderbody").remove()
          if(typeof response.status !== "undefined" && response.status === "OK"){
            successModal("Success", response.message)
            getUserData()
            setAddremoveShow(false)
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              // alert(response.message)
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                      console.log(i)
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
      })
      .catch(err => {
          console.log(err)
      });
}


const removeCoins = (e) => {
  e.preventDefault()
  formLoading(e.target)
  fetch(apiurl+"api/walletRemoveCoins", {
      "method": "POST",
      "headers": {
          "content-type": "application/json",
          "accept": "application/json",
          "Authorization" : "Bearer "+props.dummyToken
      },
      "body": JSON.stringify({
            page_url: window.location.href,
            staff: AdminBasics(),
            accecible: 1,
            data: removeCoinVals
        })
      })
      .then(response => response.json()) 
      .then(response => {
        $(e.target).find(".loaderbody").remove()
          if(typeof response.status !== "undefined" && response.status === "OK"){
            successModal("Success", response.message)
            getUserData()
            setAddremoveShow(false)
          }else if(typeof response.message !== "undefined" && response.status !== "OK"){
              // alert(response.message)
              if(typeof response.errors !== "undefined"){
                  var i = 0
                  $.each(response.errors, function(index, item){
                      setTimeout(function(){
                          errorModal("Oops Something Went Wrong", item[0])
                      }, i*1000)
                      i++
                      console.log(i)
                  });
              }else{
                  errorModal("Oops Something Went Wrong", response.message)
              }
          }
      })
      .catch(err => {
          console.log(err)
      });

}

  var a = 0  
  useEffect(() => {
    if(a === 0)    {
      getUserData()
      a++
    }
  }, [])

  return (
    <>
    <br />
    <div className='col-md-12 no-padding'>
        <div className="col-lg-4 col-xs-6">
            <div className="small-box bg-green">
            <div className="inner">
                <h3>{balance}</h3>

                <p>Remaining Balance</p>
            </div>
            <div className="icon">
                <i className="fa fa-rupee"></i>
            </div>
            <a href="#" className="small-box-footer">
                {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
            </div>
        </div>
        <div className="col-lg-4 col-xs-6">
            <div className="small-box bg-red">
            <div className="inner">
                <h3>{used}</h3>

                <p>Used Balance Till Now</p>
            </div>
            <div className="icon">
                <i className="fa fa-exchange"></i>
            </div>
            <a href="#" className="small-box-footer">
                {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
            </div>
        </div>
        <div className="col-lg-4 col-xs-6">
            <div className="small-box bg-my">
            <div className="inner">
                <h3>{expired}</h3>

                <p>Balance Expired</p>
            </div>
            <div className="icon">
                <i className="fa fa-history"></i>
            </div>
            <a href="#" className="small-box-footer">
                {/* More info <i className="fa fa-arrow-circle-right"></i> */}
            </a>
            </div>
        </div>
    </div>
    {
      addremoveShow &&
      <>
      {
        pagePermissions(props.pagepermissions, "Students", "add_coins") &&
          <div className='col-md-6'>
            <div className='box no-border'>
              <div className='box-header'>
                <h3 className="box-title">
                  Add Coins
                </h3>
                <button className='btn btn-primary btn-flat pull-right' onClick={() => setAddremoveShow(false)} >
                  <i className='fa fa-times'></i>
                </button>
              </div>
              <form action='' method='POST' onSubmit={(e) => addCoins(e)}>
                <div className='box-body'>
                  <label>Coins <span style={{color:"red"}}>*</span></label>
                  <input type='number' className='form-control' placeholder='e.g 100' required value={addCoinVals.coins} onChange={(e) => changeAddCoinVal("coins", e.target.value)} />
                </div>
                <div className='box-body'>
                  <label>Expiry Date</label>
                  <input type='date' className='form-control' value={addCoinVals.exp} onChange={(e) => changeAddCoinVal("exp", e.target.value)}  />
                </div>
                <div className='box-body'>
                  <label>Transaction For <span style={{color:"red"}}>*</span></label>
                  <textarea className='form-control' placeholder='e.g Earned Cashback' required defaultValue={addCoinVals.transaction_for} onChange={(e) => changeAddCoinVal("transaction_for", e.target.value)} ></textarea>
                </div>
                <div className='box-body'>
                  <label>Remarks <span style={{color:"red"}}>*</span></label>
                  <textarea className='form-control' placeholder='e.g Special Student' required defaultValue={addCoinVals.remarks} onChange={(e) => changeAddCoinVal("remarks", e.target.value)} ></textarea>
                </div>
                <div className='box-footer'>
                  <button className='btn btn-primary btn-flat btn-block'>Add Coins</button>
                </div>
              </form>
            </div>
          </div>
        }
        {
          pagePermissions(props.pagepermissions, "Students", "remove_coins") &&
          <div className='col-md-6'>
            <div className='box no-border'>
              <div className='box-header'>
                <h3 className="box-title">
                  Remove Coins
                </h3>
                <button className='btn btn-primary btn-flat pull-right' onClick={() => setAddremoveShow(false)} >
                  <i className='fa fa-times'></i>
                </button>
              </div>
              <form action='' method='POST' onSubmit={(e) => removeCoins(e)}>
                <div className='box-body'>
                  <label>Coins <span style={{color:"red"}}>*</span></label>
                  <input type='number' className='form-control' placeholder='e.g 100' required value={removeCoinVals.coins} onChange={(e) => changeRemoveCoinVal("coins", e.target.value)} />
                </div>
                <div className='box-body'>
                  <label>Transaction For <span style={{color:"red"}}>*</span></label>
                  <textarea className='form-control' placeholder='e.g Expiring' required defaultValue={removeCoinVals.transaction_for} onChange={(e) => changeRemoveCoinVal("transaction_for", e.target.value)} ></textarea>
                </div>
                <div className='box-body'>
                  <label>Remarks <span style={{color:"red"}}>*</span></label>
                  <textarea className='form-control' placeholder='e.g Special Student' required defaultValue={removeCoinVals.remarks} onChange={(e) => changeRemoveCoinVal("remarks", e.target.value)} ></textarea>
                </div>
                <div className='box-footer'>
                  <button className='btn btn-primary btn-flat btn-block'>Remove Coins</button>
                </div>
              </form>
            </div>
          </div>
        }
      </>
    }
      <div className='col-md-12'>
          <div className='box no-border'>
            <div className='box-header'>
              <h3 className='box-title'>
                Wallet Summary
              </h3>
              {
                (
                  pagePermissions(props.pagepermissions, "Students", "add_coins") ||
                  pagePermissions(props.pagepermissions, "Students", "remove_coins") 
                ) &&
                <button className='btn btn-primary btn-flat pull-right' onClick={() => setAddremoveShow(true)} >
                  Make Transaction
                </button>
              }
            </div>
            <div className='box-body' style={{overflow:"auto"}} >
              <table id="studenttable" className="table table-bordered table-hover datatable">
                <thead>
                    <tr>
                        <th style={{width:"80px"}} >S.No</th>
                        <th>Transaction For</th>
                        <th>Date</th>
                        <th>Expiring On</th>
                        <th>Amount</th>
                        <th>Remark</th>
                        <th>Staff</th>
                    </tr>
                </thead>
                <tbody>
                  {
                    transaction !== null && transaction.length > 0 &&
                    transaction.map((item, index) => {
                      moment.locale('en');
                      var dt = item.created_at;
                      var flow = item.flow === 1 ? " bg-green" : " bg-red" 
                      var exp = (item.exp !== "" && item.exp !== null) ? new Date(item.exp * 1000) : "";
                      
                      var staff = (item.staff !== null) ? JSON.parse(item.staff) : null;

                      return(
                        <tr key={"data"+index} >
                            <td style={{width:"80px"}} >{index+1}</td>
                            <td dangerouslySetInnerHTML={{__html:item.title}}>

                            </td>
                            <td>
                              {moment(dt).format("D-M-Y HH:mm:ss")}
                            </td>
                            <td>

                              { 
                                exp !== "" &&
                                moment(exp).format("D-MM-Y HH:mm:ss")
                              }
                            </td>
                            <td>
                                <span className={'badge'+flow} style={{display:"block", padding:"10px"}}>
                                    {item.amnt}
                                </span>
                            </td>
                            <td>
                                {item.remark}
                            </td>
                            <td>
                                {
                                  staff !== null &&
                                  <>
                                    <strong>{staff.name} ({staff.role})</strong><br />
                                    <small>{staff.email}</small><br />
                                    <small>{staff.contact}</small><br />
                                  </>
                                }
                            </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
      </div>
    </>
  )
}
