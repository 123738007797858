import React, { useState, useEffect } from 'react'
import $ from "jquery"
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import apiurl from '../../Global';
import Sidebar from '../includes/Sidebar';
import CommonFunc from '../includes/Common';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
const cookies = new Cookies();

export default function Feedback() {
  const Feedbackpage = (props) => {
    const admin = cookies.get('admin')
    let getToken = cookies.get('token')
    const params = useParams()
    const {AdminBasics, successModal, errorModal, formLoading, pagePermissions, assets_url, warningModal} = CommonFunc()
    const [refresh, setRefresh] = useState("No")
    const [loading, setLoading] = useState(false)
    const [categories, setCategoryList] = useState([])
    const [active, setActive] = useState("")
    const [form, setForm] = useState({
        "name" : "", "designation" : "", "studentimg" : "", "cover" : "", "cid" : "", "position" : 1, "youtube" : "", "feedback" : "", "id" : ""
    })
    const [segment, setSegment] = useState(0)

    const changeForm = (col, val) => {
        var temp = form
        temp[col] = val
        setForm(temp)
        setRefresh(refresh==="No" ? "Yes" : "No")
    }

    const cleanForm = () => {
        changeForm("name", "")
        changeForm("designation", "")
        changeForm("studentimg", "")
        changeForm("cover", "")
        changeForm("position", 1)
        changeForm("youtube", "")
        changeForm("comment", "")
        changeForm("id", "")
    }

    const addFeedback = (e) => {

        e.preventDefault();
        formLoading(e.target)
        const formData = new FormData();
        formData.append('name', form.name);
        formData.append('designation', form.designation);
        formData.append('studentimg', form.studentimg);
        formData.append('cover', form.cover);
        formData.append('cid', form.cid);
        formData.append('id', form.id);
        formData.append('youtube', form.youtube);
        // formData.append('comment', form.comment);
        formData.append('position', form.position);
        formData.append('feedback', form.feedback);
        formData.append('youtube', form.youtube);
        formData.append('staff', JSON.stringify(AdminBasics()));
        formData.append('accecible', 1);
        formData.append('page_url', window.location.href);

        fetch(apiurl+"api/add_feedback", {
            "method": "POST",
            "headers": {
                "accept": "application/json",
                "Authorization" : "Bearer " + getToken
            },
            "body": formData
            })
            .then(response => response.json())
            .then(response => { 
                $(e.target).find(".loaderbody").remove()
                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    $(e.target).trigger("reset");
                    getCategories()
                    cleanForm()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });


    }
    
    const getCategories = () => {
        fetch(apiurl+"api/category_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    accecible: 1,
                    feedback: 1,
                })
            })
            .then(response => response.json())
            .then(response => {
                if(typeof response.status !== "undefined" && response.status === "OK"){
                    setCategoryList(response.categories)
                    setActive(active === "" ? response.categories[0]['id'] : active )
                    changeForm("cid", (active === "") ? response.categories[0]['id'] : active )
                    // callSorting()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                            console.log(i)
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
            });
    }

    const editFeedback = (item) => {
        changeForm("name", (item.name === null) ? "" : item.name)
        changeForm("designation", (item.designation === null) ? "" : item.designation)
        changeForm("position", item.position)
        changeForm("youtube", (item.youtube === null) ? "" : item.youtube)
        changeForm("feedback", (item.feedback === null) ? "" : item.feedback)
        changeForm("cid", item.cid)
        changeForm("id", item.id)
    }
    
    const removeThisFeedback = (item) => {
        warningModal("Loading...", "Removing Feedback Please Wait...")
        fetch(apiurl+"api/remove_feedback", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    page_url: window.location.href,
                    id: item.id,
                    staff: AdminBasics(),
                    accecible: 1
                })
            })
            .then(response => response.json())
            .then(response => {

                if(typeof response.status !== "undefined" && response.status == "OK"){
                    successModal("Success", response.message)
                    getCategories()
                }else if(typeof response.message !== "undefined" && response.status !== "OK"){
                    if(typeof response.errors !== "undefined"){
                        var i = 0
                        $.each(response.errors, function(index, item){
                            setTimeout(function(){
                                errorModal("Oops Something Went Wrong", item[0])
                            }, i*1000)
                            i++
                        });
                    }else{
                        errorModal("Oops Something Went Wrong", response.message)
                    }
                }
            })
            .catch(err => {
                // console.log(err);
            });
    }

    const removeFeedback = (item) => {
        confirmAlert({
            title: 'Are You Sure?',
            message: "You won't be able to revert this back.",
            buttons: [
            {
                label: 'Yes',
                onClick: () => removeThisFeedback(item)
            },
            {
                label: 'No',
                onClick: () => console.log("Canceled")
            }
            ]
        });
    }

    const rePositionAll = (array) => {
        fetch(apiurl+"api/reposition_feedbacks_list", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "Authorization" : "Bearer "+getToken
            },
            "body": JSON.stringify({
                    accecible: 1,
                    position_list: array,
                    page_url: window.location.href,
                    staff: AdminBasics(),
                    cid: form.cid
                })
            })
            .then(response => response.json())
            .then(response => {
            })
            .catch(err => { });
    }

    const callSorting = () => {

        window.$( ".droppable-area1" ).sortable({
                stop: function(event, ui) {
                    var array = [];
                    window.$(".dragHandle").each(function (){
                        var a = $(this).attr("data-bind");
                        array.push(a);
                    });
                    // console.log(array)
                    rePositionAll(array)
            },
            connectWith: ".connected-sortable",
            stack: '.connected-sortable ul',
            handle: ".dragHandle",
        }).disableSelection();
       
    }

    var a = 0
    useEffect(() => {
        if(a === 0) {
            getCategories()
            a++
        }
    },[])
    return(
      <>
      {
      pagePermissions(props.pagepermissions, "Feedbacks", "add_feedback") &&
        <div className="col-md-4">
            <div className='box no-border'>
                <div className='box-header'>
                    <h3 className='box-title'>
                        {
                            form.id === "" ?
                            "Add Feedback/Testimonial"
                            :
                            "Update Feedback"
                        }
                    </h3>
                </div>
                <form action='' method='POST' onSubmit={addFeedback} >
                    <div className='box-body'>
                        <label>Name</label>
                        <input type={"text"} className="form-control" value={form.name} onChange={(e) => changeForm("name", e.target.value)} placeholder={"e.g Shivam"} />
                    </div>
                    <div className='box-body'>
                        <label>Designation</label>
                        <input type={"text"} className="form-control" value={form.designation} onChange={(e) => changeForm("designation", e.target.value)} placeholder={"e.g Support Team"} />
                    </div>
                    <div className='box-body'>
                        <label>Youtube URL</label>
                        <input type={"text"} className="form-control" value={form.youtube} onChange={(e) => changeForm("youtube", e.target.value)} placeholder={"e.g https://www.youtube.com/watch?v=lL0ULDPCqIA"} />
                    </div>
                    <div className='box-body'>
                        <label>Feedback</label>
                        <textarea type={"text"} className="form-control" onChange={(e) => changeForm("feedback", e.target.value)} placeholder={"e.g Best Online Platform"} defaultValue={form.feedback} ></textarea>
                    </div>
                    <div className='box-body'>
                        <label>Student Photo</label>
                        <small style={{color:"red", float:"right", fontWeight:"bold"}} >Max 1 MB</small>
                        <input type={"file"} className="form-control" accept='.png,.jpg,.jpeg,.svg,.gif' onChange={(e) => changeForm("studentimg", e.target.files[0])} />
                    </div>
                    <div className='box-body'>
                        <label>Thumbnail</label>
                        <small style={{color:"red", float:"right", fontWeight:"bold"}} >Max 1 MB</small>
                        <input type={"file"} className="form-control" accept='.png,.jpg,.jpeg,.svg,.gif' onChange={(e) => changeForm("cover", e.target.files[0])} />
                    </div>
                    <div className='box-body'>
                        <label>Select Category</label>
                        <select className='form-control' defaultValue={form.cid} value={form.cid} required onChange={(e) => changeForm("cid", e.target.value)} >
                            <option value={""} >Select Category</option>
                            {
                                categories.length > 0 &&
                                categories.map((item, index) => {
                                    return(
                                        <option value={item.id} key={"cid" + index} >{item.categoryName}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='box-footer'>
                        <input type={"submit"} className="btn btn-primary btn-flat btn-block" value={ form.id === "" ? "Add Feedback" : "Updated Feedback"} />
                    </div>
                </form>
            </div>
        </div>
    }
        <div className={pagePermissions(props.pagepermissions, "Feedbacks", "add_feedback")  ? "col-md-8" : "col-md-12"}>
          <div className="nav-tabs-custom">
            <ul className="nav nav-tabs">
                {
                    categories.length > 0 &&
                    categories.map((item, index) => {
                        return(
                            <li className={ (active === item.id) ? "active" : ""} key={"index" + index} onClick={() => {
                                setActive(item.id)
                                setSegment(index)
                            }} ><a  >{item.categoryName}</a></li>
                        )
                    })
                }
              
            </ul>
            <div className="tab-content">
                
                <div className="tab-pane active" id="tab_1">
                    <div className='box no-border'>
                        <div className='box-body' style={{overflow:"auto"}} >
                            <table id="studenttable" className="table table-bordered table-hover datatable">
                                <thead>
                                    <tr>
                                        <th>Drag</th>
                                        <th>Position</th>
                                        <th>Profile</th>
                                        <th>Cover</th>
                                        <th>Name</th>
                                        <th>Designation</th>
                                        <th>Youtube</th>
                                        {/* <th>Feedback</th> */}
                                        <th>Last Modified</th>
                                        <th>Added By</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className='connected-sortable droppable-area1' onLoad={() => callSorting()}>
                                    {
                                        typeof categories[segment] !== "undefined" && categories[segment]['feedbacks'].length > 0 &&
                                        categories[segment]['feedbacks'].map((item, index) => {
                                            var staff = JSON.parse(item.staff)
                                            var studentimg = (item.studentimg !== null) ? JSON.parse(item.studentimg) : null
                                            var cover = (item.cover !== null) ? JSON.parse(item.cover) : null
                                            moment.locale('en');
                                            var dt = item.updated_at;
                                            return(
                                                <tr key={"banners"+index} className="draggable-item" data-bind={item.id}>
                                                    
                                                    <td className='text-center dragHandle' style={{width:"50px"}} data-bind={item.id} >
                                                        <i className='fa fa-arrows-v'></i>
                                                    </td>
                                                    <td>{item.position}</td>
                                                    <td>
                                                        {
                                                            studentimg !== null &&
                                                            <img src={ assets_url + studentimg.path} style={{width:"60px", objectFit:"contain"}} />
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            cover !== null &&
                                                            <img src={ assets_url + cover.path} style={{width:"60px", objectFit:"contain"}} />
                                                        }
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td>{item.designation}</td>
                                                    <td>
                                                        <a href={item.youtube} target={"_blank"} >
                                                        {item.youtube}
                                                        </a>
                                                    </td>
                                                    {/* <td>{item.feedback}</td> */}
                                                    <td>{moment(dt).format("D-M-Y HH:mm:ss")}</td>
                                                    <td>
                                                        <strong>{staff.name}<sup>({staff.role})</sup></strong><br />
                                                        {staff.email}<br />
                                                        {staff.contact}
                                                    </td>
                                                    <td style={{whiteSpace:"nowrap"}} >
                                                        {
                                                            pagePermissions(props.pagepermissions, "Feedbacks", "edit_feedback") &&
                                                            <button className='btn btn-primary btn-flat' onClick={() => editFeedback(item)} >  
                                                                <i className='fa fa-pencil'></i>
                                                            </button>
                                                        }
                                                        {
                                                            pagePermissions(props.pagepermissions, "Feedbacks", "remove_feedback") &&
                                                            <button className='btn btn-danger btn-flat' onClick={() => removeFeedback(item)}>
                                                                <i className='fa fa-trash'></i>
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <Sidebar page={Feedbackpage} permission="Feedbacks"/>
    </>
  )
}
